@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
.edit-post-visual-editor, #root {
  /* Styles */
  /*
Alternative compacted style for columns

*/
  /* Adjustments for mobile phones for screens from 320px to 480px wide:*/
  /* Adjustments for tablets for screens from 481px to 768px wide:*/
  /* Adjustments for tablets for screens from 769px to 1024px wide:*/
  /* Adjustments for tablets for screens from 1025x to 1365px wide:*/
  /* Adjustments for tablets for screens from 1366x and more px wide:*/
  /* Apply animations only when the animate class is added */
  /* Adjustments for mobile for screens from 320px to 480px wide:*/
  /* Adjustments for tablets for screens from 481 to 768px wide:*/
  /* Adjustments for laptops for screens from 769 to 1024px wide:*/
  /* Adjustments for desktop for screens from 1025 to 1365px wide:*/
  /* Adjustments for mobile phones for screens from 320px to 480px wide:*/
  /* Adjustments for tablets for screens from 481px to 782px wide:*/
  /* Adjustments for laptops for screens from 783px to 1024px wide:*/
  /* Adjustments for desktop for screens from 1025 to 1365px wide:*/
  /* Adjustments for desktop for screens from 1366px to 1920px wide:*/
  /* Adjustments for desktop for screens from 1921px wide and more:*/
  /* iPads (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait) ----------- */
  /* Smartphones (portrait) ----------- */
  /* General Adjustments for responsiveness:*/
  /*.is-layout-flex.wp-container-16.wp-block-columns, .is-layout-flex.wp-container-6.wp-block-columns.are-vertically-aligned-center {
  width: 100% !important;
  display: flex !important;
  margin-bottom: 20px !important;
  align-items: center !important;
  .is-layout-flow.wp-block-column:nth-child(1) {
    flex: 0 0 55% !important;
  }
  .is-layout-flow.wp-block-column:nth-child(2){
    flex: 0 0 10% !important;
  }
  .is-layout-flow.wp-block-column:nth-child(3){
    flex: 0 0 35% !important;
  }
}*/
  /* Adjustments for mobile phones for screens from 320px to 480px wide:*/
  /* Customize the label (the container) */
  /* Adjustments for mobile phones for screens from 320px to 480px wide:*/ }
  .edit-post-visual-editor *, #root * {
    -webkit-print-color-adjust: exact; }
  .edit-post-visual-editor .has-white-color, #root .has-white-color {
    color: #FFF; }
  .edit-post-visual-editor .has-dark-orange-color, #root .has-dark-orange-color {
    color: #ffb400; }
  .edit-post-visual-editor .has-light-orange-color, #root .has-light-orange-color {
    color: #fff6e1; }
  .edit-post-visual-editor .has-nav-orange-color, #root .has-nav-orange-color {
    color: #fff6e1; }
  .edit-post-visual-editor .has-grey-color, #root .has-grey-color {
    color: #828387; }
  .edit-post-visual-editor .has-regular-grey-color, #root .has-regular-grey-color {
    color: #535454; }
  .edit-post-visual-editor .has-background-grey-color, #root .has-background-grey-color {
    color: #ececec; }
  .edit-post-visual-editor .has-light-green-color, #root .has-light-green-color {
    color: #82b38b; }
  .edit-post-visual-editor .has-vibrant-green-color, #root .has-vibrant-green-color {
    color: #52a963; }
  .edit-post-visual-editor .has-red-color, #root .has-red-color {
    color: #ba5555; }
  .edit-post-visual-editor .has-blue-color, #root .has-blue-color {
    color: #5089a6; }
  .edit-post-visual-editor .has-border-color, #root .has-border-color {
    color: #41a1e0; }
  .edit-post-visual-editor .has-dark-orange-background-color, #root .has-dark-orange-background-color {
    background-color: #ffb400; }
  .edit-post-visual-editor .has-light-orange-background-color, #root .has-light-orange-background-color {
    background-color: #fff6e1; }
  .edit-post-visual-editor .has-nav-orange-background-color, #root .has-nav-orange-background-color {
    background-color: #fff6e1; }
  .edit-post-visual-editor .has-grey-background-color, #root .has-grey-background-color {
    background-color: #828387; }
  .edit-post-visual-editor .has-regular-grey-background-color, #root .has-regular-grey-background-color {
    background-color: #535454; }
  .edit-post-visual-editor .has-background-grey-background-color, #root .has-background-grey-background-color {
    background-color: #ececec; }
  .edit-post-visual-editor .has-light-green-background-color, #root .has-light-green-background-color {
    background-color: #82b38b; }
  .edit-post-visual-editor .has-vibrant-green-background-color, #root .has-vibrant-green-background-color {
    background-color: #52a963; }
  .edit-post-visual-editor .has-red-background-color, #root .has-red-background-color {
    background-color: #ba5555; }
  .edit-post-visual-editor .has-blue-background-color, #root .has-blue-background-color {
    background-color: #5089a6; }
  .edit-post-visual-editor .has-border-background-color, #root .has-border-background-color {
    background-color: #41a1e0; }
  .edit-post-visual-editor .has-nigeria-flag-green-color, #root .has-nigeria-flag-green-color {
    color: #008751; }
  .edit-post-visual-editor .has-nigeria-flag-green-background-color, #root .has-nigeria-flag-green-background-color {
    background-color: #008751; }
  .edit-post-visual-editor .has-indigo-blue-color, #root .has-indigo-blue-color {
    color: #00368C; }
  .edit-post-visual-editor .has-indigo-blue-background-color, #root .has-indigo-blue-background-color {
    background-color: #00368C; }
  .edit-post-visual-editor p, #root p {
    color: #66676d;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem; }
  .edit-post-visual-editor b, .edit-post-visual-editor strong, #root b, #root strong {
    font-weight: 700; }
  .edit-post-visual-editor h1, #root h1 {
    color: #66676d;
    font-size: 30px; }
  .edit-post-visual-editor h2, #root h2 {
    font-size: 1.9rem !important;
    color: #66676d; }
  .edit-post-visual-editor h3, #root h3 {
    font-size: 1.3rem !important;
    color: #66676d; }
  .edit-post-visual-editor h4, #root h4 {
    font-size: 1.07rem !important;
    color: #66676d; }
  .edit-post-visual-editor h5, #root h5 {
    font-size: 1rem !important;
    color: #66676d; }
  .edit-post-visual-editor h6, #root h6 {
    font-size: .7rem !important;
    color: #66676d; }
  .edit-post-visual-editor .has-standard-14-font-size, #root .has-standard-14-font-size {
    font-size: 14px; }
  .edit-post-visual-editor .has-standard-12-font-size, #root .has-standard-12-font-size {
    font-size: 12px; }
  .edit-post-visual-editor .has-standard-15-font-size, #root .has-standard-15-font-size {
    font-size: 15px; }
  .edit-post-visual-editor .has-standard-18-font-size, #root .has-standard-18-font-size {
    font-size: 18px; }
  .edit-post-visual-editor .has-standard-24-font-size, #root .has-standard-24-font-size {
    font-size: 24px; }
  .edit-post-visual-editor .has-standard-27-font-size, #root .has-standard-27-font-size {
    font-size: 27px; }
  .edit-post-visual-editor .has-standard-30-font-size, #root .has-standard-30-font-size {
    font-size: 30px; }
  .edit-post-visual-editor .has-standard-36-font-size, #root .has-standard-36-font-size {
    font-size: 36px; }
  .edit-post-visual-editor .has-standard-64-font-size, #root .has-standard-64-font-size {
    font-size: 64px;
    line-height: 110%; }
  .edit-post-visual-editor .has-condensed-text, #root .has-condensed-text {
    font-stretch: condensed;
    line-height: 115% !important; }
  .edit-post-visual-editor .has-weight-300, #root .has-weight-300 {
    font-weight: 300; }
  .edit-post-visual-editor .has-weight-400, #root .has-weight-400 {
    font-weight: 400; }
  .edit-post-visual-editor .has-weight-700, #root .has-weight-700 {
    font-weight: 700; }
  .edit-post-visual-editor .entry-content, #root .entry-content {
    margin: 0;
    display: block; }
    .edit-post-visual-editor .entry-content .date, #root .entry-content .date {
      font-size: 14px;
      color: #66676d;
      text-align: right; }
    .edit-post-visual-editor .entry-content .title, #root .entry-content .title {
      padding: 0px;
      color: #5baf95;
      font-size: 2.5rem; }
  .edit-post-visual-editor .wp-block-columns, #root .wp-block-columns {
    display: flex !important; }
  .edit-post-visual-editor .wp-block-image img, #root .wp-block-image img {
    height: -webkit-fill-available;
    height: auto; }
  .edit-post-visual-editor .aligncenter, #root .aligncenter {
    clear: both;
    text-align: center; }
  .edit-post-visual-editor figure, #root figure {
    padding: 0;
    margin: 0;
    line-height: 0px; }
  .edit-post-visual-editor .tight, #root .tight {
    margin: 3px 0 0 0 !important; }
  .edit-post-visual-editor .padded, #root .padded {
    padding: 1em; }
    .edit-post-visual-editor .padded.padded-sm, #root .padded.padded-sm {
      padding: 2em; }
    .edit-post-visual-editor .padded.padded-md, #root .padded.padded-md {
      padding: 3em; }
  .edit-post-visual-editor .light, #root .light {
    font-weight: 100; }
  .edit-post-visual-editor ul.wp.post.list, #root ul.wp.post.list {
    list-style-type: circle; }
    .edit-post-visual-editor ul.wp.post.list li.entry-content, #root ul.wp.post.list li.entry-content {
      display: list-item !important;
      padding: 10px; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content div, .edit-post-visual-editor ul.wp.post.list li.entry-content a, #root ul.wp.post.list li.entry-content div, #root ul.wp.post.list li.entry-content a {
        margin: 5px; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .date, #root ul.wp.post.list li.entry-content .date {
        color: #363636; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .title, #root ul.wp.post.list li.entry-content .title {
        font-weight: bold; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .link, #root ul.wp.post.list li.entry-content .link {
        text-align: right; }
  .edit-post-visual-editor .wp-block-image.bordered img, #root .wp-block-image.bordered img {
    border: 2px solid #ececec; }
  .edit-post-visual-editor .wp-block-columns .cost-gender-label, #root .wp-block-columns .cost-gender-label {
    line-height: 90%;
    margin-left: -40px; }
  .edit-post-visual-editor .wp-block-columns.contracted, #root .wp-block-columns.contracted {
    padding: 0px; }
    .edit-post-visual-editor .wp-block-columns.contracted .wp-block-column, #root .wp-block-columns.contracted .wp-block-column {
      margin: 0px; }
  .edit-post-visual-editor .form-block-half, #root .form-block-half {
    padding: 3em; }
    .edit-post-visual-editor .form-block-half p.has-big-font-size, #root .form-block-half p.has-big-font-size {
      margin-bottom: 0.3em; }
  .edit-post-visual-editor .wp-block-separator, #root .wp-block-separator {
    border: 1px solid #dadada;
    width: 100% !important;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto; }
    .edit-post-visual-editor .wp-block-separator.has-alpha-channel-opacity, #root .wp-block-separator.has-alpha-channel-opacity {
      opacity: 0.4; }
  .edit-post-visual-editor p + .wp-block-separator, #root p + .wp-block-separator {
    margin-top: -20px; }
  .edit-post-visual-editor p:empty, #root p:empty {
    display: none; }
  .edit-post-visual-editor .wp-block-buttons .wp-block-button__link, #root .wp-block-buttons .wp-block-button__link {
    font-size: 16px !important;
    color: #FFFFFF !important; }
  .edit-post-visual-editor .wp-block-columns.is-compacted, #root .wp-block-columns.is-compacted {
    width: 100%;
    padding: 0px; }
    .edit-post-visual-editor .wp-block-columns.is-compacted .wp-block-column, #root .wp-block-columns.is-compacted .wp-block-column {
      align-items: stretch;
      margin-bottom: 0px;
      margin: 0px;
      padding: 0px; }
    .edit-post-visual-editor .wp-block-columns.is-compacted .wp-block-image, #root .wp-block-columns.is-compacted .wp-block-image {
      margin: 0 0 0em; }
  .edit-post-visual-editor .wp-block-columns.has-background.is-compacted, #root .wp-block-columns.has-background.is-compacted {
    padding: 0px; }
  .edit-post-visual-editor .wp-block-group.compacted .wp-block-columns, #root .wp-block-group.compacted .wp-block-columns {
    margin-bottom: 10px;
    margin: 0px;
    padding: 0px !important; }
    .edit-post-visual-editor .wp-block-group.compacted .wp-block-columns .wp-block-column:not(:first-child), #root .wp-block-group.compacted .wp-block-columns .wp-block-column:not(:first-child) {
      margin-left: 3px; }
  .edit-post-visual-editor .wp-block-group p, #root .wp-block-group p {
    margin: 0px !important;
    padding: 0; }
  .edit-post-visual-editor .ui.input > input::placeholder, .edit-post-visual-editor .ui.input > input::-webkit-input-placeholder, #root .ui.input > input::placeholder, #root .ui.input > input::-webkit-input-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 0.8em; }
  .edit-post-visual-editor .ui.input.error > input::placeholder, .edit-post-visual-editor .ui.input.error > input::-webkit-input-placeholder, #root .ui.input.error > input::placeholder, #root .ui.input.error > input::-webkit-input-placeholder {
    color: #9f3a38;
    font-family: "Roboto", sans-serif; }
  .edit-post-visual-editor .ui.default.dropdown:not(.button) > .text,
  .edit-post-visual-editor .ui.dropdown:not(.button) > .default.text, #root .ui.default.dropdown:not(.button) > .text,
  #root .ui.dropdown:not(.button) > .default.text {
    font-family: "Roboto", sans-serif;
    font-size: 0.9em;
    top: -3px; }
  .edit-post-visual-editor .wp-block-spacer, #root .wp-block-spacer {
    clear: none; }
  .edit-post-visual-editor .endnotes, #root .endnotes {
    margin-left: 1em;
    color: #66676d;
    font-size: 14px; }
    .edit-post-visual-editor .endnotes li, #root .endnotes li {
      margin-bottom: 15px; }
  .edit-post-visual-editor .sr-only, #root .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    padding: 0;
    margin: -1px;
    position: absolute;
    overflow: hidden;
    width: 1px; }
  .edit-post-visual-editor .wp-block-quote, #root .wp-block-quote {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #fff6e1;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0MS40LDM3LjljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOAoJCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCQljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOGMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMQoJCWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44YzAtNDYuMiwyNC43LTg2LjcsNjUuNy0xMTUuNUwyNzEuMSwzNy45eiIvPgo8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: 90px 90px;
    background-position: 20px 14px;
    border-left: none;
    padding: 30px 45px 30px 30px;
    padding-left: 120px;
    background-color: #e4f2ee;
    background-image: none;
    display: block;
    padding: 20px 45px 30px 105px;
    position: relative; }
    .edit-post-visual-editor .wp-block-quote:before, #root .wp-block-quote:before {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 320 202' style='enable-background:new 0 0 320 202;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%235BAF95;%7D%0A%3C/style%3E%3Cpath class='st0' d='M141.4,37.9c-16.8,7.4-41,30.5-47.8,50.4c2.6-1.1,7.9-2.1,13.7-2.1c25.2,0,45.7,19.4,45.7,48.8 c0,30.5-25.2,55.1-54.6,55.1c-32,0-60.9-24.7-60.9-67.8C37.4,76.2,62,35.8,103,6.9L141.4,37.9z M271.1,37.9 c-16.8,7.4-41,30.5-47.8,50.4c2.6-1.1,7.9-2.1,13.7-2.1c25.2,0,45.7,19.4,45.7,48.8c0,30.5-25.2,55.1-54.6,55.1 c-32,0-60.9-24.7-60.9-67.8c0-46.2,24.7-86.7,65.7-115.5L271.1,37.9z'/%3E%3C/svg%3E");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      left: 2%;
      position: absolute;
      top: 7%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote:after, #root .wp-block-quote:after {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 320 202' style='enable-background:new 0 0 320 202;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%235BAF95;%7D%0A%3C/style%3E%3Cpath class='st0' d='M141.4,37.9c-16.8,7.4-41,30.5-47.8,50.4c2.6-1.1,7.9-2.1,13.7-2.1c25.2,0,45.7,19.4,45.7,48.8 c0,30.5-25.2,55.1-54.6,55.1c-32,0-60.9-24.7-60.9-67.8C37.4,76.2,62,35.8,103,6.9L141.4,37.9z M271.1,37.9 c-16.8,7.4-41,30.5-47.8,50.4c2.6-1.1,7.9-2.1,13.7-2.1c25.2,0,45.7,19.4,45.7,48.8c0,30.5-25.2,55.1-54.6,55.1 c-32,0-60.9-24.7-60.9-67.8c0-46.2,24.7-86.7,65.7-115.5L271.1,37.9z'/%3E%3C/svg%3E");
      transform: scaleX(-1) scaleY(-1);
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      right: 2%;
      position: absolute;
      bottom: 5%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote p, #root .wp-block-quote p {
      color: #5baf95 !important;
      font-size: 24px !important;
      font-weight: 700;
      font-stretch: condensed;
      line-height: 115% !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      padding: 0 !important; }
    .edit-post-visual-editor .wp-block-quote .quote-cite, #root .wp-block-quote .quote-cite {
      color: #828387 !important;
      font-size: 15px !important;
      font-weight: 400;
      line-height: 130%;
      display: block; }
    .edit-post-visual-editor .wp-block-quote cite, #root .wp-block-quote cite {
      color: #828387 !important;
      font-size: 15px !important;
      display: block;
      line-height: 130%; }
  .edit-post-visual-editor .background-quote-img, #root .background-quote-img {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #e4f2ee;
    background-image: none;
    display: block; }
  .edit-post-visual-editor .list-column, #root .list-column {
    margin-bottom: 0; }
  .edit-post-visual-editor .wp-block-columns.subtitle, #root .wp-block-columns.subtitle {
    padding-left: 0; }
    .edit-post-visual-editor .wp-block-columns.subtitle img, #root .wp-block-columns.subtitle img {
      margin-top: 8px; }
  .edit-post-visual-editor .header-cover, #root .header-cover {
    background-size: cover;
    margin-bottom: -100px;
    padding: 0; }
  .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
    width: 100vw;
    object-position: right top;
    margin-left: calc(-50vw + 50%);
    margin-top: -54px;
    margin-bottom: 30px;
    padding: 143px 0 0 0; }
  .edit-post-visual-editor .wp-block-cover__inner-container, #root .wp-block-cover__inner-container {
    width: 1127px !important;
    margin-left: auto;
    margin-right: auto; }
    .edit-post-visual-editor .wp-block-cover__inner-container .wp-block-columns:last-child, #root .wp-block-cover__inner-container .wp-block-columns:last-child {
      margin-bottom: 13px !important; }
  .edit-post-visual-editor .header-cover img, #root .header-cover img {
    z-index: -1; }
  .edit-post-visual-editor .entry-content, #root .entry-content {
    margin: 0;
    display: block; }
    .edit-post-visual-editor .entry-content ul, #root .entry-content ul {
      list-style: disc;
      margin-left: 20px; }
      .edit-post-visual-editor .entry-content ul li, #root .entry-content ul li {
        line-height: 1.5; }
    .edit-post-visual-editor .entry-content .date, #root .entry-content .date {
      font-size: 14px;
      color: #66676d;
      text-align: right; }
    .edit-post-visual-editor .entry-content .title, #root .entry-content .title {
      padding: 0px;
      color: #5baf95;
      font-size: 2.5rem; }
  .edit-post-visual-editor .wp-block-columns, #root .wp-block-columns {
    display: flex !important; }
  .edit-post-visual-editor .wp-block-image, #root .wp-block-image {
    display: block !important; }
  .edit-post-visual-editor .wp-block-image .aligncenter, #root .wp-block-image .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .edit-post-visual-editor .wp-block-image img, #root .wp-block-image img {
    height: -webkit-fill-available;
    height: auto; }
  .edit-post-visual-editor .aligncenter, #root .aligncenter {
    clear: both;
    text-align: center; }
  .edit-post-visual-editor figure, #root figure {
    padding: 0;
    margin: 0; }
  .edit-post-visual-editor .tight, #root .tight {
    margin: 3px 0 0 0 !important; }
  .edit-post-visual-editor .padded, #root .padded {
    padding: 1em; }
    .edit-post-visual-editor .padded.padded-sm, #root .padded.padded-sm {
      padding: 2em; }
    .edit-post-visual-editor .padded.padded-md, #root .padded.padded-md {
      padding: 3em; }
  .edit-post-visual-editor .light, #root .light {
    font-weight: 100; }
  .edit-post-visual-editor ul.wp.post.list, #root ul.wp.post.list {
    list-style-type: circle; }
    .edit-post-visual-editor ul.wp.post.list li.entry-content, #root ul.wp.post.list li.entry-content {
      display: list-item !important;
      padding: 10px; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content div, .edit-post-visual-editor ul.wp.post.list li.entry-content a, #root ul.wp.post.list li.entry-content div, #root ul.wp.post.list li.entry-content a {
        margin: 5px; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .date, #root ul.wp.post.list li.entry-content .date {
        color: #363636; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .title, #root ul.wp.post.list li.entry-content .title {
        font-weight: bold; }
      .edit-post-visual-editor ul.wp.post.list li.entry-content .link, #root ul.wp.post.list li.entry-content .link {
        text-align: right; }
  .edit-post-visual-editor .wp-block-image.bordered img, #root .wp-block-image.bordered img {
    border: 2px solid #ececec; }
  .edit-post-visual-editor .wp-block-columns .cost-gender-label, #root .wp-block-columns .cost-gender-label {
    line-height: 90%;
    margin-left: -40px; }
  .edit-post-visual-editor .wp-block-columns.contracted, #root .wp-block-columns.contracted {
    background-color: #f0f0f0;
    padding: 0px; }
    .edit-post-visual-editor .wp-block-columns.contracted .wp-block-column, #root .wp-block-columns.contracted .wp-block-column {
      margin: 0px; }
  .edit-post-visual-editor .form-block-half, #root .form-block-half {
    padding: 2.5em; }
    .edit-post-visual-editor .form-block-half p.has-big-font-size, #root .form-block-half p.has-big-font-size {
      margin-bottom: 0.3em; }
  .edit-post-visual-editor .wp-block-separator, #root .wp-block-separator {
    border: 1px solid #dadada;
    width: 100% !important;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto; }
  .edit-post-visual-editor p + .wp-block-separator, #root p + .wp-block-separator {
    margin-top: -20px; }
  .edit-post-visual-editor p:empty, #root p:empty {
    display: none; }
  .edit-post-visual-editor .wp-block-buttons .wp-block-button__link, #root .wp-block-buttons .wp-block-button__link {
    font-size: 16px !important; }
  .edit-post-visual-editor .wp-block-group.compacted .wp-block-columns, #root .wp-block-group.compacted .wp-block-columns {
    margin-bottom: 10px;
    margin: 0px;
    padding: 0px !important; }
    .edit-post-visual-editor .wp-block-group.compacted .wp-block-columns .wp-block-column:not(:first-child), #root .wp-block-group.compacted .wp-block-columns .wp-block-column:not(:first-child) {
      margin-left: 3px; }
  .edit-post-visual-editor .wp-block-group p, #root .wp-block-group p {
    margin: 1rem 0;
    padding: 0; }
  .edit-post-visual-editor .ui.input > input::placeholder, .edit-post-visual-editor .ui.input > input::-webkit-input-placeholder, #root .ui.input > input::placeholder, #root .ui.input > input::-webkit-input-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 0.8em; }
  .edit-post-visual-editor .ui.input.error > input::placeholder, .edit-post-visual-editor .ui.input.error > input::-webkit-input-placeholder, #root .ui.input.error > input::placeholder, #root .ui.input.error > input::-webkit-input-placeholder {
    color: #9f3a38;
    font-family: "Roboto", sans-serif; }
  .edit-post-visual-editor .ui.default.dropdown:not(.button) > .text,
  .edit-post-visual-editor .ui.dropdown:not(.button) > .default.text, #root .ui.default.dropdown:not(.button) > .text,
  #root .ui.dropdown:not(.button) > .default.text {
    font-family: "Roboto", sans-serif;
    font-size: 0.9em;
    top: -3px; }
  .edit-post-visual-editor .wp-block-spacer, #root .wp-block-spacer {
    clear: none; }
  .edit-post-visual-editor .endnotes, #root .endnotes {
    margin-left: 1em;
    color: #66676d;
    font-size: 14px; }
    .edit-post-visual-editor .endnotes li, #root .endnotes li {
      margin-bottom: 15px; }
  .edit-post-visual-editor .sr-only, #root .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    padding: 0;
    margin: -1px;
    position: absolute;
    overflow: hidden;
    width: 1px; }
  .edit-post-visual-editor .highlight, #root .highlight {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    background-color: transparent;
    border: 1px solid #cccccc;
    padding: 0; }
    .edit-post-visual-editor .highlight h3, #root .highlight h3 {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      -moz-border-radius-bottomright: 0;
      -moz-border-radius-bottomleft: 0;
      border-bottom: 1px solid #cccccc;
      margin-bottom: 0;
      padding: 15px 20px; }
    .edit-post-visual-editor .highlight ul, #root .highlight ul {
      list-style: disc;
      margin: 5px auto 0 35px; }
      .edit-post-visual-editor .highlight ul li, #root .highlight ul li {
        margin-bottom: 0; }
    .edit-post-visual-editor .highlight .highlight-content, #root .highlight .highlight-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      -webkit-border-top-left-radius: 0;
      -webkit-border-top-right-radius: 0;
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-topleft: 0;
      -moz-border-radius-topright: 0;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      padding: 10px 30px;
      margin-bottom: 0; }
      .edit-post-visual-editor .highlight .highlight-content .has-icon, #root .highlight .highlight-content .has-icon {
        margin-bottom: 0; }
        .edit-post-visual-editor .highlight .highlight-content .has-icon .wp-block-column, #root .highlight .highlight-content .has-icon .wp-block-column {
          margin: 0; }
  .edit-post-visual-editor .text-underline, #root .text-underline {
    margin-top: -20px; }
  .edit-post-visual-editor .wp-block-quote, #root .wp-block-quote {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #fff6e1;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0MS40LDM3LjljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOAoJCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCQljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOGMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMQoJCWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44YzAtNDYuMiwyNC43LTg2LjcsNjUuNy0xMTUuNUwyNzEuMSwzNy45eiIvPgo8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: 90px 90px;
    background-position: 20px 14px;
    border-left: none;
    padding: 30px 45px 30px 30px;
    padding-left: 120px;
    background-color: #fff6e1;
    background-image: none;
    display: block;
    padding: 20px 45px 30px 105px;
    position: relative; }
    .edit-post-visual-editor .wp-block-quote:before, #root .wp-block-quote:before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNDEuNCwzNy45Yy0xNi44LDcuNC00MSwzMC41LTQ3LjgsNTAuNGMyLjYtMS4xLDcuOS0yLjEsMTMuNy0yLjFjMjUuMiwwLDQ1LjcsMTkuNCw0NS43LDQ4LjgKCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xCgljLTMyLDAtNjAuOS0yNC43LTYwLjktNjcuOGMwLTQ2LjIsMjQuNy04Ni43LDY1LjctMTE1LjVMMjcxLjEsMzcuOXoiLz4KPC9zdmc+Cg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      left: 2%;
      position: absolute;
      top: 7%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote:after, #root .wp-block-quote:after {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNDEuNCwzNy45Yy0xNi44LDcuNC00MSwzMC41LTQ3LjgsNTAuNGMyLjYtMS4xLDcuOS0yLjEsMTMuNy0yLjFjMjUuMiwwLDQ1LjcsMTkuNCw0NS43LDQ4LjgKCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xCgljLTMyLDAtNjAuOS0yNC43LTYwLjktNjcuOGMwLTQ2LjIsMjQuNy04Ni43LDY1LjctMTE1LjVMMjcxLjEsMzcuOXoiLz4KPC9zdmc+Cg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      right: 2%;
      position: absolute;
      bottom: 5%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote p, #root .wp-block-quote p {
      color: #008751 !important;
      font-size: 24px !important;
      font-weight: 700;
      font-stretch: condensed;
      line-height: 115% !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      padding: 0 !important; }
    .edit-post-visual-editor .wp-block-quote .quote-cite, #root .wp-block-quote .quote-cite {
      color: #828387 !important;
      font-size: 15px !important;
      font-weight: 400;
      line-height: 130%;
      display: block;
      line-height: 130%; }
    .edit-post-visual-editor .wp-block-quote cite, #root .wp-block-quote cite {
      color: #828387 !important;
      font-size: 15px !important;
      display: block;
      line-height: 130%; }
  .edit-post-visual-editor .wp-block-quote-small, #root .wp-block-quote-small {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #fff6e1;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0MS40LDM3LjljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOAoJCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCQljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOGMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMQoJCWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44YzAtNDYuMiwyNC43LTg2LjcsNjUuNy0xMTUuNUwyNzEuMSwzNy45eiIvPgo8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: 90px 90px;
    background-position: 20px 14px;
    border-left: none;
    padding: 30px 45px 30px 30px;
    padding-left: 120px;
    background-color: #fff6e1;
    background-image: none;
    display: block;
    padding: 20px 45px 30px 105px;
    position: relative; }
    .edit-post-visual-editor .wp-block-quote-small:before, #root .wp-block-quote-small:before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNDEuNCwzNy45Yy0xNi44LDcuNC00MSwzMC41LTQ3LjgsNTAuNGMyLjYtMS4xLDcuOS0yLjEsMTMuNy0yLjFjMjUuMiwwLDQ1LjcsMTkuNCw0NS43LDQ4LjgKCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xCgljLTMyLDAtNjAuOS0yNC43LTYwLjktNjcuOGMwLTQ2LjIsMjQuNy04Ni43LDY1LjctMTE1LjVMMjcxLjEsMzcuOXoiLz4KPC9zdmc+Cg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      left: 2%;
      position: absolute;
      top: 7%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote-small:after, #root .wp-block-quote-small:after {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNDEuNCwzNy45Yy0xNi44LDcuNC00MSwzMC41LTQ3LjgsNTAuNGMyLjYtMS4xLDcuOS0yLjEsMTMuNy0yLjFjMjUuMiwwLDQ1LjcsMTkuNCw0NS43LDQ4LjgKCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xCgljLTMyLDAtNjAuOS0yNC43LTYwLjktNjcuOGMwLTQ2LjIsMjQuNy04Ni43LDY1LjctMTE1LjVMMjcxLjEsMzcuOXoiLz4KPC9zdmc+Cg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      right: 2%;
      position: absolute;
      bottom: 5%;
      width: 55px; }
    .edit-post-visual-editor .wp-block-quote-small p, #root .wp-block-quote-small p {
      color: #ffb400 !important;
      font-size: 20px !important;
      font-weight: 700;
      font-stretch: condensed;
      line-height: 115% !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      padding: 0 !important; }
    .edit-post-visual-editor .wp-block-quote-small .quote-cite, #root .wp-block-quote-small .quote-cite {
      color: #828387 !important;
      font-size: 15px !important;
      font-weight: 400;
      line-height: 130%;
      display: block;
      line-height: 130%; }
    .edit-post-visual-editor .wp-block-quote-small cite, #root .wp-block-quote-small cite {
      color: #828387 !important;
      font-size: 15px !important;
      display: block;
      line-height: 130%; }
  .edit-post-visual-editor .background-quote-img, #root .background-quote-img {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #fff6e1;
    background-image: none;
    display: block; }
  .edit-post-visual-editor .green-quote, #root .green-quote {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    background-color: #fff6e1;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGQjQwMDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0MS40LDM3LjljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOAoJCWMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44QzM3LjQsNzYuMiw2MiwzNS44LDEwMyw2LjlMMTQxLjQsMzcuOXogTTI3MS4xLDM3LjkKCQljLTE2LjgsNy40LTQxLDMwLjUtNDcuOCw1MC40YzIuNi0xLjEsNy45LTIuMSwxMy43LTIuMWMyNS4yLDAsNDUuNywxOS40LDQ1LjcsNDguOGMwLDMwLjUtMjUuMiw1NS4xLTU0LjYsNTUuMQoJCWMtMzIsMC02MC45LTI0LjctNjAuOS02Ny44YzAtNDYuMiwyNC43LTg2LjcsNjUuNy0xMTUuNUwyNzEuMSwzNy45eiIvPgo8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: 90px 90px;
    background-position: 20px 14px;
    border-left: none;
    padding: 30px 45px 30px 30px;
    padding-left: 120px;
    background-color: #ececec;
    background-image: none;
    display: block;
    padding: 20px 45px 30px 105px;
    position: relative; }
    .edit-post-visual-editor .green-quote:before, #root .green-quote:before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMDA4NzUxO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTQxLjQsMzcuOWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44DQoJYzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xYy0zMiwwLTYwLjktMjQuNy02MC45LTY3LjhDMzcuNCw3Ni4yLDYyLDM1LjgsMTAzLDYuOUwxNDEuNCwzNy45eiBNMjcxLjEsMzcuOQ0KCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xDQoJYy0zMiwwLTYwLjktMjQuNy02MC45LTY3LjhjMC00Ni4yLDI0LjctODYuNyw2NS43LTExNS41TDI3MS4xLDM3Ljl6Ii8+DQo8L3N2Zz4NCg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      left: 2%;
      position: absolute;
      top: 7%;
      width: 55px; }
    .edit-post-visual-editor .green-quote:after, #root .green-quote:after {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMjAgMjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMjAyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMDA4NzUxO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTQxLjQsMzcuOWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44DQoJYzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xYy0zMiwwLTYwLjktMjQuNy02MC45LTY3LjhDMzcuNCw3Ni4yLDYyLDM1LjgsMTAzLDYuOUwxNDEuNCwzNy45eiBNMjcxLjEsMzcuOQ0KCWMtMTYuOCw3LjQtNDEsMzAuNS00Ny44LDUwLjRjMi42LTEuMSw3LjktMi4xLDEzLjctMi4xYzI1LjIsMCw0NS43LDE5LjQsNDUuNyw0OC44YzAsMzAuNS0yNS4yLDU1LjEtNTQuNiw1NS4xDQoJYy0zMiwwLTYwLjktMjQuNy02MC45LTY3LjhjMC00Ni4yLDI0LjctODYuNyw2NS43LTExNS41TDI3MS4xLDM3Ljl6Ii8+DQo8L3N2Zz4NCg==");
      background-size: 55px 55px;
      content: '';
      display: block;
      height: 55px;
      right: 2%;
      position: absolute;
      bottom: 5%;
      width: 55px; }
    .edit-post-visual-editor .green-quote p, #root .green-quote p {
      color: #008751 !important;
      font-size: 24px !important;
      font-weight: 700;
      font-stretch: condensed;
      line-height: 115% !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      padding: 0 !important; }
    .edit-post-visual-editor .green-quote .quote-cite, #root .green-quote .quote-cite {
      color: #828387 !important;
      font-size: 15px !important;
      font-weight: 400;
      line-height: 130%;
      display: block;
      line-height: 130%; }
    .edit-post-visual-editor .green-quote cite, #root .green-quote cite {
      color: #828387 !important;
      font-size: 15px !important;
      display: block;
      line-height: 130%; }
  .edit-post-visual-editor .list-column, #root .list-column {
    margin-bottom: 0; }
  .edit-post-visual-editor .wp-block-columns.subtitle, #root .wp-block-columns.subtitle {
    padding-left: 0; }
    .edit-post-visual-editor .wp-block-columns.subtitle img, #root .wp-block-columns.subtitle img {
      margin-top: 8px; }
  .edit-post-visual-editor .header-cover, #root .header-cover {
    background-size: cover;
    margin-bottom: -100px;
    padding: 0; }
  .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
    width: 100vw;
    object-position: right top;
    margin-left: calc(-50vw + 50%);
    margin-top: -95px !important;
    margin-bottom: 30px;
    padding: 50px 0 0 0 !important; }
    .edit-post-visual-editor .wp-block-cover.header-cover .wp-block-cover__image-background, #root .wp-block-cover.header-cover .wp-block-cover__image-background {
      border-bottom: 1px solid #ececec; }
  .edit-post-visual-editor .wp-block-cover__inner-container, #root .wp-block-cover__inner-container {
    width: 1127px !important;
    margin-left: auto;
    margin-right: auto; }
    .edit-post-visual-editor .wp-block-cover__inner-container .wp-block-columns:last-child, #root .wp-block-cover__inner-container .wp-block-columns:last-child {
      margin-bottom: 13px !important; }
  .edit-post-visual-editor .highlighted-module, #root .highlighted-module {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 1px solid #ececec; }
    .edit-post-visual-editor .highlighted-module .inline.list .ui.grid, #root .highlighted-module .inline.list .ui.grid {
      border-bottom: none; }
  .edit-post-visual-editor .myth, #root .myth {
    /*strong{
    color: $red;
    font-size: 24px;
  }*/ }
    .edit-post-visual-editor .myth a, #root .myth a {
      color: #2271b1; }
    .edit-post-visual-editor .myth p, #root .myth p {
      line-height: 130%; }
  .edit-post-visual-editor .fact, #root .fact {
    /*strong{
    color: $ng-green-title;
    font-size: 24px;
  }*/ }
    .edit-post-visual-editor .fact a, #root .fact a {
      color: #2271b1; }
    .edit-post-visual-editor .fact p, #root .fact p {
      line-height: 130%; }
  .edit-post-visual-editor .wp-block-column.myths-content, #root .wp-block-column.myths-content {
    padding-left: 20px; }
  .edit-post-visual-editor .underline a, #root .underline a {
    text-decoration: underline; }
  .edit-post-visual-editor .no-padding, #root .no-padding {
    padding: 0; }
  .edit-post-visual-editor .wp-block-column.page-feature, #root .wp-block-column.page-feature {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ececec;
    padding-right: 20px; }
    .edit-post-visual-editor .wp-block-column.page-feature .wp-block-columns, #root .wp-block-column.page-feature .wp-block-columns {
      margin-bottom: 0; }
    .edit-post-visual-editor .wp-block-column.page-feature h2, #root .wp-block-column.page-feature h2 {
      color: #ffb400;
      font-family: "Raleway", serif;
      font-size: 1.3rem !important;
      font-weight: bold;
      margin-bottom: -10px;
      margin-top: 10px; }
      .edit-post-visual-editor .wp-block-column.page-feature h2 a, #root .wp-block-column.page-feature h2 a {
        color: #ffb400; }
    .edit-post-visual-editor .wp-block-column.page-feature img, #root .wp-block-column.page-feature img {
      border-top-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 4px;
      -webkit-border-top-left-radius: 4px;
      -webkit-border-top-right-radius: 0;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-topleft: 4px;
      -moz-border-radius-topright: 0;
      -moz-border-radius-bottomright: 0;
      -moz-border-radius-bottomleft: 4px;
      border-right: 1px solid #ececec;
      margin-bottom: -5px; }
    .edit-post-visual-editor .wp-block-column.page-feature p, #root .wp-block-column.page-feature p {
      line-height: 120%; }
      .edit-post-visual-editor .wp-block-column.page-feature p a, #root .wp-block-column.page-feature p a {
        color: #52a963; }
        .edit-post-visual-editor .wp-block-column.page-feature p a:hover, #root .wp-block-column.page-feature p a:hover {
          color: #535454; }
  .edit-post-visual-editor .has-0-margin, #root .has-0-margin {
    margin: 0 !important; }
  .edit-post-visual-editor .has-0-bottom-margin, #root .has-0-bottom-margin {
    margin-bottom: 0 !important; }
  .edit-post-visual-editor .has-0-top-margin, #root .has-0-top-margin {
    margin-top: 0 !important; }
  .edit-post-visual-editor .has-0-padding, #root .has-0-padding {
    padding: 0 !important; }
  @media (min-width: 782px) {
    .edit-post-visual-editor .edit-post-visual-editor .wp-block-column:not(:first-child), .edit-post-visual-editor #root .wp-block-column:not(:first-child), #root .edit-post-visual-editor .wp-block-column:not(:first-child), #root #root .wp-block-column:not(:first-child) {
      margin-left: auto !important; } }
  .edit-post-visual-editor .ui.container .breadcrumbs a, #root .ui.container .breadcrumbs a {
    color: #00368c !important; }
  @media (max-width: 480px) {
    .edit-post-visual-editor .ui.container, #root .ui.container {
      margin: 0 !important; }
      .edit-post-visual-editor .ui.container .breadcrumbs, #root .ui.container .breadcrumbs {
        margin-left: 5% !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        font-size: 14px !important; }
      .edit-post-visual-editor .ui.container h1, #root .ui.container h1 {
        font-size: 26px !important;
        font-weight: 300 !important;
        margin: 10px 0 !important;
        font-family: Roboto, sans-serif !important; }
      .edit-post-visual-editor .ui.container p, #root .ui.container p {
        margin: 0 !important; }
      .edit-post-visual-editor .ui.container .page-intro, #root .ui.container .page-intro {
        font-size: 16px !important;
        margin-top: 15px !important;
        margin-bottom: 20px !important; }
      .edit-post-visual-editor .ui.container .ui.container.desktop, #root .ui.container .ui.container.desktop {
        margin: 0 5% !important; }
      .edit-post-visual-editor .ui.container .partners-column, #root .ui.container .partners-column {
        margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .wp-block-column, #root .ui.container .partners-column .wp-block-column {
          padding: 0 !important;
          margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .partners-column-logo, #root .ui.container .partners-column .partners-column-logo {
          margin-bottom: 50px !important; }
          .edit-post-visual-editor .ui.container .partners-column .partners-column-logo .wp-block-image img, #root .ui.container .partners-column .partners-column-logo .wp-block-image img {
            max-height: 130px !important;
            width: auto;
            justify-self: center; } }
  @media (min-width: 481px) and (max-width: 768px) {
    .edit-post-visual-editor .ui.container, #root .ui.container {
      margin: 0 !important;
      width: auto !important; }
      .edit-post-visual-editor .ui.container .breadcrumbs, #root .ui.container .breadcrumbs {
        margin-left: 8% !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        font-size: 16px !important; }
      .edit-post-visual-editor .ui.container h1, #root .ui.container h1 {
        font-size: 30px !important;
        font-weight: 300 !important;
        margin: 20px 0 !important;
        font-family: Roboto, sans-serif !important; }
      .edit-post-visual-editor .ui.container p, #root .ui.container p {
        margin: 0 !important; }
      .edit-post-visual-editor .ui.container .page-intro, #root .ui.container .page-intro {
        font-size: 18px !important;
        margin-top: 15px !important;
        margin-bottom: 20px !important; }
      .edit-post-visual-editor .ui.container .ui.container.desktop, #root .ui.container .ui.container.desktop {
        margin: 0 8% !important; }
      .edit-post-visual-editor .ui.container .partners-column, #root .ui.container .partners-column {
        margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .wp-block-column, #root .ui.container .partners-column .wp-block-column {
          padding: 0 !important;
          margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .partners-column-logo, #root .ui.container .partners-column .partners-column-logo {
          margin-bottom: 60px !important; }
          .edit-post-visual-editor .ui.container .partners-column .partners-column-logo .wp-block-image img, #root .ui.container .partners-column .partners-column-logo .wp-block-image img {
            max-height: 160px !important;
            width: auto;
            max-width: 400px;
            justify-self: center; } }
  @media (min-width: 769px) and (max-width: 1024px) {
    .edit-post-visual-editor .ui.container, #root .ui.container {
      margin: 0 !important;
      width: auto !important; }
      .edit-post-visual-editor .ui.container .breadcrumbs, #root .ui.container .breadcrumbs {
        margin-left: 10% !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        font-size: 16px !important; }
      .edit-post-visual-editor .ui.container h1, #root .ui.container h1 {
        font-size: 32px !important;
        font-weight: 300 !important;
        margin: 20px 0 !important;
        font-family: Roboto, sans-serif !important; }
      .edit-post-visual-editor .ui.container p, #root .ui.container p {
        margin: 0 !important; }
      .edit-post-visual-editor .ui.container .page-intro, #root .ui.container .page-intro {
        font-size: 20px !important;
        margin-top: 15px !important;
        margin-bottom: 20px !important; }
      .edit-post-visual-editor .ui.container .ui.container.desktop, #root .ui.container .ui.container.desktop {
        margin: 0 10% !important; }
      .edit-post-visual-editor .ui.container .partners-column, #root .ui.container .partners-column {
        margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .wp-block-column, #root .ui.container .partners-column .wp-block-column {
          padding: 0 !important;
          margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .partners-column-logo, #root .ui.container .partners-column .partners-column-logo {
          margin-bottom: 60px !important;
          align-self: center;
          min-width: 30%; }
          .edit-post-visual-editor .ui.container .partners-column .partners-column-logo .wp-block-image img, #root .ui.container .partners-column .partners-column-logo .wp-block-image img {
            max-height: 100px !important;
            width: auto;
            max-width: 100%;
            justify-self: center; } }
  @media (min-width: 1025px) and (max-width: 1366px) {
    .edit-post-visual-editor .ui.container, #root .ui.container {
      margin: 0 !important;
      width: auto !important; }
      .edit-post-visual-editor .ui.container .breadcrumbs, #root .ui.container .breadcrumbs {
        margin-left: 5% !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        font-size: 16px !important; }
      .edit-post-visual-editor .ui.container h1, #root .ui.container h1 {
        font-size: 38px !important;
        font-weight: 300 !important;
        margin: 30px 0 40px 0 !important;
        font-family: Roboto, sans-serif !important; }
      .edit-post-visual-editor .ui.container p, #root .ui.container p {
        margin: 0 !important; }
      .edit-post-visual-editor .ui.container .page-intro, #root .ui.container .page-intro {
        font-size: 22px !important;
        margin-top: 15px !important;
        margin-bottom: 40px !important; }
      .edit-post-visual-editor .ui.container .ui.container.desktop, #root .ui.container .ui.container.desktop {
        margin: 0 5% !important; }
      .edit-post-visual-editor .ui.container .partners-column, #root .ui.container .partners-column {
        margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .wp-block-column, #root .ui.container .partners-column .wp-block-column {
          padding: 0 !important;
          margin: 0 !important; }
        .edit-post-visual-editor .ui.container .partners-column .partners-column-logo, #root .ui.container .partners-column .partners-column-logo {
          margin-bottom: 80px !important;
          align-self: center;
          min-width: 30%; }
          .edit-post-visual-editor .ui.container .partners-column .partners-column-logo .wp-block-image img, #root .ui.container .partners-column .partners-column-logo .wp-block-image img {
            max-height: 140px !important;
            width: auto;
            max-width: 100%;
            justify-self: center; } }
  @media (min-width: 1366px) {
    .edit-post-visual-editor .ui.container .breadcrumbs, #root .ui.container .breadcrumbs {
      font-size: 16px !important; }
    .edit-post-visual-editor .ui.container h1, #root .ui.container h1 {
      font-weight: 300 !important;
      margin: 1em 0;
      font-family: Roboto, sans-serif !important; }
    .edit-post-visual-editor .ui.container .page-intro, #root .ui.container .page-intro {
      margin: 0 0 40px 0; }
    .edit-post-visual-editor .ui.container .ui.container.desktop, #root .ui.container .ui.container.desktop {
      width: 1127px;
      margin-left: auto;
      margin-right: auto; }
    .edit-post-visual-editor .ui.container .partners-column, #root .ui.container .partners-column {
      margin: 0 !important; }
      .edit-post-visual-editor .ui.container .partners-column .wp-block-column, #root .ui.container .partners-column .wp-block-column {
        padding: 0 !important;
        margin: 0 !important; }
      .edit-post-visual-editor .ui.container .partners-column .partners-column-logo, #root .ui.container .partners-column .partners-column-logo {
        margin-bottom: 80px !important;
        align-self: center;
        min-width: 33%; }
        .edit-post-visual-editor .ui.container .partners-column .partners-column-logo .wp-block-image img, #root .ui.container .partners-column .partners-column-logo .wp-block-image img {
          max-height: 130px !important;
          width: auto;
          max-width: 100%;
          justify-self: center; } }
  .edit-post-visual-editor .container.header, #root .container.header {
    height: 90px;
    padding: 0;
    margin: 0;
    box-shadow: none;
    left: auto; }
    .edit-post-visual-editor .container.header .background, #root .container.header .background {
      border-bottom: 1px solid #dadada;
      background-color: #f9f9f9; }
    .edit-post-visual-editor .container.header .menu.branding, #root .container.header .menu.branding {
      background-color: #f9f9f9;
      height: 96px;
      position: relative;
      z-index: 99999999; }
    .edit-post-visual-editor .container.header .branding.menu img, #root .container.header .branding.menu img {
      margin-top: 7px; }
    .edit-post-visual-editor .container.header .menu, .edit-post-visual-editor .container.header .branding, #root .container.header .menu, #root .container.header .branding {
      padding: 0;
      margin: 0;
      max-width: 1366px;
      margin: auto; }
      .edit-post-visual-editor .container.header .menu .divider div, .edit-post-visual-editor .container.header .branding .divider div, #root .container.header .menu .divider div, #root .container.header .branding .divider div {
        border-left: 1px solid #dadada;
        height: 46px; }
      .edit-post-visual-editor .container.header .menu .flag:before, .edit-post-visual-editor .container.header .branding .flag:before, #root .container.header .menu .flag:before, #root .container.header .branding .flag:before {
        width: 20px;
        height: 20px;
        margin-top: -5px;
        background-repeat: no-repeat !important;
        background: url("../public/flags/ng-flag-header.svg");
        background-size: 100% 100%;
        border: 1px solid #e7e7e7; }
      .edit-post-visual-editor .container.header .menu .item.fitted, .edit-post-visual-editor .container.header .branding .item.fitted, #root .container.header .menu .item.fitted, #root .container.header .branding .item.fitted {
        color: #000;
        font-size: 22px; }
      .edit-post-visual-editor .container.header .menu .item, .edit-post-visual-editor .container.header .branding .item, #root .container.header .menu .item, #root .container.header .branding .item {
        font-size: 15px;
        margin: 0px 1%; }
      .edit-post-visual-editor .container.header .menu .pages, .edit-post-visual-editor .container.header .branding .pages, #root .container.header .menu .pages, #root .container.header .branding .pages {
        align-self: flex-end;
        height: 96px;
        width: 100%; }
        .edit-post-visual-editor .container.header .menu .pages .item:nth-child(1), .edit-post-visual-editor .container.header .branding .pages .item:nth-child(1), #root .container.header .menu .pages .item:nth-child(1), #root .container.header .branding .pages .item:nth-child(1) {
          margin-left: 4%; }
        .edit-post-visual-editor .container.header .menu .pages .item, .edit-post-visual-editor .container.header .branding .pages .item, #root .container.header .menu .pages .item, #root .container.header .branding .pages .item {
          box-sizing: border-box;
          margin: 0px;
          text-transform: uppercase;
          justify-content: center;
          height: 71px;
          min-width: 12%;
          padding: 0px 3%;
          align-self: flex-end;
          border: 1px solid transparent;
          padding-bottom: 25px; }
          .edit-post-visual-editor .container.header .menu .pages .item a, .edit-post-visual-editor .container.header .branding .pages .item a, #root .container.header .menu .pages .item a, #root .container.header .branding .pages .item a {
            cursor: pointer;
            color: #54657e; }
            .edit-post-visual-editor .container.header .menu .pages .item a:hover, .edit-post-visual-editor .container.header .branding .pages .item a:hover, #root .container.header .menu .pages .item a:hover, #root .container.header .branding .pages .item a:hover {
              color: #008751; }
        .edit-post-visual-editor .container.header .menu .pages .item.selected, .edit-post-visual-editor .container.header .branding .pages .item.selected, #root .container.header .menu .pages .item.selected, #root .container.header .branding .pages .item.selected {
          font-weight: bold;
          height: 71px;
          color: #4aabb6;
          background-color: #FFF;
          border-bottom: 1px solid #FFF !important; }
          .edit-post-visual-editor .container.header .menu .pages .item.selected a, .edit-post-visual-editor .container.header .branding .pages .item.selected a, #root .container.header .menu .pages .item.selected a, #root .container.header .branding .pages .item.selected a {
            color: #008751; }
        .edit-post-visual-editor .container.header .menu .pages .item.has-child-items, .edit-post-visual-editor .container.header .branding .pages .item.has-child-items, #root .container.header .menu .pages .item.has-child-items, #root .container.header .branding .pages .item.has-child-items {
          color: #54657e;
          padding-bottom: 25px; }
          .edit-post-visual-editor .container.header .menu .pages .item.has-child-items span::after, .edit-post-visual-editor .container.header .branding .pages .item.has-child-items span::after, #root .container.header .menu .pages .item.has-child-items span::after, #root .container.header .branding .pages .item.has-child-items span::after {
            transition: 0.2s ease-in-out;
            transform: rotate(90deg);
            content: '\203A';
            margin-left: 13px;
            float: right;
            display: block; }
          .edit-post-visual-editor .container.header .menu .pages .item.has-child-items span, .edit-post-visual-editor .container.header .branding .pages .item.has-child-items span, #root .container.header .menu .pages .item.has-child-items span, #root .container.header .branding .pages .item.has-child-items span {
            cursor: pointer !important; }
        .edit-post-visual-editor .container.header .menu .pages .item.selected.has-child-items, .edit-post-visual-editor .container.header .branding .pages .item.selected.has-child-items, #root .container.header .menu .pages .item.selected.has-child-items, #root .container.header .branding .pages .item.selected.has-child-items {
          z-index: 1;
          color: #008751;
          border: 1px solid #dadada;
          margin-bottom: -1px;
          padding-bottom: 28px; }
          .edit-post-visual-editor .container.header .menu .pages .item.selected.has-child-items span::after, .edit-post-visual-editor .container.header .branding .pages .item.selected.has-child-items span::after, #root .container.header .menu .pages .item.selected.has-child-items span::after, #root .container.header .branding .pages .item.selected.has-child-items span::after {
            transform: rotate(-90deg);
            content: '\203A';
            margin-left: 13px;
            float: right;
            display: block; }
    .edit-post-visual-editor .container.header .container.child, #root .container.header .container.child {
      transform: translateY(-1px);
      position: relative;
      z-index: 3; }
      .edit-post-visual-editor .container.header .container.child .menu, #root .container.header .container.child .menu {
        background-color: #ffffff;
        max-width: 100%;
        margin-top: 0px;
        padding: 0px;
        border-bottom: 1px solid #dadada;
        border-top: 1px solid #dadada;
        justify-content: center;
        position: absolute;
        z-index: 2; }
        .edit-post-visual-editor .container.header .container.child .menu .item, #root .container.header .container.child .menu .item {
          margin-top: 1px;
          text-transform: uppercase;
          padding: 0px 10px;
          margin: 0px .5%; }
          .edit-post-visual-editor .container.header .container.child .menu .item:hover a, #root .container.header .container.child .menu .item:hover a {
            color: #00368c; }
          .edit-post-visual-editor .container.header .container.child .menu .item a, #root .container.header .container.child .menu .item a {
            color: #9798a0; }
          .edit-post-visual-editor .container.header .container.child .menu .item .mark, .edit-post-visual-editor .container.header .container.child .menu .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu .item.active .mark, #root .edit-post-visual-editor .container.header .container.child .menu .item.active .mark, #root .container.header .container.child .menu .item .mark, #root .container.header .container.child .menu .item.active .mark {
            width: 15px;
            height: 17px;
            background-size: 15px 15px;
            margin-right: 11px;
            background-image: url("../public/child-menu-arrow.svg");
            background-repeat: no-repeat; }
        .edit-post-visual-editor .container.header .container.child .menu .item.selected .mark, .edit-post-visual-editor #root .container.header .container.child .menu .item.selected .item.active .mark, #root .edit-post-visual-editor .container.header .container.child .menu .item.selected .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu .item.active .item.selected .mark, #root .edit-post-visual-editor .container.header .container.child .menu .item.active .item.selected .mark, #root .container.header .container.child .menu .item.selected .mark {
          width: 15px;
          height: 16px;
          background-size: 15px 15px;
          margin-right: 10px;
          background-repeat: no-repeat; }
        .edit-post-visual-editor .container.header .container.child .menu .item.selected, #root .container.header .container.child .menu .item.selected {
          color: #4aabb6; }
        .edit-post-visual-editor .container.header .container.child .menu .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu .item.active .mark, #root .edit-post-visual-editor .container.header .container.child .menu .item.active .mark, #root .container.header .container.child .menu .item.active .mark {
          background-image: url("../public/child-menu-arrow-active-ng.svg"); }
        .edit-post-visual-editor .container.header .container.child .menu .item.active a, #root .container.header .container.child .menu .item.active a {
          font-weight: 800;
          color: #00368c; }
  .edit-post-visual-editor .url.breadcrumbs, #root .url.breadcrumbs {
    margin-top: 30px;
    font-size: 16px;
    position: relative;
    z-index: 2;
    padding-top: 60px;
    height: 30px; }
    .edit-post-visual-editor .url.breadcrumbs span, #root .url.breadcrumbs span {
      color: #535454; }
    .edit-post-visual-editor .url.breadcrumbs span, #root .url.breadcrumbs span {
      color: #535454; }
    .edit-post-visual-editor .url.breadcrumbs span:after, .edit-post-visual-editor .url.breadcrumbs a:not(:last-child):after, #root .url.breadcrumbs span:after, #root .url.breadcrumbs a:not(:last-child):after {
      color: #535454;
      font-family: "Roboto";
      content: '/';
      display: inline;
      font-size: 16px;
      margin: 0px 10px; }
    .edit-post-visual-editor .url.breadcrumbs a, #root .url.breadcrumbs a {
      color: #535454; }
    .edit-post-visual-editor .url.breadcrumbs *, #root .url.breadcrumbs * {
      padding: 0px;
      margin: 0px; }
    .edit-post-visual-editor .url.breadcrumbs .active, #root .url.breadcrumbs .active {
      color: #008751; }
    .edit-post-visual-editor .url.breadcrumbs .entry-content, #root .url.breadcrumbs .entry-content {
      display: inline !important; }
    .edit-post-visual-editor .url.breadcrumbs .title, #root .url.breadcrumbs .title {
      font-size: 16px; }
  .edit-post-visual-editor .ui.search input.prompt, #root .ui.search input.prompt {
    border-radius: 0px; }
  .edit-post-visual-editor .ui.search i.search.icon, #root .ui.search i.search.icon {
    color: lightgrey;
    font-size: 0.8rem;
    opacity: 1; }
  .edit-post-visual-editor .result *, #root .result * {
    padding: 0px !important;
    margin: 0px !important; }
  .edit-post-visual-editor .result .search-content, #root .result .search-content {
    font-size: 16px !important; }
  .edit-post-visual-editor .hamburger-menu, #root .hamburger-menu {
    display: none;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 15px;
    right: 16px;
    z-index: 999999999;
    transition: none;
    /* Ensures no transition or animation initially */ }
  .edit-post-visual-editor .hamburger-menu div, #root .hamburger-menu div {
    width: 22px;
    height: 2.3px;
    background-color: #54657E;
    margin-bottom: 4px;
    border-radius: 10px;
    transform-origin: 50% 50%;
    transition: none;
    /* Initially no transition for child divs either */ }
  .edit-post-visual-editor .hamburger-menu.animate.open div:first-child, #root .hamburger-menu.animate.open div:first-child {
    animation: moveAndRotate1 0.3s ease-in-out forwards; }
  .edit-post-visual-editor .hamburger-menu.animate.open div:last-child, #root .hamburger-menu.animate.open div:last-child {
    animation: moveAndRotate2 0.3s ease-in-out forwards; }
  .edit-post-visual-editor .hamburger-menu.animate.close div:first-child, #root .hamburger-menu.animate.close div:first-child {
    animation: reverseMoveAndRotate1 0.3s ease-in-out forwards; }
  .edit-post-visual-editor .hamburger-menu.animate.close div:last-child, #root .hamburger-menu.animate.close div:last-child {
    animation: reverseMoveAndRotate2 0.3s ease-in-out forwards; }

@keyframes moveAndRotate1 {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(6px); }
  100% {
    transform: translateY(6px) rotate(45deg); } }

@keyframes moveAndRotate2 {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-6px); }
  100% {
    transform: translateY(-6px) rotate(-45deg); } }

@keyframes reverseMoveAndRotate1 {
  0% {
    transform: translateY(6px) rotate(45deg); }
  50% {
    transform: translateY(6px) rotate(0deg); }
  100% {
    transform: translateY(0) rotate(0deg); } }

@keyframes reverseMoveAndRotate2 {
  0% {
    transform: translateY(-6px) rotate(-45deg); }
  50% {
    transform: translateY(-6px) rotate(0deg); }
  100% {
    transform: translateY(0) rotate(0deg); } }
  .edit-post-visual-editor .hamburger-menu.animate.open .middle-line, #root .hamburger-menu.animate.open .middle-line {
    opacity: 0;
    transition: opacity 0s ease 0.15s;
    /* Apply the opacity change over 0.5 seconds, after a 1 second delay */ }
  .edit-post-visual-editor .hamburger-menu.animate.close .middle-line, #root .hamburger-menu.animate.close .middle-line {
    opacity: 1;
    transition: opacity 0s ease 0.15s;
    /* Apply the opacity change over 0.5 seconds, after a 1 second delay */ }
  .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
    border: none; }
  @media (max-width: 480px) {
    .edit-post-visual-editor .container.header, #root .container.header {
      height: 100px !important; }
    .edit-post-visual-editor .hamburger-menu, #root .hamburger-menu {
      display: block;
      right: 16px !important; }
    .edit-post-visual-editor .child, #root .child {
      display: none; }
    .edit-post-visual-editor .menu.branding, #root .menu.branding {
      height: 64px !important; }
      .edit-post-visual-editor .menu.branding .item, #root .menu.branding .item {
        margin: 0 !important; }
      .edit-post-visual-editor .menu.branding .brand.logo.small, #root .menu.branding .brand.logo.small {
        height: 60px;
        margin: 4px 4px 0 16px !important; }
    .edit-post-visual-editor .divider div, #root .divider div {
      height: 32px !important; }
    .edit-post-visual-editor .flag, #root .flag {
      margin-left: 15px; }
    .edit-post-visual-editor .site.name, #root .site.name {
      display: none; }
    .edit-post-visual-editor a.fitted.item, #root a.fitted.item {
      flex-grow: 9999; }
    .edit-post-visual-editor .menu.branding .pages, #root .menu.branding .pages {
      z-index: 999999 !important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%);
      /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out; }
      .edit-post-visual-editor .menu.branding .pages .item, #root .menu.branding .pages .item {
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        height: 60px !important;
        width: 320px;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important; }
        .edit-post-visual-editor .menu.branding .pages .item a, .edit-post-visual-editor .menu.branding .pages .item span, #root .menu.branding .pages .item a, #root .menu.branding .pages .item span {
          font-size: 21px;
          color: #54657E; }
        .edit-post-visual-editor .menu.branding .pages .item .mark, .edit-post-visual-editor .container.header .container.child .menu.branding .pages .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu.branding .pages .item.active .mark, #root .container.header .container.child .edit-post-visual-editor .menu.branding .pages .item.active .mark, #root .menu.branding .pages .item .mark, #root .container.header .container.child .menu.branding .pages .item.active .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("../public/child-menu-arrow.svg");
          background-repeat: no-repeat; }
      .edit-post-visual-editor .menu.branding .pages .child-item, #root .menu.branding .pages .child-item {
        height: 50px !important;
        transform: translateY(-5px); }
        .edit-post-visual-editor .menu.branding .pages .child-item a, #root .menu.branding .pages .child-item a {
          font-size: 18px !important; }
    .edit-post-visual-editor .menu.branding .pages.show, #root .menu.branding .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%; }
      .edit-post-visual-editor .menu.branding .pages.show .item, #root .menu.branding .pages.show .item {
        opacity: 1; }
    .edit-post-visual-editor .container.header .menu .pages .item.selected, .edit-post-visual-editor #root .container.header .branding .pages .item.selected, #root .container.header .menu .pages .item.selected, #root #root .container.header .branding .pages .item.selected {
      border-bottom: none !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #008751 !important;
      font-weight: 600 !important; }
    .edit-post-visual-editor .item.has-child-items span:hover, #root .item.has-child-items span:hover {
      color: #008751 !important; }
    .edit-post-visual-editor .has-child-items.selected span, #root .has-child-items.selected span {
      color: #008751; }
    .edit-post-visual-editor .child-item a:hover, #root .child-item a:hover {
      color: #00368c !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #00368c !important; }
    .edit-post-visual-editor .ui.float-search, #root .ui.float-search {
      margin: 12px 55px 0 0px;
      height: 54px !important;
      transform: translateY(-6.5px); }
      .edit-post-visual-editor .ui.float-search i, #root .ui.float-search i {
        margin-top: 5px; }
    .edit-post-visual-editor #float-input-container, #root #float-input-container {
      top: 62px !important;
      padding-left: 16px !important; }
      .edit-post-visual-editor #float-input-container .float-results-header, #root #float-input-container .float-results-header {
        padding-left: 16px !important;
        margin-left: 0 !important; }
      .edit-post-visual-editor #float-input-container .searching-results, #root #float-input-container .searching-results {
        padding-left: 16px !important; }
    .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
      margin-top: -86px; } }
  @media (min-width: 481px) and (max-width: 768px) {
    .edit-post-visual-editor .container.header, #root .container.header {
      height: 100px !important; }
    .edit-post-visual-editor .hamburger-menu, #root .hamburger-menu {
      display: block;
      right: 40px !important; }
    .edit-post-visual-editor .child, #root .child {
      display: none; }
    .edit-post-visual-editor .menu.branding, #root .menu.branding {
      height: 64px !important; }
      .edit-post-visual-editor .menu.branding .item, #root .menu.branding .item {
        margin: 0 !important; }
      .edit-post-visual-editor .menu.branding .brand.logo.small, #root .menu.branding .brand.logo.small {
        height: 60px;
        margin: 4px 4px 0 40px !important; }
    .edit-post-visual-editor .divider div, #root .divider div {
      height: 32px !important; }
    .edit-post-visual-editor .flag, #root .flag {
      margin-left: 15px; }
    .edit-post-visual-editor .site.name, #root .site.name {
      font-size: 19px; }
    .edit-post-visual-editor a.fitted.item, #root a.fitted.item {
      flex-grow: 9999; }
    .edit-post-visual-editor .menu.branding .pages, #root .menu.branding .pages {
      z-index: 999999 !important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%);
      /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out; }
      .edit-post-visual-editor .menu.branding .pages .item, #root .menu.branding .pages .item {
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        width: 320px;
        height: 60px !important;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important; }
        .edit-post-visual-editor .menu.branding .pages .item a, .edit-post-visual-editor .menu.branding .pages .item span, #root .menu.branding .pages .item a, #root .menu.branding .pages .item span {
          font-size: 21px;
          color: #54657E; }
        .edit-post-visual-editor .menu.branding .pages .item .mark, .edit-post-visual-editor .container.header .container.child .menu.branding .pages .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu.branding .pages .item.active .mark, #root .container.header .container.child .edit-post-visual-editor .menu.branding .pages .item.active .mark, #root .menu.branding .pages .item .mark, #root .container.header .container.child .menu.branding .pages .item.active .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("../public/child-menu-arrow.svg");
          background-repeat: no-repeat; }
      .edit-post-visual-editor .menu.branding .pages .child-item, #root .menu.branding .pages .child-item {
        height: 50px !important;
        transform: translateY(-5px); }
        .edit-post-visual-editor .menu.branding .pages .child-item a, #root .menu.branding .pages .child-item a {
          font-size: 18px !important; }
    .edit-post-visual-editor .menu.branding .pages.show, #root .menu.branding .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%; }
      .edit-post-visual-editor .menu.branding .pages.show .item, #root .menu.branding .pages.show .item {
        opacity: 1; }
    .edit-post-visual-editor .container.header .menu .pages .item.selected, .edit-post-visual-editor #root .container.header .branding .pages .item.selected, #root .container.header .menu .pages .item.selected, #root #root .container.header .branding .pages .item.selected {
      border-bottom: none !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #008751 !important;
      font-weight: 600 !important; }
    .edit-post-visual-editor .item.has-child-items span:hover, #root .item.has-child-items span:hover {
      color: #008751 !important; }
    .edit-post-visual-editor .has-child-items.selected span, #root .has-child-items.selected span {
      color: #008751; }
    .edit-post-visual-editor .child-item a:hover, #root .child-item a:hover {
      color: #00368c !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #00368c !important; }
    .edit-post-visual-editor .ui.float-search, #root .ui.float-search {
      margin: 12px 100px 0 20px;
      height: 54px !important;
      transform: translateY(-6.5px); }
      .edit-post-visual-editor .ui.float-search i, #root .ui.float-search i {
        margin-top: 5px; }
    .edit-post-visual-editor #float-input-container, #root #float-input-container {
      top: 62px !important;
      padding-left: 40px !important; }
      .edit-post-visual-editor #float-input-container .float-results-header, #root #float-input-container .float-results-header {
        padding-left: 40px !important;
        margin-left: 0 !important; }
      .edit-post-visual-editor #float-input-container .searching-results, #root #float-input-container .searching-results {
        padding-left: 40px !important; }
    .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
      margin-top: -86px; } }
  @media (min-width: 769px) and (max-width: 1024px) {
    .edit-post-visual-editor .container.header, #root .container.header {
      height: 120px !important; }
    .edit-post-visual-editor .hamburger-menu, #root .hamburger-menu {
      display: block;
      right: 105px !important; }
    .edit-post-visual-editor .child, #root .child {
      display: none; }
    .edit-post-visual-editor .menu.branding, #root .menu.branding {
      height: 64px !important; }
      .edit-post-visual-editor .menu.branding .item, #root .menu.branding .item {
        margin: 0 !important; }
      .edit-post-visual-editor .menu.branding .brand.logo.small, #root .menu.branding .brand.logo.small {
        height: 60px;
        margin: 4px 4px 0 105px !important; }
    .edit-post-visual-editor .divider div, #root .divider div {
      height: 32px !important; }
    .edit-post-visual-editor .flag, #root .flag {
      margin-left: 15px; }
    .edit-post-visual-editor .site.name, #root .site.name {
      font-size: 19px; }
    .edit-post-visual-editor a.fitted.item, #root a.fitted.item {
      flex-grow: 9999; }
    .edit-post-visual-editor .menu.branding .pages, #root .menu.branding .pages {
      z-index: 999999 !important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%);
      /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out; }
      .edit-post-visual-editor .menu.branding .pages .item, #root .menu.branding .pages .item {
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        width: 320px;
        height: 60px !important;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important; }
        .edit-post-visual-editor .menu.branding .pages .item a, .edit-post-visual-editor .menu.branding .pages .item span, #root .menu.branding .pages .item a, #root .menu.branding .pages .item span {
          font-size: 21px;
          color: #54657E; }
        .edit-post-visual-editor .menu.branding .pages .item .mark, .edit-post-visual-editor .container.header .container.child .menu.branding .pages .item.active .mark, .edit-post-visual-editor #root .container.header .container.child .menu.branding .pages .item.active .mark, #root .container.header .container.child .edit-post-visual-editor .menu.branding .pages .item.active .mark, #root .menu.branding .pages .item .mark, #root .container.header .container.child .menu.branding .pages .item.active .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("../public/child-menu-arrow.svg");
          background-repeat: no-repeat; }
      .edit-post-visual-editor .menu.branding .pages .child-item, #root .menu.branding .pages .child-item {
        height: 50px !important;
        transform: translateY(-5px); }
        .edit-post-visual-editor .menu.branding .pages .child-item a, #root .menu.branding .pages .child-item a {
          font-size: 18px !important; }
    .edit-post-visual-editor .menu.branding .pages.show, #root .menu.branding .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%; }
      .edit-post-visual-editor .menu.branding .pages.show .item, #root .menu.branding .pages.show .item {
        opacity: 1; }
    .edit-post-visual-editor .container.header .menu .pages .item.selected, .edit-post-visual-editor #root .container.header .branding .pages .item.selected, #root .container.header .menu .pages .item.selected, #root #root .container.header .branding .pages .item.selected {
      border-bottom: none !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #008751 !important;
      font-weight: 600 !important; }
    .edit-post-visual-editor .item.has-child-items span:hover, #root .item.has-child-items span:hover {
      color: #008751 !important; }
    .edit-post-visual-editor .has-child-items.selected span, #root .has-child-items.selected span {
      color: #008751; }
    .edit-post-visual-editor .child-item a:hover, #root .child-item a:hover {
      color: #00368c !important; }
    .edit-post-visual-editor .child-item.active a, #root .child-item.active a {
      color: #00368c !important; }
    .edit-post-visual-editor .ui.float-search, #root .ui.float-search {
      margin: 12px 175px 0 20px;
      height: 54px !important;
      transform: translateY(-6.5px); }
      .edit-post-visual-editor .ui.float-search i, #root .ui.float-search i {
        margin-top: 5px; }
    .edit-post-visual-editor #float-input-container, #root #float-input-container {
      top: 62px !important; }
    .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
      margin-top: -86px; } }
  @media (min-width: 1025px) and (max-width: 1365px) {
    .edit-post-visual-editor .container.header, #root .container.header {
      height: 130px !important; }
    .edit-post-visual-editor .container.header .menu.branding, #root .container.header .menu.branding {
      height: 64px; }
      .edit-post-visual-editor .container.header .menu.branding .item, #root .container.header .menu.branding .item {
        margin: 0; }
      .edit-post-visual-editor .container.header .menu.branding .brand.logo.small, #root .container.header .menu.branding .brand.logo.small {
        height: 60px;
        margin: 4px 4px 0 48px; }
    .edit-post-visual-editor .container.header .divider div, #root .container.header .divider div {
      height: 32px !important; }
    .edit-post-visual-editor .container.header .site.name, #root .container.header .site.name {
      font-size: 19px; }
    .edit-post-visual-editor .container.header .flag, #root .container.header .flag {
      margin-left: 15px; }
    .edit-post-visual-editor .container.header .pages, #root .container.header .pages {
      height: 54px !important;
      margin: 0; }
      .edit-post-visual-editor .container.header .pages .item, #root .container.header .pages .item {
        height: 54px !important; }
        .edit-post-visual-editor .container.header .pages .item span, .edit-post-visual-editor .container.header .pages .item a, #root .container.header .pages .item span, #root .container.header .pages .item a {
          margin-top: 18px;
          font-size: 14px; }
    .edit-post-visual-editor .ui.float-search, #root .ui.float-search {
      margin: 0 48px 0 48px;
      height: 54px !important;
      transform: translateY(-6.5px); }
      .edit-post-visual-editor .ui.float-search i, #root .ui.float-search i {
        margin-top: 5px; }
    .edit-post-visual-editor #float-input-container, #root #float-input-container {
      top: 62px !important;
      padding-left: 60px !important; }
      .edit-post-visual-editor #float-input-container .float-results-header, #root #float-input-container .float-results-header {
        padding-left: 60px !important;
        margin-left: 0 !important; }
      .edit-post-visual-editor #float-input-container .searching-results, #root #float-input-container .searching-results {
        padding-left: 60px !important; }
    .edit-post-visual-editor .wp-block-cover.header-cover, #root .wp-block-cover.header-cover {
      margin-top: -86px; } }
  .edit-post-visual-editor .container.viz.footer, #root .container.viz.footer {
    margin: 0 !important; }
    .edit-post-visual-editor .container.viz.footer .ui.container.entry-content, #root .container.viz.footer .ui.container.entry-content {
      width: 100vw;
      margin: 0 !important; }
      .edit-post-visual-editor .container.viz.footer .ui.container.entry-content .wp-block-columns, #root .container.viz.footer .ui.container.entry-content .wp-block-columns {
        margin: 0; }
      .edit-post-visual-editor .container.viz.footer .ui.container.entry-content .ui.fluid.container.content, #root .container.viz.footer .ui.container.entry-content .ui.fluid.container.content {
        margin: 0 !important; }
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding: 3vw 10vw; }
      .edit-post-visual-editor .container.viz.footer .logos-column .logos-column-item, #root .container.viz.footer .logos-column .logos-column-item {
        margin: 0 4vw; }
    .edit-post-visual-editor .container.viz.footer .footer-cover, #root .container.viz.footer .footer-cover {
      border-top: 1px solid #008751;
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      padding-top: 30px;
      padding-bottom: 30px; }
      .edit-post-visual-editor .container.viz.footer .footer-cover a, #root .container.viz.footer .footer-cover a {
        color: #e5f6ff; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .wp-block-columns, #root .container.viz.footer .footer-cover .wp-block-columns {
        margin-bottom: 0; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .bottom-bar-container, #root .container.viz.footer .footer-cover .bottom-bar-container {
        margin: auto;
        max-width: 1300px; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .wp-block-cover__background, #root .container.viz.footer .footer-cover .wp-block-cover__background {
        background-color: #008751 !important; }
    .edit-post-visual-editor .container.viz.footer .attribution-icon, #root .container.viz.footer .attribution-icon {
      display: block;
      float: right;
      margin-right: 1em;
      min-width: 85px; }
    .edit-post-visual-editor .container.viz.footer .bottom-menu, #root .container.viz.footer .bottom-menu {
      border-bottom: 1px solid #B0CAD8;
      display: inline-block;
      padding-bottom: 20px;
      margin-bottom: 25px !important; }
      .edit-post-visual-editor .container.viz.footer .bottom-menu a, #root .container.viz.footer .bottom-menu a {
        margin-right: 20px; }
        .edit-post-visual-editor .container.viz.footer .bottom-menu a:last-of-type, #root .container.viz.footer .bottom-menu a:last-of-type {
          margin-right: 0; }
    .edit-post-visual-editor .container.viz.footer .column, #root .container.viz.footer .column {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem; }
    .edit-post-visual-editor .container.viz.footer .header, #root .container.viz.footer .header {
      font-weight: 400;
      color: white;
      text-transform: uppercase;
      margin: 0px; }
    .edit-post-visual-editor .container.viz.footer p, #root .container.viz.footer p {
      font-weight: 400;
      color: white; }
      .edit-post-visual-editor .container.viz.footer p a, #root .container.viz.footer p a {
        color: #ffffff; }
        .edit-post-visual-editor .container.viz.footer p a:hover, #root .container.viz.footer p a:hover {
          color: #f0f0f0; }
    .edit-post-visual-editor .container.viz.footer .viz.secondary-menu.container, #root .container.viz.footer .viz.secondary-menu.container {
      background-color: #175372;
      text-align: center; }
  @media (max-width: 480px) {
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding: 10vw 15vw; }
      .edit-post-visual-editor .container.viz.footer .logos-column .logos-column-item, #root .container.viz.footer .logos-column .logos-column-item {
        margin: 10vw 0; }
    .edit-post-visual-editor .container.viz.footer .footer-cover a, #root .container.viz.footer .footer-cover a {
      width: 100vw;
      text-align: center;
      display: block;
      margin: 0 0 30px 0; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected, #root .container.viz.footer .footer-cover .stay-connected {
      justify-self: center;
      align-self: center;
      text-align: center; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected p, #root .container.viz.footer .footer-cover .stay-connected p {
        padding: 30px 0; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected .stay-connected-icon img, #root .container.viz.footer .footer-cover .stay-connected .stay-connected-icon img {
        width: 100px !important;
        height: auto !important;
        margin-bottom: 15px !important; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected .contact-block, #root .container.viz.footer .footer-cover .stay-connected .contact-block {
        text-align: center;
        line-height: 2;
        padding: 10px 0;
        border-bottom: 1px solid #B0CAD8; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-column, #root .container.viz.footer .footer-cover .creative-commons .wp-block-column {
      padding: 0 20px; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-image, #root .container.viz.footer .footer-cover .creative-commons .wp-block-image {
      width: 100vw;
      margin: 10px 0 30px 0; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-image img, #root .container.viz.footer .footer-cover .creative-commons .wp-block-image img {
        width: 150px !important;
        text-align: center; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons p, #root .container.viz.footer .footer-cover .creative-commons p {
      text-align: center; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons p a, #root .container.viz.footer .footer-cover .creative-commons p a {
        display: inline; } }
  @media (min-width: 481px) and (max-width: 782px) {
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding: 10vw 30vw; }
      .edit-post-visual-editor .container.viz.footer .logos-column .logos-column-item, #root .container.viz.footer .logos-column .logos-column-item {
        margin: 8vw 0; }
    .edit-post-visual-editor .container.viz.footer .footer-cover a, #root .container.viz.footer .footer-cover a {
      width: 100vw;
      text-align: center;
      display: block;
      margin: 0 0 30px 0; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected, #root .container.viz.footer .footer-cover .stay-connected {
      justify-self: center;
      align-self: center;
      text-align: center; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected p, #root .container.viz.footer .footer-cover .stay-connected p {
        padding: 30px 0; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected .stay-connected-icon img, #root .container.viz.footer .footer-cover .stay-connected .stay-connected-icon img {
        width: 100px !important;
        height: auto !important;
        margin-bottom: 15px !important; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected .contact-block, #root .container.viz.footer .footer-cover .stay-connected .contact-block {
        text-align: center;
        line-height: 2;
        padding: 10px 0;
        border-bottom: 1px solid #B0CAD8; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .stay-connected .wp-block-column, #root .container.viz.footer .footer-cover .stay-connected .wp-block-column {
        min-width: fit-content !important; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-column, #root .container.viz.footer .footer-cover .creative-commons .wp-block-column {
      padding: 0 20px; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-image, #root .container.viz.footer .footer-cover .creative-commons .wp-block-image {
      width: 100vw;
      margin: 10px 0 30px 0; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-image img, #root .container.viz.footer .footer-cover .creative-commons .wp-block-image img {
        width: 150px !important;
        text-align: center; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons p, #root .container.viz.footer .footer-cover .creative-commons p {
      text-align: center; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons p a, #root .container.viz.footer .footer-cover .creative-commons p a {
        display: inline; } }
  @media (min-width: 782px) and (max-width: 1024px) {
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding: 5vw 10vw; }
    .edit-post-visual-editor .container.viz.footer .footer-cover .bottom-bar-container, #root .container.viz.footer .footer-cover .bottom-bar-container {
      display: flex;
      flex-direction: column; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .bottom-bar-container .bottom-bar-container-column, #root .container.viz.footer .footer-cover .bottom-bar-container .bottom-bar-container-column {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        flex-wrap: wrap; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .bottom-bar-container .creative-commons .creative-commons-columns .creative-commons-column, #root .container.viz.footer .footer-cover .bottom-bar-container .creative-commons .creative-commons-columns .creative-commons-column {
        flex-basis: auto !important;
        width: auto !important; }
      .edit-post-visual-editor .container.viz.footer .footer-cover .bottom-bar-container .contact-block, #root .container.viz.footer .footer-cover .bottom-bar-container .contact-block {
        width: 500px; } }
  @media (min-width: 1025px) and (max-width: 1365px) {
    .edit-post-visual-editor .container.viz.footer .footer-cover .creative-commons .wp-block-spacer, #root .container.viz.footer .footer-cover .creative-commons .wp-block-spacer {
      display: none; } }
  @media (min-width: 1366px) and (max-width: 1920px) {
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding-left: 15vw;
      padding-right: 15vw; } }
  @media (min-width: 1921px) {
    .edit-post-visual-editor .container.viz.footer .logos-column, #root .container.viz.footer .logos-column {
      padding-left: 23vw;
      padding-right: 23vw; } }
  .edit-post-visual-editor body.illicit, #root body.illicit {
    background-image: url("../public/old/smoke-on-white_v2.png");
    background-repeat: no-repeat;
    background-size: 1366px 768px;
    background-position: center top;
    background-attachment: scroll; }
  .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection, #root .dashboard.illicit .section.illicit-prevalence .subsection {
    background-color: #f4f4f4; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .loss .wp-block-columns, #root .dashboard.illicit .section.illicit-prevalence .subsection .loss .wp-block-columns {
      padding: 1vw; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .loss .wp-block-separator, #root .dashboard.illicit .section.illicit-prevalence .subsection .loss .wp-block-separator {
      margin: 0;
      padding: 0;
      max-width: 90% !important;
      border-color: #dadada;
      border-bottom: 0; }
    @media screen and (max-width: 781px) and (min-width: 600px) {
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .products .wp-block-column:nth-child(1),
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .taxes .wp-block-column:nth-child(1), #root .dashboard.illicit .section.illicit-prevalence .subsection .products .wp-block-column:nth-child(1),
      #root .dashboard.illicit .section.illicit-prevalence .subsection .taxes .wp-block-column:nth-child(1) {
        flex-basis: calc(20% - 16px) !important;
        flex-grow: 0; } }
    @media (max-width: 599px) {
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .products .wp-block-column:nth-child(1),
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .taxes .wp-block-column:nth-child(1), #root .dashboard.illicit .section.illicit-prevalence .subsection .products .wp-block-column:nth-child(1),
      #root .dashboard.illicit .section.illicit-prevalence .subsection .taxes .wp-block-column:nth-child(1) {
        display: none;
        flex-grow: 0; } }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .products figure,
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .taxes figure, #root .dashboard.illicit .section.illicit-prevalence .subsection .products figure,
    #root .dashboard.illicit .section.illicit-prevalence .subsection .taxes figure {
      padding: 0;
      margin: 0; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .products p,
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .taxes p, #root .dashboard.illicit .section.illicit-prevalence .subsection .products p,
    #root .dashboard.illicit .section.illicit-prevalence .subsection .taxes p {
      margin: 0; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header), #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) {
      margin: 0;
      padding: 0 2vw;
      background-color: transparent !important; }
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .row, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .row {
        padding: 0;
        margin: 0; }
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column {
        margin: 0; }
        .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button {
          height: 60px;
          border-radius: 1vmin;
          display: flex;
          text-align: left;
          width: 100%;
          color: #FFF; }
          .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button .icon, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button .icon {
            background-position: center;
            background-repeat: no-repeat;
            width: auto;
            float: left;
            min-width: 40px;
            min-width: 40px;
            min-height: 30px; }
          .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button .title, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .column button .title {
            font-size: 1.5em;
            margin: auto auto auto 2%; }
      .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .active button, #root .dashboard.illicit .section.illicit-prevalence .subsection .types .grid:not(.header) .active button {
        background-color: #5baf95; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .map .map.component, #root .dashboard.illicit .section.illicit-prevalence .subsection .map .map.component {
      margin: 1.5vw; }
    .edit-post-visual-editor .dashboard.illicit .section.illicit-prevalence .subsection .map .entry-content, #root .dashboard.illicit .section.illicit-prevalence .subsection .map .entry-content {
      padding-top: 0;
      margin-left: 3.5vw;
      margin-right: 3.5vw; }
  .edit-post-visual-editor .viz.tabbed.posts.container, #root .viz.tabbed.posts.container {
    margin: 0;
    min-height: 320px;
    padding: 0px 20px; }
    .edit-post-visual-editor .viz.tabbed.posts.container .label, #root .viz.tabbed.posts.container .label {
      font-weight: 400;
      margin: 0 !important;
      font-size: 16px;
      padding: 0 !important; }
    .edit-post-visual-editor .viz.tabbed.posts.container .viz.download .download-header, #root .viz.tabbed.posts.container .viz.download .download-header {
      align-items: center; }
      .edit-post-visual-editor .viz.tabbed.posts.container .viz.download .download-header .ui.container.entry-content, #root .viz.tabbed.posts.container .viz.download .download-header .ui.container.entry-content {
        padding-bottom: 10px !important;
        padding-top: 10px !important; }
  .edit-post-visual-editor .ui.grid.tabbed.posts, #root .ui.grid.tabbed.posts {
    background-color: transparent !important; }
    .edit-post-visual-editor .ui.grid.tabbed.posts .row, #root .ui.grid.tabbed.posts .row {
      border: 1.2px solid #B7B7B7;
      border-radius: 0 0 5px 5px;
      padding: 0;
      margin: 0; }
      .edit-post-visual-editor .ui.grid.tabbed.posts .row .sixteen.wide.column.content, #root .ui.grid.tabbed.posts .row .sixteen.wide.column.content {
        overflow: hidden;
        padding: 0; }
        .edit-post-visual-editor .ui.grid.tabbed.posts .row .sixteen.wide.column.content .entry-content, #root .ui.grid.tabbed.posts .row .sixteen.wide.column.content .entry-content {
          padding: 25px 33px 0 33px; }
    .edit-post-visual-editor .ui.grid.tabbed.posts > .column, #root .ui.grid.tabbed.posts > .column {
      margin: 0 0 -1px 0;
      padding: .2vw 0 0 3px; }
      .edit-post-visual-editor .ui.grid.tabbed.posts > .column:first-child, #root .ui.grid.tabbed.posts > .column:first-child {
        padding-left: 0; }
      .edit-post-visual-editor .ui.grid.tabbed.posts > .column button.nav, #root .ui.grid.tabbed.posts > .column button.nav {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 0;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 0;
        -moz-border-radius-bottomleft: 0;
        background-color: #5BAF95;
        border: 1.2px solid #B7B7B7;
        height: 45px;
        width: 100%;
        padding: 0;
        margin: 0px;
        color: #a6a6a6;
        text-align: left;
        border-bottom: none;
        transform: translateY(0.1px) !important;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap; }
        .edit-post-visual-editor .ui.grid.tabbed.posts > .column button.nav .label, #root .ui.grid.tabbed.posts > .column button.nav .label {
          align-self: center;
          color: #ffffff;
          background-color: transparent !important;
          font-weight: 700; }
      .edit-post-visual-editor .ui.grid.tabbed.posts > .column .button.one.nav, #root .ui.grid.tabbed.posts > .column .button.one.nav {
        width: auto !important; }
    .edit-post-visual-editor .ui.grid.tabbed.posts .column.has-icon button.nav, #root .ui.grid.tabbed.posts .column.has-icon button.nav {
      display: flex;
      justify-content: center; }
      .edit-post-visual-editor .ui.grid.tabbed.posts .column.has-icon button.nav .icon, #root .ui.grid.tabbed.posts .column.has-icon button.nav .icon {
        border: 0px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: auto;
        float: left;
        height: 30px;
        margin: 0 3px 0 0;
        filter: invert(0) saturate(0) brightness(200%);
        opacity: 1 !important; }
    .edit-post-visual-editor .ui.grid.tabbed.posts .column.active.has-icon button.nav .icon, #root .ui.grid.tabbed.posts .column.active.has-icon button.nav .icon {
      filter: none;
      opacity: 1 !important; }
    .edit-post-visual-editor .ui.grid.tabbed.posts button.nav:hover, #root .ui.grid.tabbed.posts button.nav:hover {
      background-color: #398570; }
    .edit-post-visual-editor .ui.grid.tabbed.posts .active button.nav, #root .ui.grid.tabbed.posts .active button.nav {
      color: #ffffff;
      background-color: #ffffff !important;
      transform: translateY(0.1px) !important;
      position: relative;
      z-index: 9; }
      .edit-post-visual-editor .ui.grid.tabbed.posts .active button.nav .label, #root .ui.grid.tabbed.posts .active button.nav .label {
        color: #5BAF95;
        text-align: center; }
        .edit-post-visual-editor .ui.grid.tabbed.posts .active button.nav .label span, #root .ui.grid.tabbed.posts .active button.nav .label span {
          border-bottom: 2px solid #5BAF95;
          /* Set the border properties as needed */
          border-radius: 0;
          padding-bottom: 2px;
          /* Adjust the padding to control the space between text and line */ }
  .edit-post-visual-editor .ui.menu.tabbed.posts, #root .ui.menu.tabbed.posts {
    padding: 0px;
    margin: 0px; }
    .edit-post-visual-editor .ui.menu.tabbed.posts .item, #root .ui.menu.tabbed.posts .item {
      margin: 0px;
      padding: 0px;
      color: #66676d !important;
      border: 0px;
      font-size: 18px; }
      .edit-post-visual-editor .ui.menu.tabbed.posts .item .label, #root .ui.menu.tabbed.posts .item .label {
        background-color: transparent !important;
        color: #66676d;
        padding: 0px; }
      .edit-post-visual-editor .ui.menu.tabbed.posts .item:last-child:after, #root .ui.menu.tabbed.posts .item:last-child:after {
        content: none; }
    .edit-post-visual-editor .ui.menu.tabbed.posts .item.active, #root .ui.menu.tabbed.posts .item.active {
      color: #4aabb6 !important;
      font-weight: 700;
      padding-left: 10px; }
      .edit-post-visual-editor .ui.menu.tabbed.posts .item.active .label, #root .ui.menu.tabbed.posts .item.active .label {
        background-color: transparent !important;
        color: #4aabb6;
        font-weight: bold;
        padding: 0; }
      .edit-post-visual-editor .ui.menu.tabbed.posts .item.active:after, #root .ui.menu.tabbed.posts .item.active:after {
        color: #66676d;
        font-weight: normal; }
    .edit-post-visual-editor .ui.menu.tabbed.posts a.item.active::before, #root .ui.menu.tabbed.posts a.item.active::before {
      content: '';
      background-image: url("../public/green_arrow_active.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 18px !important;
      height: 20px !important;
      background-color: transparent !important;
      display: block !important;
      position: relative !important;
      margin-left: -18px;
      margin-right: 8px; }
    .edit-post-visual-editor .ui.menu.tabbed.posts .item::after, #root .ui.menu.tabbed.posts .item::after {
      content: '|';
      margin-left: 18px;
      margin-right: 18px; }
  .edit-post-visual-editor .container.viz.tabbed.posts.editing .button.nav, #root .container.viz.tabbed.posts.editing .button.nav {
    margin-top: 20px; }
  .edit-post-visual-editor .wp-block-viz-components-tabbed-posts .viz-component[data-theme='light'] .ui.menu.tabbed.posts .item .label, #root .wp-block-viz-components-tabbed-posts .viz-component[data-theme='light'] .ui.menu.tabbed.posts .item .label {
    font-size: 18px; }
  .edit-post-visual-editor .tabbed-no-padding .row .sixteen.wide.column.content, #root .tabbed-no-padding .row .sixteen.wide.column.content {
    margin: 0 !important;
    padding: 0 !important; }
    .edit-post-visual-editor .tabbed-no-padding .row .sixteen.wide.column.content .ui.fluid.container.entry-content, #root .tabbed-no-padding .row .sixteen.wide.column.content .ui.fluid.container.entry-content {
      padding: 0 !important;
      margin: 0 !important; }
  .edit-post-visual-editor .viz.tabbed.posts.container.scrollable, #root .viz.tabbed.posts.container.scrollable {
    margin: 0 0 65px 0; }
    .edit-post-visual-editor .viz.tabbed.posts.container.scrollable .content-tab, #root .viz.tabbed.posts.container.scrollable .content-tab {
      overflow-y: auto;
      overflow-x: hidden; }
  .edit-post-visual-editor .tabbed-no-padding .row .sixteen.wide.column.content, #root .tabbed-no-padding .row .sixteen.wide.column.content {
    margin: 0 !important;
    padding: 0 !important; }
    .edit-post-visual-editor .tabbed-no-padding .row .sixteen.wide.column.content .ui.fluid.container.entry-content, #root .tabbed-no-padding .row .sixteen.wide.column.content .ui.fluid.container.entry-content {
      padding: 0 !important;
      margin: 0 !important; }
  .edit-post-visual-editor .ui.grid.tabbed.posts > .column button.nav, #root .ui.grid.tabbed.posts > .column button.nav {
    background-color: #008751; }
  .edit-post-visual-editor .ui.grid.tabbed.posts button.nav:hover, #root .ui.grid.tabbed.posts button.nav:hover {
    background-color: #02603b; }
  .edit-post-visual-editor .ui.grid.tabbed.posts .active button.nav .label, #root .ui.grid.tabbed.posts .active button.nav .label {
    color: #008751; }
    .edit-post-visual-editor .ui.grid.tabbed.posts .active button.nav .label span, #root .ui.grid.tabbed.posts .active button.nav .label span {
      border-bottom: 2px solid #008751; }
  .edit-post-visual-editor .body.parts, #root .body.parts {
    padding: 0px;
    margin: auto; }
    .edit-post-visual-editor .body.parts text.label, #root .body.parts text.label {
      cursor: pointer;
      font-size: 14px;
      fill: #66676d;
      font-weight: bold; }
    .edit-post-visual-editor .body.parts text.label:hover, #root .body.parts text.label:hover {
      fill: #5089a6; }
    .edit-post-visual-editor .body.parts text.title, #root .body.parts text.title {
      cursor: pointer;
      font-size: 16px;
      fill: #3e53a6;
      font-weight: bold; }
    .edit-post-visual-editor .body.parts circle,
    .edit-post-visual-editor .body.parts line, #root .body.parts circle,
    #root .body.parts line {
      stroke: #5089a6; }
    .edit-post-visual-editor .body.parts circle, #root .body.parts circle {
      fill: #5089a6; }
    .edit-post-visual-editor .body.parts svg, #root .body.parts svg {
      margin: auto;
      width: 100%; }
  .edit-post-visual-editor .st0, #root .st0 {
    fill: #674534; }
  .edit-post-visual-editor .st1, #root .st1 {
    fill: url(#SVGID_1_); }
  .edit-post-visual-editor .st2, #root .st2 {
    fill: #C8BFB8; }
  .edit-post-visual-editor .st3, #root .st3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #C8BFB8; }
  .edit-post-visual-editor .st4, #root .st4 {
    opacity: 0.7;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #D6CFC9;
    enable-background: new; }
  .edit-post-visual-editor .st5, #root .st5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
    fill-opacity: 0.298; }
  .edit-post-visual-editor .st6, #root .st6 {
    fill: #B9B6B5; }
  .edit-post-visual-editor .st7, #root .st7 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #B9B6B5; }
  .edit-post-visual-editor .st8, #root .st8 {
    opacity: 0.7;
    fill: #D6CFC9;
    enable-background: new; }
  .edit-post-visual-editor .st9, #root .st9 {
    fill: none; }
  .edit-post-visual-editor .st10, #root .st10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF; }
  .edit-post-visual-editor .st11, #root .st11 {
    fill: #FFFFFF;
    fill-opacity: 0.298; }
  .edit-post-visual-editor .st12, #root .st12 {
    fill: url(#SVGID_2_); }
  .edit-post-visual-editor .st13, #root .st13 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #674534; }
  .edit-post-visual-editor .st14, #root .st14 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #CF785F; }
  .edit-post-visual-editor .st15, #root .st15 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #D3A79F; }
  .edit-post-visual-editor .st16, #root .st16 {
    fill: #D3A79F; }
  .edit-post-visual-editor .st17, #root .st17 {
    opacity: 0.4;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
    enable-background: new; }
  .edit-post-visual-editor .st18, #root .st18 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_3_); }
  .edit-post-visual-editor .st19, #root .st19 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_4_);
    fill-opacity: 0.298; }
  .edit-post-visual-editor .st20, #root .st20 {
    opacity: 0.7;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #978679;
    enable-background: new; }
  .edit-post-visual-editor .st21, #root .st21 {
    opacity: 0.3;
    fill: #FFFFFF;
    enable-background: new; }
  .edit-post-visual-editor .st22, #root .st22 {
    opacity: 0.5; }
  .edit-post-visual-editor .st23, #root .st23 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_5_); }
  .edit-post-visual-editor .st24, #root .st24 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_6_);
    fill-opacity: 0.298; }
  .edit-post-visual-editor .st25, #root .st25 {
    fill: url(#SVGID_7_); }
  .edit-post-visual-editor .st26, #root .st26 {
    fill: #B9684A; }
  .edit-post-visual-editor .st27, #root .st27 {
    fill: #CF785F; }
  .edit-post-visual-editor .st28, #root .st28 {
    opacity: 0.8;
    fill: #5D798F;
    enable-background: new; }
  .edit-post-visual-editor .st29, #root .st29 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_8_); }
  .edit-post-visual-editor .st30, #root .st30 {
    opacity: 0.8;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #5D798F;
    enable-background: new; }
  .edit-post-visual-editor .st31, #root .st31 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #B9684A; }
  .edit-post-visual-editor .st32, #root .st32 {
    opacity: 0.4;
    fill: #FFFFFF;
    fill-opacity: 0.298;
    enable-background: new; }
  .edit-post-visual-editor .st33, #root .st33 {
    opacity: 0.4;
    fill: #FFFFFF;
    enable-background: new; }
  .edit-post-visual-editor .st34, #root .st34 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #CECCCB; }
  .edit-post-visual-editor .st35, #root .st35 {
    opacity: 0.6; }
  .edit-post-visual-editor .st36, #root .st36 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #663333; }
  .edit-post-visual-editor .st37, #root .st37 {
    opacity: 0.8;
    fill: #D3A79F; }
  .edit-post-visual-editor .st38, #root .st38 {
    fill: #AA7D6A; }
  .edit-post-visual-editor .st39, #root .st39 {
    fill: #D35D63; }
  .edit-post-visual-editor .st40, #root .st40 {
    fill: #FF8585; }
  .edit-post-visual-editor .st41, #root .st41 {
    fill: #E6E4E0; }
  .edit-post-visual-editor .st42, #root .st42 {
    fill: #E3DED5; }
  .edit-post-visual-editor .st43, #root .st43 {
    fill: #E8E7E2; }
  .edit-post-visual-editor .st44, #root .st44 {
    fill: #333333; }
  .edit-post-visual-editor .st45, #root .st45 {
    fill: #91734E; }
  .edit-post-visual-editor .st46, #root .st46 {
    fill: #AA996F; }
  .edit-post-visual-editor .st47, #root .st47 {
    fill: #212429; }
  .edit-post-visual-editor .st48, #root .st48 {
    opacity: 0.46;
    fill: #FFFFFF; }
  .edit-post-visual-editor .st49, #root .st49 {
    fill: #FF9191; }
  .edit-post-visual-editor .st50, #root .st50 {
    opacity: 0.31;
    fill: #FFFFFF; }
  .edit-post-visual-editor .st51, #root .st51 {
    fill: #A83767; }
  .edit-post-visual-editor .st52, #root .st52 {
    fill: #DEC752; }
  .edit-post-visual-editor .st53, #root .st53 {
    fill: #C96354; }
  .edit-post-visual-editor .st54, #root .st54 {
    clip-path: url(#SVGID_10_);
    fill: #BA4838; }
  .edit-post-visual-editor .st55, #root .st55 {
    fill: #E88C80; }
  .edit-post-visual-editor .st56, #root .st56 {
    fill: #B84B79; }
  .edit-post-visual-editor .st57, #root .st57 {
    fill: #C75E8A; }
  .edit-post-visual-editor .st58, #root .st58 {
    fill: #DB796C; }
  .edit-post-visual-editor .st59, #root .st59 {
    fill: #E8A874; }
  .edit-post-visual-editor .st60, #root .st60 {
    clip-path: url(#SVGID_12_);
    fill: #F28274; }
  .edit-post-visual-editor .st61, #root .st61 {
    fill: #C26153; }
  .edit-post-visual-editor .st62, #root .st62 {
    fill: #B34636; }
  .edit-post-visual-editor .st63, #root .st63 {
    fill: #D17164; }
  .edit-post-visual-editor .st64, #root .st64 {
    fill: #F2BE94; }
  .edit-post-visual-editor .st65, #root .st65 {
    fill: #FFDCBF; }
  .edit-post-visual-editor .st66, #root .st66 {
    fill: #CE5F5F; }
  .edit-post-visual-editor .st67, #root .st67 {
    clip-path: url(#SVGID_14_);
    fill: #FFA094; }
  .edit-post-visual-editor .st68, #root .st68 {
    fill: #DDB6B1; }
  .edit-post-visual-editor .st69, #root .st69 {
    opacity: 0.1797;
    fill: #D3A79F; }
  .edit-post-visual-editor .viz.dashboard.gallery .ui.grid, #root .viz.dashboard.gallery .ui.grid {
    margin-right: 0; }
  .edit-post-visual-editor .viz.dashboard.gallery .ui.grid > .row > .column, #root .viz.dashboard.gallery .ui.grid > .row > .column {
    padding-right: 0; }
  .edit-post-visual-editor .viz.dashboard.gallery .ui.two.column.grid > .row,
  .edit-post-visual-editor .viz.dashboard.gallery .ui.three.column.grid > .row, #root .viz.dashboard.gallery .ui.two.column.grid > .row,
  #root .viz.dashboard.gallery .ui.three.column.grid > .row {
    padding-bottom: 0.5em;
    padding-top: 0.5em; }
  .edit-post-visual-editor .viz.dashboard.gallery .ui.two.column.grid .wp-block-columns,
  .edit-post-visual-editor .viz.dashboard.gallery .ui.three.column.grid .wp-block-columns, #root .viz.dashboard.gallery .ui.two.column.grid .wp-block-columns,
  #root .viz.dashboard.gallery .ui.three.column.grid .wp-block-columns {
    margin-bottom: 0; }
  .edit-post-visual-editor .viz.dashboard.gallery .ui.two.column.grid .wp-block-column:first-child,
  .edit-post-visual-editor .viz.dashboard.gallery .ui.three.column.grid .wp-block-column:first-child, #root .viz.dashboard.gallery .ui.two.column.grid .wp-block-column:first-child,
  #root .viz.dashboard.gallery .ui.three.column.grid .wp-block-column:first-child {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important; }
  .edit-post-visual-editor .viz.dashboard.gallery.horizontal .grid, .edit-post-visual-editor .viz.dashboard.gallery.horizontal .column, .edit-post-visual-editor .viz.dashboard.gallery.horizontal .row, #root .viz.dashboard.gallery.horizontal .grid, #root .viz.dashboard.gallery.horizontal .column, #root .viz.dashboard.gallery.horizontal .row {
    padding: 0px;
    margin: 0px; }
  .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview, #root .viz.dashboard.gallery.vertical .item .preview {
    border: 2px solid #dadada;
    border-radius: 5px;
    height: 100%;
    padding: 0px; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .wp-block-columns, #root .viz.dashboard.gallery.vertical .item .preview .wp-block-columns {
      bottom: 10px;
      position: absolute;
      z-index: 1; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .wp-block-cover, #root .viz.dashboard.gallery.vertical .item .preview .wp-block-cover {
      margin-bottom: 60px; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .ui.container.entry-content,
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview figure, .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview img, .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .wp-block-image, #root .viz.dashboard.gallery.vertical .item .preview .ui.container.entry-content,
    #root .viz.dashboard.gallery.vertical .item .preview figure, #root .viz.dashboard.gallery.vertical .item .preview img, #root .viz.dashboard.gallery.vertical .item .preview .wp-block-image {
      width: 100% !important;
      margin-bottom: .4rem;
      padding: 0px; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .wp-block-image .aligncenter, #root .viz.dashboard.gallery.vertical .item .preview .wp-block-image .aligncenter {
      display: block; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview p, #root .viz.dashboard.gallery.vertical .item .preview p {
      padding-bottom: .4rem;
      font-weight: 400; }
    .edit-post-visual-editor .viz.dashboard.gallery.vertical .item .preview .wp-block-columns, #root .viz.dashboard.gallery.vertical .item .preview .wp-block-columns {
      padding: 0px 1rem; }
  .edit-post-visual-editor .myths.list, #root .myths.list {
    display: flex;
    flex-direction: column; }
    .edit-post-visual-editor .myths.list .header, #root .myths.list .header {
      padding: 0.5em;
      display: flex;
      flex-direction: row;
      margin-bottom: 2vh; }
      .edit-post-visual-editor .myths.list .header h2, #root .myths.list .header h2 {
        margin: 0; }
      .edit-post-visual-editor .myths.list .header img, #root .myths.list .header img {
        margin-right: 10px;
        width: 40px;
        height: auto; }
    .edit-post-visual-editor .myths.list .title, #root .myths.list .title {
      cursor: text;
      margin-left: 3.2em;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0; }
      .edit-post-visual-editor .myths.list .title .entry-content, #root .myths.list .title .entry-content {
        flex: 1;
        padding: 0;
        margin: 0; }
        .edit-post-visual-editor .myths.list .title .entry-content p, #root .myths.list .title .entry-content p {
          font-size: 1.3em;
          font-weight: 400;
          line-height: 130%;
          margin-left: -65px;
          text-indent: 70px; }
      .edit-post-visual-editor .myths.list .title .ui.label, #root .myths.list .title .ui.label {
        width: 100px;
        border-radius: 0;
        margin: 0;
        font-weight: 400;
        color: #ba5555;
        font-size: 1.3em;
        line-height: 30px;
        padding: 0px 0.2em 0px 0px;
        border: none; }
        .edit-post-visual-editor .myths.list .title .ui.label img, #root .myths.list .title .ui.label img {
          border: 1px solid transparent;
          display: inline-block;
          height: auto !important;
          margin: 0;
          width: 30px !important; }
    .edit-post-visual-editor .myths.list > .content, #root .myths.list > .content {
      display: flex !important;
      margin-bottom: 15px;
      margin-left: 3.2em;
      flex-direction: row;
      border-bottom: 2px solid #ececec !important;
      font-weight: 100;
      color: #5baf95;
      padding-bottom: 20px !important; }
      .edit-post-visual-editor .myths.list > .content .excerpt, #root .myths.list > .content .excerpt {
        display: none; }
      .edit-post-visual-editor .myths.list > .content p, #root .myths.list > .content p {
        font-size: 1.3em;
        font-weight: 400;
        line-height: 130%;
        margin-top: 0;
        margin-left: -70px;
        padding-bottom: 17px;
        padding-top: 0; }
        .edit-post-visual-editor .myths.list > .content p:first-child, #root .myths.list > .content p:first-child {
          margin-left: -70px;
          text-indent: 70px; }
      .edit-post-visual-editor .myths.list > .content .label, #root .myths.list > .content .label {
        justify-content: center;
        color: #5baf95;
        align-items: center;
        font-size: 1.3em;
        font-weight: 400;
        border: 0;
        line-height: 30px;
        padding: 0 0.2em 0 0;
        height: 35px; }
        .edit-post-visual-editor .myths.list > .content .label img, #root .myths.list > .content .label img {
          border: 1px solid transparent;
          display: inline-block;
          height: auto !important;
          margin: 0;
          width: 30px !important; }
      .edit-post-visual-editor .myths.list > .content .entry-content, #root .myths.list > .content .entry-content {
        flex: 1;
        padding: 0;
        margin: 0; }
        .edit-post-visual-editor .myths.list > .content .entry-content > p, #root .myths.list > .content .entry-content > p {
          padding: 0; }
    .edit-post-visual-editor .myths.list > .title.odd, #root .myths.list > .title.odd {
      margin-top: 10px; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px), screen and (max-width: 320px) {
    .edit-post-visual-editor .ui.container.section .menu, .edit-post-visual-editor .ui.container.section .header, #root .ui.container.section .menu, #root .ui.container.section .header {
      border: 0 !important; }
      .edit-post-visual-editor .ui.container.section .menu .item, .edit-post-visual-editor .ui.container.section .header .item, #root .ui.container.section .menu .item, #root .ui.container.section .header .item {
        border: 0 !important;
        display: none; } }
  .edit-post-visual-editor .green .left.navigator, #root .green .left.navigator {
    top: 10px;
    float: left;
    position: sticky !important;
    width: 100px;
    margin-left: -8vw; }
    .edit-post-visual-editor .green .left.navigator .ui.menu, #root .green .left.navigator .ui.menu {
      background-color: #008751;
      margin: 0;
      padding: 0;
      width: 90px;
      min-width: 70px;
      max-width: 100px;
      display: flex; }
      .edit-post-visual-editor .green .left.navigator .ui.menu .header, #root .green .left.navigator .ui.menu .header {
        font-size: 0.8rem;
        align-content: center;
        text-align: center;
        text-transform: uppercase; }
      .edit-post-visual-editor .green .left.navigator .ui.menu .item, #root .green .left.navigator .ui.menu .item {
        border-bottom: 1px solid #FFF;
        padding: 10% 1%;
        box-sizing: border-box;
        text-align: center;
        color: #FFF !important; }
        .edit-post-visual-editor .green .left.navigator .ui.menu .item .label, #root .green .left.navigator .ui.menu .item .label {
          padding: 3%;
          font-size: .6rem;
          margin: auto;
          background: none;
          text-transform: uppercase;
          width: 100%;
          border: 0; }
        .edit-post-visual-editor .green .left.navigator .ui.menu .item img, #root .green .left.navigator .ui.menu .item img {
          height: auto;
          width: 65%;
          cursor: pointer;
          margin: -3px auto 5px auto; }
      .edit-post-visual-editor .green .left.navigator .ui.menu .item.active, #root .green .left.navigator .ui.menu .item.active {
        background-color: #00368C; }
  @media screen and (max-width: 1300px) {
    .edit-post-visual-editor .green .left.navigator .ui.menu, #root .green .left.navigator .ui.menu {
      background-color: #008751;
      margin: 0;
      padding: 0;
      width: 5vw;
      min-width: 65px;
      max-width: 100px;
      display: flex; }
    .edit-post-visual-editor .green .left.navigator .label, #root .green .left.navigator .label {
      display: none; } }
  @media screen and (max-width: 1023px) {
    .edit-post-visual-editor .green .left.navigator, #root .green .left.navigator {
      display: none; }
      .edit-post-visual-editor .green .left.navigator .label, #root .green .left.navigator .label {
        display: none; } }
  @media screen and (max-width: 1023px) {
    .edit-post-visual-editor .green .left.navigator, #root .green .left.navigator {
      display: none; }
      .edit-post-visual-editor .green .left.navigator .label, #root .green .left.navigator .label {
        display: none; } }
  @media print {
    .edit-post-visual-editor .green .left.navigator, #root .green .left.navigator {
      display: none !important; } }
  .edit-post-visual-editor .top-navigator, #root .top-navigator {
    background-color: #008751;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NS4yIDU1LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU1LjIgNTUuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiNGRkZGRkY7fQoJLnN0MXtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjM7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQsNDEuN2g3LjJWMjUuNmw3LjMtMC4xbC0xMC44LTEybC0xMC45LDEybDcuMiwwLjFWNDEuN3oiLz4KPGNpcmNsZSBjbGFzcz0ic3QxIiBjeD0iMjcuNiIgY3k9IjI3LjYiIHI9IjIzLjQiLz4KPC9zdmc+Cg==);
    background-position: center 10px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    bottom: 28%;
    right: 20px;
    position: fixed;
    width: 75px;
    z-index: 10; }
    @media screen and (min-width: 1400px) {
      .edit-post-visual-editor .top-navigator, #root .top-navigator {
        right: 5%; } }
    @media screen and (min-width: 1600px) {
      .edit-post-visual-editor .top-navigator, #root .top-navigator {
        right: 8%; } }
    @media screen and (min-width: 1900px) {
      .edit-post-visual-editor .top-navigator, #root .top-navigator {
        right: 15%; } }
    .edit-post-visual-editor .top-navigator .ui.menu, #root .top-navigator .ui.menu {
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin: 0;
      padding: 0; }
      .edit-post-visual-editor .top-navigator .ui.menu .item, #root .top-navigator .ui.menu .item {
        color: #ffffff;
        font-size: 12px;
        line-height: 130%;
        outline: none;
        padding: 45px 0 15px 0;
        text-align: center;
        text-transform: uppercase;
        vertical-align: baseline;
        width: 100%; }
        .edit-post-visual-editor .top-navigator .ui.menu .item:before, #root .top-navigator .ui.menu .item:before {
          background: none; }
  .edit-post-visual-editor .opacity-0, #root .opacity-0 {
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-in-out;
    backface-visibility: hidden;
    opacity: 0; }
  .edit-post-visual-editor .opacity-100, #root .opacity-100 {
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-in-out;
    backface-visibility: hidden;
    opacity: 1; }
  .edit-post-visual-editor .wp-block-viz-components-featured-tabs, #root .wp-block-viz-components-featured-tabs {
    margin-bottom: 30px; }
  .edit-post-visual-editor .wp-block-viz-components-featured-tabs, #root .wp-block-viz-components-featured-tabs {
    overflow: hidden; }
  .edit-post-visual-editor .featured.tabs, #root .featured.tabs {
    display: flex; }
    .edit-post-visual-editor .featured.tabs .grid.desktop, #root .featured.tabs .grid.desktop {
      flex-basis: 100%;
      padding: 0;
      margin: 0; }
      .edit-post-visual-editor .featured.tabs .grid.desktop .cover, #root .featured.tabs .grid.desktop .cover {
        display: flex;
        flex-direction: column;
        background-size: cover;
        background-repeat: no-repeat !important;
        height: 100%;
        border: 1px solid #b8ccd8;
        border-top: 0;
        margin-top: 0 !important;
        padding: 10%;
        text-align: center;
        justify-content: flex-end; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .cover .entry-content, .edit-post-visual-editor .featured.tabs .grid.desktop .cover .excerpt, #root .featured.tabs .grid.desktop .cover .entry-content, #root .featured.tabs .grid.desktop .cover .excerpt {
          display: flex;
          flex-direction: column;
          height: 100%; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .cover .entry-content blco p, .edit-post-visual-editor .featured.tabs .grid.desktop .cover .excerpt blco p, #root .featured.tabs .grid.desktop .cover .entry-content blco p, #root .featured.tabs .grid.desktop .cover .excerpt blco p {
            margin-bottom: 0; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .cover .label, #root .featured.tabs .grid.desktop .cover .label {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          line-height: 160%;
          margin: auto;
          margin-bottom: 5%;
          width: 150px;
          border: 1px solid #FFF;
          color: #FFF;
          background-color: transparent !important; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .cover .label .icon, #root .featured.tabs .grid.desktop .cover .label .icon {
            margin-right: 4%; }
            .edit-post-visual-editor .featured.tabs .grid.desktop .cover .label .icon:before, #root .featured.tabs .grid.desktop .cover .label .icon:before {
              background: 20px 20px !important;
              background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01MDguNSw0NjguOUwzODcuMSwzNDcuNWMtMi4zLTIuMy01LjMtMy41LTguNS0zLjVoLTEzLjJjMzEuNS0zNi41LDUwLjYtODQsNTAuNi0xMzZDNDE2LDkzLjEsMzIyLjksMCwyMDgsMAoJUzAsOTMuMSwwLDIwOHM5My4xLDIwOCwyMDgsMjA4YzUyLDAsOTkuNS0xOS4xLDEzNi01MC42djEzLjJjMCwzLjIsMS4zLDYuMiwzLjUsOC41bDEyMS40LDEyMS40YzQuNyw0LjcsMTIuMyw0LjcsMTcsMGwyMi42LTIyLjYKCUM1MTMuMiw0ODEuMiw1MTMuMiw0NzMuNiw1MDguNSw0NjguOXogTTIwOCwzNjhjLTg4LjQsMC0xNjAtNzEuNi0xNjAtMTYwUzExOS42LDQ4LDIwOCw0OHMxNjAsNzEuNiwxNjAsMTYwUzI5Ni40LDM2OCwyMDgsMzY4eiIvPgo8L3N2Zz4K") !important;
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: 100% 100% !important;
              display: block;
              height: 20px;
              width: 20px;
              content: ""; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .cover p, #root .featured.tabs .grid.desktop .cover p {
          color: #FFF; }
      .edit-post-visual-editor .featured.tabs .grid.desktop .column, #root .featured.tabs .grid.desktop .column {
        color: #FFF;
        padding: 0 !important;
        margin: 0 !important; }
      .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded, #root .featured.tabs .grid.desktop .column.expanded {
        padding: 0px;
        border: 1px solid #dadada;
        margin: 0 !important; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label, #root .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label {
          color: #52a963;
          position: relative;
          right: auto;
          left: auto;
          top: auto; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label span, #root .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label span {
            display: block;
            margin-top: 30px; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .segment, #root .featured.tabs .grid.desktop .column.expanded .segment {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0px 10px;
          border: 0px;
          border-radius: 0px;
          margin-bottom: 0; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .segment img, #root .featured.tabs .grid.desktop .column.expanded .segment img {
            margin-left: 2%;
            height: 40px !important; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .segment .title, #root .featured.tabs .grid.desktop .column.expanded .segment .title {
            font-size: inherit;
            color: #FFF;
            padding-top: 0; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .segment h2, #root .featured.tabs .grid.desktop .column.expanded .segment h2 {
            font-weight: 300;
            padding: 10px;
            margin: 0px; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .entry-content, #root .featured.tabs .grid.desktop .column.expanded .entry-content {
          color: #000;
          margin: 0px;
          padding: 4%; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .label.closeIconText, #root .featured.tabs .grid.desktop .column.expanded .label.closeIconText {
          cursor: pointer;
          color: #FFF;
          margin: 25px 0 15px 90%; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .label.closeIconText .icon, #root .featured.tabs .grid.desktop .column.expanded .label.closeIconText .icon {
            position: relative !important;
            left: auto !important;
            top: auto !important;
            bottom: auto !important;
            right: auto !important;
            padding: 0px;
            margin-right: 2px; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .label.closeIcon, #root .featured.tabs .grid.desktop .column.expanded .label.closeIcon {
          position: absolute;
          right: 15px;
          cursor: pointer;
          color: #FFF;
          background-color: transparent; }
          .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .label.closeIcon .icon, #root .featured.tabs .grid.desktop .column.expanded .label.closeIcon .icon {
            background-color: transparent;
            position: relative !important;
            left: auto !important;
            top: auto !important;
            bottom: auto !important;
            right: auto !important;
            padding: 0px;
            margin-right: 2px; }
        .edit-post-visual-editor .featured.tabs .grid.desktop .column.expanded .full-link, #root .featured.tabs .grid.desktop .column.expanded .full-link {
          margin-top: 5vmin;
          width: 80%;
          padding: 2vmin;
          border-bottom: 1px solid #dadada;
          border-top: 1px solid #dadada; }
  .edit-post-visual-editor .ui.fluid.container.viz.featured.tabs .entry-content, #root .ui.fluid.container.viz.featured.tabs .entry-content {
    overflow: hidden; }
  .edit-post-visual-editor .ui.fluid.container.viz.featured.tabs.scrollable .entry-content, #root .ui.fluid.container.viz.featured.tabs.scrollable .entry-content {
    overflow-y: auto; }
  .edit-post-visual-editor .vertical.featured.tabs .item, #root .vertical.featured.tabs .item {
    overflow: hidden;
    flex-grow: 1; }
  .edit-post-visual-editor .vertical.featured.tabs .item.expanded, #root .vertical.featured.tabs .item.expanded {
    flex-grow: 1;
    transition: all 0.5s;
    backface-visibility: hidden; }
  .edit-post-visual-editor .vertical.featured.tabs .item.collapsed, #root .vertical.featured.tabs .item.collapsed {
    flex-grow: 0;
    transition: all 0.5s;
    backface-visibility: hidden; }
  .edit-post-visual-editor .vertical.featured.tabs .item.collapsed:hover, #root .vertical.featured.tabs .item.collapsed:hover {
    transform: translateX(-7px); }
    .edit-post-visual-editor .vertical.featured.tabs .item.collapsed:hover .cover, #root .vertical.featured.tabs .item.collapsed:hover .cover {
      transition: filter 0.25s ease-in-out;
      filter: brightness(1.18); }
  .edit-post-visual-editor .vertical.featured.tabs .content-area, #root .vertical.featured.tabs .content-area {
    height: 100%;
    display: block;
    position: relative; }
    .edit-post-visual-editor .vertical.featured.tabs .content-area .cover, #root .vertical.featured.tabs .content-area .cover {
      cursor: pointer;
      padding: 0px;
      left: 0px;
      float: left;
      position: absolute;
      height: 100%;
      display: grid;
      transition: filter 0.25s ease-in-out; }
      .edit-post-visual-editor .vertical.featured.tabs .content-area .cover .rotator, #root .vertical.featured.tabs .content-area .cover .rotator {
        position: absolute;
        transform-origin: left; }
        .edit-post-visual-editor .vertical.featured.tabs .content-area .cover .rotator *, #root .vertical.featured.tabs .content-area .cover .rotator * {
          margin: 0px;
          padding: 0px; }
        .edit-post-visual-editor .vertical.featured.tabs .content-area .cover .rotator img, #root .vertical.featured.tabs .content-area .cover .rotator img {
          transform: rotate(-90deg); }
        .edit-post-visual-editor .vertical.featured.tabs .content-area .cover .rotator p, #root .vertical.featured.tabs .content-area .cover .rotator p {
          transform: rotate(180deg);
          text-align: right; }
    .edit-post-visual-editor .vertical.featured.tabs .content-area .collapsable-content, #root .vertical.featured.tabs .content-area .collapsable-content {
      height: 100%;
      position: absolute;
      display: block;
      margin-right: 0px; }
      .edit-post-visual-editor .vertical.featured.tabs .content-area .collapsable-content .entry-content, #root .vertical.featured.tabs .content-area .collapsable-content .entry-content {
        cursor: default !important;
        height: 100%;
        margin: auto;
        padding: 0px; }
        .edit-post-visual-editor .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content, #root .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content {
          height: 100%;
          overflow-y: auto; }
          .edit-post-visual-editor .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content::-webkit-scrollbar-thumb, #root .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            background-color: #66676d; }
          .edit-post-visual-editor .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content::-webkit-scrollbar-track, #root .vertical.featured.tabs .content-area .collapsable-content .entry-content .ui.fluid.container.content::-webkit-scrollbar-track {
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            background-color: #dadada; }
  .edit-post-visual-editor .vertical.featured.tabs .overlay-label-container, #root .vertical.featured.tabs .overlay-label-container {
    position: absolute;
    transform: rotate(-90deg);
    bottom: 65px;
    justify-self: center;
    width: 100px;
    text-align: center; }
  .edit-post-visual-editor .vertical.featured.tabs .overlay-label, #root .vertical.featured.tabs .overlay-label {
    color: white;
    font-size: 11px;
    font-weight: 300;
    line-height: 1;
    /* Keep line height as 1 */
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    text-align: center;
    transform: translate(-16px, 2px); }
  .edit-post-visual-editor .vertical.featured.tabs .overlay-label.visible, #root .vertical.featured.tabs .overlay-label.visible {
    opacity: 1; }
  .edit-post-visual-editor .vertical.featured.tabs .arrow-svg, #root .vertical.featured.tabs .arrow-svg {
    height: 20px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjE3MjcgMTEuMTM2QzE5LjMyIDEwLjk4NzMgMTkuNDM2OSAxMC44MTAzIDE5LjUxNjcgMTAuNjE1M0MxOS41OTY1IDEwLjQyMDQgMTkuNjM3NiAxMC4yMTEyIDE5LjYzNzYgMTBDMTkuNjM3NiA5Ljc4ODc4IDE5LjU5NjUgOS41Nzk2NSAxOS41MTY3IDkuMzg0NjhDMTkuNDM2OSA5LjE4OTcgMTkuMzIgOS4wMTI3NCAxOS4xNzI3IDguODY0TDExLjMxNTYgMC44NjQwMDFDMTEuMTY5NSAwLjcxNDAzNiAxMC45OTU3IDAuNTk1MDA1IDEwLjgwNDIgMC41MTM3NzVDMTAuNjEyNyAwLjQzMjU0NSAxMC40MDczIDAuMzkwNzI0IDEwLjE5OTkgMC4zOTA3MjRDOS45OTI0NCAwLjM5MDcyNCA5Ljc4NzA1IDAuNDMyNTQ1IDkuNTk1NTYgMC41MTM3NzVDOS40MDQwNiAwLjU5NTAwNSA5LjIzMDI2IDAuNzE0MDM2IDkuMDg0MTggMC44NjQwMDFMMS4yMjcwNCA4Ljg2NEMxLjA3OTc1IDkuMDEyNzQgMC45NjI4MzkgOS4xODk3IDAuODgzMDYgOS4zODQ2OEMwLjgwMzI4MSA5LjU3OTY1IDAuNzYyMjA3IDkuNzg4NzggMC43NjIyMDcgMTBDMC43NjIyMDcgMTAuMjExMiAwLjgwMzI4MSAxMC40MjAzIDAuODgzMDYgMTAuNjE1M0MwLjk2MjgzOSAxMC44MTAzIDEuMDc5NzUgMTAuOTg3MyAxLjIyNzA0IDExLjEzNkMxLjM3MzEyIDExLjI4NiAxLjU0NjkyIDExLjQwNSAxLjczODQxIDExLjQ4NjJDMS45Mjk5MSAxMS41Njc1IDIuMTM1MyAxMS42MDkzIDIuMzQyNzUgMTEuNjA5M0MyLjU1MDE5IDExLjYwOTMgMi43NTU1OSAxMS41Njc1IDIuOTQ3MDggMTEuNDg2MkMzLjEzODU4IDExLjQwNSAzLjMxMjM3IDExLjI4NiAzLjQ1ODQ2IDExLjEzNkwxMC4xOTk5IDQuMjU2TDE2Ljk0MTMgMTEuMTM2QzE3LjA4NzQgMTEuMjg2IDE3LjI2MTIgMTEuNDA1IDE3LjQ1MjcgMTEuNDg2MkMxNy42NDQyIDExLjU2NzUgMTcuODQ5NiAxMS42MDkzIDE4LjA1NyAxMS42MDkzQzE4LjI2NDUgMTEuNjA5MyAxOC40Njk5IDExLjU2NzUgMTguNjYxNCAxMS40ODYyQzE4Ljg1MjkgMTEuNDA1IDE5LjAyNjcgMTEuMjg2IDE5LjE3MjcgMTEuMTM2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==") no-repeat;
    transform: translate(25px, -3px);
    transition: transform 0.25s ease-in-out; }
  .edit-post-visual-editor .vertical.featured.tabs .overlay-label.visible + .arrow-svg, #root .vertical.featured.tabs .overlay-label.visible + .arrow-svg {
    transform: translate(25px, 6px);
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out; }
  .edit-post-visual-editor .vertical.featured.tabs .item.expanded .arrow-svg, #root .vertical.featured.tabs .item.expanded .arrow-svg {
    opacity: 0;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out; }
  .edit-post-visual-editor .vertical.featured.tabs .item.collapsed .arrow-svg, #root .vertical.featured.tabs .item.collapsed .arrow-svg {
    opacity: 1;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out; }
  .edit-post-visual-editor .inline.list, #root .inline.list {
    padding: 0px; }
    .edit-post-visual-editor .inline.list .list, #root .inline.list .list {
      padding-bottom: 0; }
    .edit-post-visual-editor .inline.list .ui.grid, #root .inline.list .ui.grid {
      border-bottom: 1px solid #dadada;
      padding: 0px 0px 30px 0px;
      margin: 0px 0px 30px 0px; }
      .edit-post-visual-editor .inline.list .ui.grid:last-of-type, #root .inline.list .ui.grid:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0; }
      .edit-post-visual-editor .inline.list .ui.grid .column, #root .inline.list .ui.grid .column {
        padding: 0px; }
      .edit-post-visual-editor .inline.list .ui.grid .fifteen.wide.column, #root .inline.list .ui.grid .fifteen.wide.column {
        padding-left: 20px; }
      .edit-post-visual-editor .inline.list .ui.grid .ui.container.entry-content, #root .inline.list .ui.grid .ui.container.entry-content {
        padding: 0; }
      .edit-post-visual-editor .inline.list .ui.grid .ui.label, #root .inline.list .ui.grid .ui.label {
        height: auto;
        background-color: transparent;
        text-align: center;
        padding: 0px;
        margin: 0px; }
        .edit-post-visual-editor .inline.list .ui.grid .ui.label img, #root .inline.list .ui.grid .ui.label img {
          height: auto !important;
          width: 100% !important;
          min-width: 30px;
          margin: 0px; }
    .edit-post-visual-editor .inline.list a, #root .inline.list a {
      cursor: pointer; }
    .edit-post-visual-editor .inline.list .link:first-letter, #root .inline.list .link:first-letter {
      text-transform: capitalize !important; }
    .edit-post-visual-editor .inline.list .link, #root .inline.list .link {
      position: relative;
      padding-top: 5px;
      font-weight: 700;
      font-size: 16px;
      color: #008751;
      display: block;
      /*&:hover{
      color: $ke-blue;
    }*/ }
    .edit-post-visual-editor .inline.list .viz.download .ui.grid, #root .inline.list .viz.download .ui.grid {
      border-bottom: none; }
  .edit-post-visual-editor .chart.container, #root .chart.container {
    margin: 0px;
    padding: 0px; }
    .edit-post-visual-editor .chart.container .ui.segment.nodata, #root .chart.container .ui.segment.nodata {
      margin-top: 100px;
      margin-left: -5px;
      vertical-align: center;
      font-size: 20px;
      text-align: center; }
    .edit-post-visual-editor .chart.container .body, #root .chart.container .body {
      position: relative;
      height: 100%;
      padding: 5px;
      margin: 0px; }
      .edit-post-visual-editor .chart.container .body .ui.container.entry-content, #root .chart.container .body .ui.container.entry-content {
        padding: 0px !important;
        margin: 0px !important; }
      .edit-post-visual-editor .chart.container .body svg, #root .chart.container .body svg {
        pointer-events: all;
        overflow: visible; }
        .edit-post-visual-editor .chart.container .body svg text, #root .chart.container .body svg text {
          font-size: 13px !important; }
    .edit-post-visual-editor .chart.container .legend.item input[type=checkbox], #root .chart.container .legend.item input[type=checkbox] {
      width: 18px;
      height: 18px; }
    .edit-post-visual-editor .chart.container .legend.item input[type=checkbox]:before, .edit-post-visual-editor .chart.container .legend.item input[type=radio], #root .chart.container .legend.item input[type=checkbox]:before, #root .chart.container .legend.item input[type=radio] {
      width: 16px;
      margin: auto !important;
      font-size: 16px !important; }
    .edit-post-visual-editor .chart.container .legend.item label, #root .chart.container .legend.item label {
      padding: 3px;
      font-size: 14px !important; }
    .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark-with-bg, .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark-with-bg, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark-with-bg, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark-with-bg, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark, #root .chart.container .legends.container.top .legend.item .checkmark-with-bg, #root .chart.container .legends.container.top .legend.item .checkmark, #root .chart.container .legends.container.left .legend.item .checkmark-with-bg, #root .chart.container .legends.container.left .legend.item .checkmark, #root .chart.container .legends.container.right .legend.item .checkmark-with-bg, #root .chart.container .legends.container.right .legend.item .checkmark, #root .chart.container .legends.container.bottom .legend.item .checkmark-with-bg, #root .chart.container .legends.container.bottom .legend.item .checkmark {
      height: 18px;
      width: 18px;
      margin-right: 4px;
      border: 1px solid #8c8f94;
      border-radius: 4px; }
    .edit-post-visual-editor .chart.container .legends.container.top .legend.item input:checked ~ .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.top .legend.item input:checked ~ .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item input:checked ~ .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item input:checked ~ .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item input:checked ~ .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item input:checked ~ .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item input:checked ~ .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item input:checked ~ .checkmark-with-bg:after, #root .chart.container .legends.container.top .legend.item input:checked ~ .checkmark:after, #root .chart.container .legends.container.top .legend.item input:checked ~ .checkmark-with-bg:after, #root .chart.container .legends.container.left .legend.item input:checked ~ .checkmark:after, #root .chart.container .legends.container.left .legend.item input:checked ~ .checkmark-with-bg:after, #root .chart.container .legends.container.right .legend.item input:checked ~ .checkmark:after, #root .chart.container .legends.container.right .legend.item input:checked ~ .checkmark-with-bg:after, #root .chart.container .legends.container.bottom .legend.item input:checked ~ .checkmark:after, #root .chart.container .legends.container.bottom .legend.item input:checked ~ .checkmark-with-bg:after {
      display: block; }
    .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.top .legend.item .checkmark:after, #root .chart.container .legends.container.top .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.left .legend.item .checkmark:after, #root .chart.container .legends.container.left .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.right .legend.item .checkmark:after, #root .chart.container .legends.container.right .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.bottom .legend.item .checkmark:after, #root .chart.container .legends.container.bottom .legend.item .checkmark-with-bg:after {
      content: "";
      display: none; }
    .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark:after, #root .chart.container .legends.container.top .legend.item .checkmark:after, #root .chart.container .legends.container.left .legend.item .checkmark:after, #root .chart.container .legends.container.right .legend.item .checkmark:after, #root .chart.container .legends.container.bottom .legend.item .checkmark:after {
      position: relative;
      left: 5px;
      top: 2px;
      width: 7px;
      height: 10px;
      border: solid #2196F3;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .edit-post-visual-editor .chart.container .legends.container.top .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.left .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.right .legend.item .checkmark-with-bg:after, .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.top .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.left .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.right .legend.item .checkmark-with-bg:after, #root .chart.container .legends.container.bottom .legend.item .checkmark-with-bg:after {
      position: relative;
      left: 5px;
      top: 2px;
      width: 7px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .edit-post-visual-editor .chart.container .legends.container.top, #root .chart.container .legends.container.top {
      position: absolute;
      top: 5px;
      left: 10px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
      .edit-post-visual-editor .chart.container .legends.container.top .legend-sections, #root .chart.container .legends.container.top .legend-sections {
        display: flex; }
        .edit-post-visual-editor .chart.container .legends.container.top .legend-sections .title-section, #root .chart.container .legends.container.top .legend-sections .title-section {
          display: flex;
          position: relative; }
        .edit-post-visual-editor .chart.container .legends.container.top .legend-sections .items-section, #root .chart.container .legends.container.top .legend-sections .items-section {
          display: flex;
          position: relative !important;
          flex-wrap: wrap !important; }
      .edit-post-visual-editor .chart.container .legends.container.top .legend.item, #root .chart.container .legends.container.top .legend.item {
        margin: 2px;
        padding: 3px;
        text-align: left;
        display: flex;
        line-height: 28px !important; }
        .edit-post-visual-editor .chart.container .legends.container.top .legend.item input[type=checkbox], .edit-post-visual-editor .chart.container .legends.container.top .legend.item input[type=radio], #root .chart.container .legends.container.top .legend.item input[type=checkbox], #root .chart.container .legends.container.top .legend.item input[type=radio] {
          display: none; }
        .edit-post-visual-editor .chart.container .legends.container.top .legend.item label, #root .chart.container .legends.container.top .legend.item label {
          padding: 0px 3px;
          line-height: 20px;
          height: 20px;
          white-space: nowrap; }
      .edit-post-visual-editor .chart.container .legends.container.top .legend.item.overlay, #root .chart.container .legends.container.top .legend.item.overlay {
        margin-left: 20px; }
    .edit-post-visual-editor .chart.container .legends.container.left, #root .chart.container .legends.container.left {
      position: absolute;
      bottom: 50px;
      left: 10px; }
      .edit-post-visual-editor .chart.container .legends.container.left .legend.item, #root .chart.container .legends.container.left .legend.item {
        margin: 2px;
        padding: 3px;
        text-align: left;
        display: flex;
        line-height: 28px !important; }
        .edit-post-visual-editor .chart.container .legends.container.left .legend.item input[type=checkbox], .edit-post-visual-editor .chart.container .legends.container.left .legend.item input[type=radio], #root .chart.container .legends.container.left .legend.item input[type=checkbox], #root .chart.container .legends.container.left .legend.item input[type=radio] {
          display: none; }
        .edit-post-visual-editor .chart.container .legends.container.left .legend.item label, #root .chart.container .legends.container.left .legend.item label {
          padding: 0px 5px;
          line-height: 20px;
          height: 20px; }
      .edit-post-visual-editor .chart.container .legends.container.left .legend.item.overlay, #root .chart.container .legends.container.left .legend.item.overlay {
        margin-top: 20px; }
    .edit-post-visual-editor .chart.container .legends.container.left .legend.item .legend-title, #root .chart.container .legends.container.left .legend.item .legend-title {
      padding-left: 0px; }
    .edit-post-visual-editor .chart.container .legends.container.right, #root .chart.container .legends.container.right {
      position: absolute;
      bottom: 50px;
      right: 10px; }
      .edit-post-visual-editor .chart.container .legends.container.right .legend.item, #root .chart.container .legends.container.right .legend.item {
        margin: 2px;
        padding: 3px;
        text-align: left;
        display: flex;
        line-height: 28px !important; }
        .edit-post-visual-editor .chart.container .legends.container.right .legend.item input[type=checkbox], .edit-post-visual-editor .chart.container .legends.container.right .legend.item input[type=radio], #root .chart.container .legends.container.right .legend.item input[type=checkbox], #root .chart.container .legends.container.right .legend.item input[type=radio] {
          display: none; }
        .edit-post-visual-editor .chart.container .legends.container.right .legend.item label, #root .chart.container .legends.container.right .legend.item label {
          padding: 0px 5px;
          line-height: 20px;
          height: 20px; }
        .edit-post-visual-editor .chart.container .legends.container.right .legend.item .legend-title, #root .chart.container .legends.container.right .legend.item .legend-title {
          padding-left: 0px; }
      .edit-post-visual-editor .chart.container .legends.container.right .legend.item.overlay, #root .chart.container .legends.container.right .legend.item.overlay {
        margin-top: 20px; }
    .edit-post-visual-editor .chart.container .legend-title, #root .chart.container .legend-title {
      color: #66676d;
      cursor: default;
      font-weight: bold;
      font-size: 14px; }
    .edit-post-visual-editor .chart.container .legends.container.bottom, #root .chart.container .legends.container.bottom {
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
      .edit-post-visual-editor .chart.container .legends.container.bottom .legend-sections, #root .chart.container .legends.container.bottom .legend-sections {
        display: flex; }
        .edit-post-visual-editor .chart.container .legends.container.bottom .legend-sections .title-section, #root .chart.container .legends.container.bottom .legend-sections .title-section {
          display: flex;
          position: relative; }
        .edit-post-visual-editor .chart.container .legends.container.bottom .legend-sections .items-section, #root .chart.container .legends.container.bottom .legend-sections .items-section {
          display: flex;
          position: relative !important;
          flex-wrap: wrap !important; }
      .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item, #root .chart.container .legends.container.bottom .legend.item {
        margin: 2px;
        padding: 3px;
        text-align: left;
        display: flex;
        line-height: 28px !important; }
        .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item input[type=checkbox], .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item input[type=radio], #root .chart.container .legends.container.bottom .legend.item input[type=checkbox], #root .chart.container .legends.container.bottom .legend.item input[type=radio] {
          display: none; }
        .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item label, #root .chart.container .legends.container.bottom .legend.item label {
          padding: 0px 5px;
          line-height: 20px;
          height: 20px; }
      .edit-post-visual-editor .chart.container .legends.container.bottom .legend.item.overlay, #root .chart.container .legends.container.bottom .legend.item.overlay {
        margin-left: 20px; }
    .edit-post-visual-editor .chart.container .footnote, #root .chart.container .footnote {
      margin-top: 20px;
      width: auto;
      text-align: left; }
      .edit-post-visual-editor .chart.container .footnote .grid, #root .chart.container .footnote .grid {
        width: 100%; }
      .edit-post-visual-editor .chart.container .footnote .column, #root .chart.container .footnote .column {
        height: 40px;
        vertical-align: bottom;
        padding: 0px;
        margin: 0px; }
      .edit-post-visual-editor .chart.container .footnote p, #root .chart.container .footnote p {
        line-height: 40px;
        font-size: 13px;
        padding: 0px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        text-align: left; }
    .edit-post-visual-editor .chart.container .buttons, #root .chart.container .buttons {
      display: flex; }
      .edit-post-visual-editor .chart.container .buttons .button, #root .chart.container .buttons .button {
        font-size: 14px;
        background-color: #D6D6D6 !important;
        font-weight: 100;
        border: 1px solid;
        min-width: 150px;
        color: white;
        font-weight: 600; }
      .edit-post-visual-editor .chart.container .buttons .button.active, #root .chart.container .buttons .button.active {
        background: #4F8BA5 !important; }
  .edit-post-visual-editor .chart.tooltip, #root .chart.tooltip {
    padding: 15px;
    border-radius: 4px;
    opacity: 1;
    font-family: Roboto;
    line-height: 28px;
    font-size: 16px;
    color: #1e1e1e;
    background-color: #f7f7f7 !important;
    border: 1px solid #dddddd !important;
    line-height: 1.5; }
    .edit-post-visual-editor .chart.tooltip hr, #root .chart.tooltip hr {
      margin-left: -15px;
      margin-right: -15px; }
    .edit-post-visual-editor .chart.tooltip b, #root .chart.tooltip b {
      color: #1e1e1e !important; }
  .edit-post-visual-editor .chartView, #root .chartView {
    margin-bottom: 20px; }
  .edit-post-visual-editor .carousel, #root .carousel {
    width: 100%;
    padding: 0px; }
    .edit-post-visual-editor .carousel .carousel__slide, #root .carousel .carousel__slide {
      border: 1px solid transparent; }
    .edit-post-visual-editor .carousel .wp-block-cover, #root .carousel .wp-block-cover {
      padding: 0px; }
    .edit-post-visual-editor .carousel .wp-block-group, #root .carousel .wp-block-group {
      padding: 1rem 2rem; }
    .edit-post-visual-editor .carousel .wp-block-columns .wp-block-column, #root .carousel .wp-block-columns .wp-block-column {
      padding: 0px;
      margin: 0px; }
    .edit-post-visual-editor .carousel ul, #root .carousel ul {
      margin: 0; }
    .edit-post-visual-editor .carousel li, #root .carousel li {
      padding: 0px;
      min-height: 565px; }
      .edit-post-visual-editor .carousel li .wp-block-buttons, #root .carousel li .wp-block-buttons {
        padding: 10px 0px; }
        .edit-post-visual-editor .carousel li .wp-block-buttons .wp-block-button, #root .carousel li .wp-block-buttons .wp-block-button {
          margin: 0px; }
  .edit-post-visual-editor .carousel__dot-group, #root .carousel__dot-group {
    margin: 0px auto auto;
    text-align: center; }
    .edit-post-visual-editor .carousel__dot-group .carousel__dot, #root .carousel__dot-group .carousel__dot {
      pointer-events: all !important;
      background-color: #ececec;
      margin: 5px;
      border-radius: 100%;
      height: 15px;
      width: 15px;
      border: 0px !important; }
    .edit-post-visual-editor .carousel__dot-group .carousel__dot:hover, #root .carousel__dot-group .carousel__dot:hover {
      background-color: #008751; }
    .edit-post-visual-editor .carousel__dot-group .carousel__dot:focus, #root .carousel__dot-group .carousel__dot:focus {
      outline: none;
      box-shadow: none; }
    .edit-post-visual-editor .carousel__dot-group .carousel__dot:active, #root .carousel__dot-group .carousel__dot:active {
      background-color: #fff6e1;
      box-shadow: none;
      transform: translateY(0px); }
    .edit-post-visual-editor .carousel__dot-group .carousel__dot--selected, #root .carousel__dot-group .carousel__dot--selected {
      background-color: #ffb400; }
  .edit-post-visual-editor .container.desktop h2 .title, #root .container.desktop h2 .title {
    padding-top: 50px; }
  .edit-post-visual-editor .container.green .excerpt .entry-content, #root .container.green .excerpt .entry-content {
    margin: 0; }
  .edit-post-visual-editor .container.section, #root .container.section {
    page-break-after: always;
    margin-left: 10px !important;
    margin-bottom: 50px; }
    .edit-post-visual-editor .container.section .ui.menu.header, #root .container.section .ui.menu.header {
      margin: 0;
      background-color: #FFF;
      border: 1px solid #dadada;
      background: #00368C; }
      .edit-post-visual-editor .container.section .ui.menu.header h1, #root .container.section .ui.menu.header h1 {
        color: #00368C;
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 300 !important;
        padding: 0;
        margin: 0; }
      .edit-post-visual-editor .container.section .ui.menu.header h2, #root .container.section .ui.menu.header h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem !important;
        margin: 0;
        padding: 0;
        font-weight: 300 !important; }
      .edit-post-visual-editor .container.section .ui.menu.header .item, #root .container.section .ui.menu.header .item {
        padding: 1.5rem;
        background: #00368C;
        width: 20vw;
        max-height: 160px !important;
        max-width: 191px !important; }
      .edit-post-visual-editor .container.section .ui.menu.header .ui.image, #root .container.section .ui.menu.header .ui.image {
        margin: auto;
        width: 60%; }
      .edit-post-visual-editor .container.section .ui.menu.header .header, #root .container.section .ui.menu.header .header {
        padding: 2%;
        background: #FFF;
        width: 100%;
        font-weight: 300;
        justify-content: left;
        align-items: center;
        display: flex;
        border-left: 1px solid #dadada; }
        .edit-post-visual-editor .container.section .ui.menu.header .header > div, #root .container.section .ui.menu.header .header > div {
          margin-left: 1.5vw; }
        .edit-post-visual-editor .container.section .ui.menu.header .header p, #root .container.section .ui.menu.header .header p {
          line-height: 100%;
          padding: 0;
          margin: 0; }
    @media (max-width: 600px) {
      .edit-post-visual-editor .container.section .ui.menu .item, #root .container.section .ui.menu .item {
        display: none; } }
    .edit-post-visual-editor .container.section .subsection, #root .container.section .subsection {
      margin: 0 !important;
      padding: 1vw; }
      .edit-post-visual-editor .container.section .subsection a, #root .container.section .subsection a {
        color: #3e9fde !important; }
      .edit-post-visual-editor .container.section .subsection .grid.header, #root .container.section .subsection .grid.header {
        padding: 0.5vw;
        margin: 0; }
        .edit-post-visual-editor .container.section .subsection .grid.header h2.title, #root .container.section .subsection .grid.header h2.title {
          text-transform: none;
          padding-left: 0;
          color: #5baf95;
          font-weight: 300;
          margin: 0; }
        .edit-post-visual-editor .container.section .subsection .grid.header h3.subtitle, #root .container.section .subsection .grid.header h3.subtitle {
          text-transform: none;
          font-size: 1.5rem;
          margin: 0;
          color: #66676d;
          font-weight: 300;
          padding-left: 0; }
        .edit-post-visual-editor .container.section .subsection .grid.header .column,
        .edit-post-visual-editor .container.section .subsection .grid.header .row, #root .container.section .subsection .grid.header .column,
        #root .container.section .subsection .grid.header .row {
          padding: 0;
          margin: 0; }
        .edit-post-visual-editor .container.section .subsection .grid.header .column, #root .container.section .subsection .grid.header .column {
          min-height: 90px;
          border-bottom: 1px solid #dadada; }
        .edit-post-visual-editor .container.section .subsection .grid.header .icon, #root .container.section .subsection .grid.header .icon {
          width: 40px;
          height: 40px;
          min-width: 30px !important;
          min-height: 30px !important;
          background: url("../public/old/sub_section_icon.svg") no-repeat center;
          content: '';
          margin-top: 15px; }
        @media (max-width: 779px) {
          .edit-post-visual-editor .container.section .subsection .grid.header .column:nth-child(1), #root .container.section .subsection .grid.header .column:nth-child(1) {
            display: none; }
          .edit-post-visual-editor .container.section .subsection .grid.header h2.title, #root .container.section .subsection .grid.header h2.title {
            font-size: 1.5em; }
          .edit-post-visual-editor .container.section .subsection .grid.header h3.subtitle, #root .container.section .subsection .grid.header h3.subtitle {
            font-size: 1.3em; } }
  .edit-post-visual-editor .intro, #root .intro {
    margin: 50px auto auto;
    padding: 0; }
    .edit-post-visual-editor .intro .entry-content, #root .intro .entry-content {
      padding: 0 !important;
      margin: 0; }
      .edit-post-visual-editor .intro .entry-content .has-intermediate-font-size, #root .intro .entry-content .has-intermediate-font-size {
        font-size: 1.8rem;
        line-height: 135%;
        padding-bottom: 35px;
        padding-top: 25px; }
    @media screen and (max-width: 781px) and (min-width: 600px) {
      .edit-post-visual-editor .intro .wp-block-columns, #root .intro .wp-block-columns {
        margin-bottom: 10vh !important; }
      .edit-post-visual-editor .intro .wp-block-column:nth-child(1), #root .intro .wp-block-column:nth-child(1) {
        flex-basis: calc(20% - 5px) !important;
        flex-grow: 0; }
      .edit-post-visual-editor .intro .wp-block-column:nth-child(2), #root .intro .wp-block-column:nth-child(2) {
        flex-basis: calc(80% - 16px) !important;
        flex-grow: 0; } }
    @media (max-width: 599px) {
      .edit-post-visual-editor .intro .wp-block-column:nth-child(2), #root .intro .wp-block-column:nth-child(2) {
        text-align: center;
        flex-grow: 0; } }
    .edit-post-visual-editor .intro .wp-block-columns, #root .intro .wp-block-columns {
      margin: 0; }
      .edit-post-visual-editor .intro .wp-block-columns .wp-block-column, #root .intro .wp-block-columns .wp-block-column {
        margin: 0;
        padding: 0.3vw; }
        .edit-post-visual-editor .intro .wp-block-columns .wp-block-column p, #root .intro .wp-block-columns .wp-block-column p {
          margin: 0.3em; }
        .edit-post-visual-editor .intro .wp-block-columns .wp-block-column figure, #root .intro .wp-block-columns .wp-block-column figure {
          margin-block-end: 0;
          margin-block-start: 0.3em; }
  .edit-post-visual-editor .cost .cost-gender-label, #root .cost .cost-gender-label {
    line-height: 90%; }
  .edit-post-visual-editor .wp-block-viz-components-newsletter, #root .wp-block-viz-components-newsletter {
    padding: 10px;
    width: 100%; }
  .edit-post-visual-editor .viz.newsLetter, #root .viz.newsLetter {
    text-align: center;
    max-width: 500px;
    height: 60px;
    margin: 1rem auto; }
    .edit-post-visual-editor .viz.newsLetter .ui.negative.message, #root .viz.newsLetter .ui.negative.message {
      bottom: 10px;
      right: 10px;
      padding: 15px; }
      .edit-post-visual-editor .viz.newsLetter .ui.negative.message p, #root .viz.newsLetter .ui.negative.message p {
        color: #ffffff;
        line-height: 120%;
        opacity: 1; }
    .edit-post-visual-editor .viz.newsLetter .ui.success.message, #root .viz.newsLetter .ui.success.message {
      background-color: #63c576;
      color: #FFFFFF;
      bottom: 10px;
      right: 10px;
      padding: 15px; }
      .edit-post-visual-editor .viz.newsLetter .ui.success.message p, #root .viz.newsLetter .ui.success.message p {
        color: #ffffff;
        line-height: 120%;
        opacity: 1; }
    .edit-post-visual-editor .viz.newsLetter .ui.primary.button, #root .viz.newsLetter .ui.primary.button {
      background-color: #008751 !important; }
      .edit-post-visual-editor .viz.newsLetter .ui.primary.button:hover, #root .viz.newsLetter .ui.primary.button:hover {
        background-color: #006b40 !important; }
    .edit-post-visual-editor .viz.newsLetter .ui.message, #root .viz.newsLetter .ui.message {
      margin: 0px; }
      .edit-post-visual-editor .viz.newsLetter .ui.message p, #root .viz.newsLetter .ui.message p {
        padding: 0px;
        margin: 0px;
        line-height: 5px; }
    .edit-post-visual-editor .viz.newsLetter .viz.newsLetter.form, #root .viz.newsLetter .viz.newsLetter.form {
      padding: 0px;
      height: 42px;
      display: flex;
      justify-content: left;
      margin-bottom: 3px; }
      .edit-post-visual-editor .viz.newsLetter .viz.newsLetter.form .input, #root .viz.newsLetter .viz.newsLetter.form .input {
        flex-grow: 1;
        height: 40px; }
        .edit-post-visual-editor .viz.newsLetter .viz.newsLetter.form .input > input, #root .viz.newsLetter .viz.newsLetter.form .input > input {
          border-top-left-radius: 4px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 4px;
          -webkit-border-top-left-radius: 4px;
          -webkit-border-top-right-radius: 0;
          -webkit-border-bottom-right-radius: 0;
          -webkit-border-bottom-left-radius: 4px;
          -moz-border-radius-topleft: 4px;
          -moz-border-radius-topright: 0;
          -moz-border-radius-bottomright: 0;
          -moz-border-radius-bottomleft: 4px; }
      .edit-post-visual-editor .viz.newsLetter .viz.newsLetter.form .ui.button, #root .viz.newsLetter .viz.newsLetter.form .ui.button {
        border-top-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 4px;
        -moz-border-radius-bottomleft: 0;
        height: 40px;
        line-height: 70%; }
  .edit-post-visual-editor .viz.showcase, #root .viz.showcase {
    width: 100% !important; }
    .edit-post-visual-editor .viz.showcase .column, #root .viz.showcase .column {
      padding-bottom: 0; }
    .edit-post-visual-editor .viz.showcase .ui.secondary.button, #root .viz.showcase .ui.secondary.button {
      background-color: #008751  !important; }
      .edit-post-visual-editor .viz.showcase .ui.secondary.button:hover, #root .viz.showcase .ui.secondary.button:hover {
        background-color: #006b40 !important; }
    .edit-post-visual-editor .viz.showcase .input, .edit-post-visual-editor .viz.showcase textarea, .edit-post-visual-editor .viz.showcase .listbox, .edit-post-visual-editor .viz.showcase .dropdown, #root .viz.showcase .input, #root .viz.showcase textarea, #root .viz.showcase .listbox, #root .viz.showcase .dropdown {
      width: 100%;
      font-size: .9rem;
      min-height: 20px;
      height: 40px; }
    .edit-post-visual-editor .viz.showcase .ui.dropdown > .dropdown.icon:before, #root .viz.showcase .ui.dropdown > .dropdown.icon:before {
      float: right;
      margin-top: -5px; }
    .edit-post-visual-editor .viz.showcase .btn-reset, #root .viz.showcase .btn-reset {
      background-color: #66676d;
      color: #ffffff;
      margin-right: 20px; }
      .edit-post-visual-editor .viz.showcase .btn-reset:hover, #root .viz.showcase .btn-reset:hover {
        background-color: #56585b !important; }
    .edit-post-visual-editor .viz.showcase textarea, #root .viz.showcase textarea {
      height: 50px;
      font-size: 0.8em;
      padding: 10px; }
      .edit-post-visual-editor .viz.showcase textarea::placeholder, .edit-post-visual-editor .viz.showcase textarea::-webkit-input-placeholder, #root .viz.showcase textarea::placeholder, #root .viz.showcase textarea::-webkit-input-placeholder {
        color: rgba(191, 191, 191, 0.87); }
    .edit-post-visual-editor .viz.showcase .validated .field.error iframe, #root .viz.showcase .validated .field.error iframe {
      background-color: #fff6f6;
      border: 1px solid #e0b4b4; }
    .edit-post-visual-editor .viz.showcase .validated .field.error input, #root .viz.showcase .validated .field.error input {
      background-color: #98511a; }
    .edit-post-visual-editor .viz.showcase .upload.files, #root .viz.showcase .upload.files {
      text-align: right; }
      .edit-post-visual-editor .viz.showcase .upload.files.error, #root .viz.showcase .upload.files.error {
        background-color: transparent; }
      .edit-post-visual-editor .viz.showcase .upload.files .ui.green.label,
      .edit-post-visual-editor .viz.showcase .upload.files .ui.green.labels .label, #root .viz.showcase .upload.files .ui.green.label,
      #root .viz.showcase .upload.files .ui.green.labels .label {
        background-color: #52a963 !important; }
      .edit-post-visual-editor .viz.showcase .upload.files .ui.button, #root .viz.showcase .upload.files .ui.button {
        font-size: .8rem;
        margin-top: 10px;
        margin-right: 0; }
    .edit-post-visual-editor .viz.showcase .form-buttons, #root .viz.showcase .form-buttons {
      padding-top: 30px; }
      .edit-post-visual-editor .viz.showcase .form-buttons button, #root .viz.showcase .form-buttons button {
        width: 110px; }
    .edit-post-visual-editor .viz.showcase .dropzone, #root .viz.showcase .dropzone {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.9em;
      padding: 10px;
      border-radius: 2px;
      background-color: #fafafa;
      color: rgba(191, 191, 191, 0.87);
      outline: none;
      transition: border 0.24s ease-in-out 0s;
      backface-visibility: hidden; }
      .edit-post-visual-editor .viz.showcase .dropzone p, #root .viz.showcase .dropzone p {
        font-size: 0.9em;
        line-height: 120%;
        margin-top: -2px;
        padding: 0; }
      .edit-post-visual-editor .viz.showcase .dropzone ul, #root .viz.showcase .dropzone ul {
        list-style: none;
        margin-bottom: 0;
        text-align: left; }
        .edit-post-visual-editor .viz.showcase .dropzone ul li, #root .viz.showcase .dropzone ul li {
          margin: 4px; }
    .edit-post-visual-editor .viz.showcase .error-messages, #root .viz.showcase .error-messages {
      box-shadow: 0 0 0 1px #bb5454 inset, 0 0 0 0 transparent;
      background-color: #BB5454;
      color: #fff;
      font-size: 14px; }
      .edit-post-visual-editor .viz.showcase .error-messages ul, #root .viz.showcase .error-messages ul {
        list-style: none;
        text-align: left; }
        .edit-post-visual-editor .viz.showcase .error-messages ul li, #root .viz.showcase .error-messages ul li {
          margin: 4px; }
    .edit-post-visual-editor .viz.showcase .upload.files.error .dropzone, #root .viz.showcase .upload.files.error .dropzone {
      background-color: #ffffff;
      border: none;
      color: #9f3a38; }
  .edit-post-visual-editor .notice, .edit-post-visual-editor div.error, .edit-post-visual-editor div.updated, #root .notice, #root div.error, #root div.updated {
    background-color: none;
    border: none;
    border-radius: 5px;
    margin: 0;
    padding: 0; }
  .edit-post-visual-editor .ui.input.error > input, #root .ui.input.error > input {
    background-color: transparent;
    border: none; }
  .edit-post-visual-editor .notice, .edit-post-visual-editor div.error, .edit-post-visual-editor div.updated, #root .notice, #root div.error, #root div.updated {
    background-color: none; }
  .edit-post-visual-editor .ui.search.selection.dropdown .text, #root .ui.search.selection.dropdown .text {
    top: 0; }
  .edit-post-visual-editor .ui.error.search.selection.dropdown .text, #root .ui.error.search.selection.dropdown .text {
    left: 10px;
    top: 12px; }
  .edit-post-visual-editor .ui.search.dropdown > .text, #root .ui.search.dropdown > .text {
    top: -3px; }
  .edit-post-visual-editor .ui.negative.message, #root .ui.negative.message {
    position: absolute;
    bottom: -17px;
    right: -74px;
    color: #FFF;
    background-color: #ac4c4a;
    width: 252px; }
    .edit-post-visual-editor .ui.negative.message p, #root .ui.negative.message p {
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 0.9em;
      opacity: 1; }
  .edit-post-visual-editor .ui.success.message, #root .ui.success.message {
    background-color: #63c576;
    color: #FFF;
    position: absolute;
    bottom: -16px;
    right: -65px;
    width: 212px; }
    .edit-post-visual-editor .ui.success.message p, #root .ui.success.message p {
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 0.9em;
      opacity: 1; }
  .edit-post-visual-editor .ui.selection.dropdown.error, #root .ui.selection.dropdown.error {
    background-color: #ffffff;
    border-color: transparent; }
  .edit-post-visual-editor .viz.download .download-header, #root .viz.download .download-header {
    align-items: center; }
    .edit-post-visual-editor .viz.download .download-header .ui.container.entry-content, #root .viz.download .download-header .ui.container.entry-content {
      padding-bottom: 10px !important;
      padding-top: 10px !important; }
  .edit-post-visual-editor .viz.download .wp-block-columns, #root .viz.download .wp-block-columns {
    margin-bottom: 15px; }
  .edit-post-visual-editor .viz.download .download.area, #root .viz.download .download.area {
    display: flex; }
    .edit-post-visual-editor .viz.download .download.area .ui.container.entry-content, #root .viz.download .download.area .ui.container.entry-content {
      padding: 0; }
    .edit-post-visual-editor .viz.download .download.area i.icon.question.circle, #root .viz.download .download.area i.icon.question.circle {
      color: #008751;
      cursor: pointer;
      font-size: 0.8em;
      margin-left: 5px;
      padding: 0 0 20px 0; }
  .edit-post-visual-editor .viz.download .button.download, #root .viz.download .button.download {
    background-color: #55889F;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    z-index: 1000;
    border: 1px solid;
    margin-right: 10px; }
  .edit-post-visual-editor .viz.download .download-icon, #root .viz.download .download-icon {
    align-self: flex-end; }
  .edit-post-visual-editor .viz.download .download-icon:before, #root .viz.download .download-icon:before {
    top: 1px;
    margin-left: -5px;
    color: #51AB8D;
    border: 1px solid;
    cursor: pointer;
    padding: 7px 9px 9px 9px;
    position: relative;
    z-index: 999;
    background-color: #FFF !important;
    border-radius: 3px;
    cursor: pointer;
    z-index: 90 !important; }
  .edit-post-visual-editor .viz.download .active .download-icon:before, #root .viz.download .active .download-icon:before {
    padding-bottom: 17px;
    border-bottom: 0px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
  .edit-post-visual-editor .viz.download .dropdown.icon, #root .viz.download .dropdown.icon {
    display: none; }
  .edit-post-visual-editor .viz.download .dropdown.download .visible.menu.transition, #root .viz.download .dropdown.download .visible.menu.transition {
    position: absolute;
    left: auto;
    top: 36px;
    right: 9px;
    padding: 15px 20px;
    box-shadow: none;
    border-color: #51AB8D;
    border-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    font-size: 16px;
    color: #51AB8D;
    font-weight: 600;
    z-index: 80 !important; }
  .edit-post-visual-editor .viz.download .dropdown.download .visible.menu.transition .item, #root .viz.download .dropdown.download .visible.menu.transition .item {
    padding: 12px 0px 0px 0px !important;
    background: none !important; }
  .edit-post-visual-editor .viz.download .dropdown.download .visible.menu.transition .item input, #root .viz.download .dropdown.download .visible.menu.transition .item input {
    margin-top: 0px; }
  .edit-post-visual-editor .viz.download .dropdown.download input[type=radio], #root .viz.download .dropdown.download input[type=radio] {
    border: 1px solid #66676d;
    font-size: 10px;
    height: 14px;
    width: 14px;
    min-height: 6px;
    min-width: 6px;
    margin: 5px 5px 2px 0px; }
  .edit-post-visual-editor .viz.download .dropdown.download input[type=radio]:checked::before, #root .viz.download .dropdown.download input[type=radio]:checked::before {
    content: "";
    background-color: #31ba1a;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: 3px; }
  .edit-post-visual-editor .viz.download .dropdown.download label, #root .viz.download .dropdown.download label {
    font-size: 16px;
    color: #66676d; }
  .edit-post-visual-editor .viz.download.has-title .download.title, #root .viz.download.has-title .download.title {
    padding: 0;
    margin: 0px;
    float: left; }
    .edit-post-visual-editor .viz.download.has-title .download.title p, #root .viz.download.has-title .download.title p {
      line-height: 125%; }
  .edit-post-visual-editor .viz.download.has-title .ui.grid, .edit-post-visual-editor .viz.download.has-title .column, #root .viz.download.has-title .ui.grid, #root .viz.download.has-title .column {
    padding: 0px;
    margin: 0px; }
    .edit-post-visual-editor .viz.download.has-title .ui.grid .editing, .edit-post-visual-editor .viz.download.has-title .column .editing, #root .viz.download.has-title .ui.grid .editing, #root .viz.download.has-title .column .editing {
      margin-top: -1rem !important; }
  .edit-post-visual-editor .viz.download.has-title .ui.grid, #root .viz.download.has-title .ui.grid {
    margin-bottom: -5px;
    margin-top: -5px; }
  .edit-post-visual-editor .viz.download.light .wrapper, #root .viz.download.light .wrapper {
    border: 2px solid #0692fe;
    padding: 3px 5px;
    border-radius: 5px;
    float: right; }
  .edit-post-visual-editor .viz.download.light .map.wrapper, #root .viz.download.light .map.wrapper {
    background-color: #FFFFFF;
    border: none;
    float: none;
    overflow: hidden; }
    @media print {
      .edit-post-visual-editor .viz.download.light .map.wrapper, #root .viz.download.light .map.wrapper {
        margin-left: 20px; } }
  .edit-post-visual-editor .viz.download.light [data-tooltip]:after, #root .viz.download.light [data-tooltip]:after {
    font-size: 0.8rem; }
  .edit-post-visual-editor .viz.download.light [data-tooltip]:before, #root .viz.download.light [data-tooltip]:before {
    bottom: 23px; }
  .edit-post-visual-editor .viz.download.light .ui.button.download, #root .viz.download.light .ui.button.download {
    border: 0px;
    font-weight: 400;
    padding: 5px;
    background-color: transparent;
    color: #0692fe;
    margin-right: 0; }
    .edit-post-visual-editor .viz.download.light .ui.button.download:hover, #root .viz.download.light .ui.button.download:hover {
      color: #0692fe; }
  .edit-post-visual-editor .viz.download.light .dropdown.download, #root .viz.download.light .dropdown.download {
    padding-right: 7px; }
  .edit-post-visual-editor .viz.download.light .download-icon, #root .viz.download.light .download-icon {
    /*&:hover{
      &:before{
        color: #046fbe;
      }
    }*/ }
    .edit-post-visual-editor .viz.download.light .download-icon:before, #root .viz.download.light .download-icon:before {
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border: 0px;
      border-left: 2px solid #0692fe;
      color: #0692fe;
      height: 20px;
      margin-left: 5px;
      padding: 0 4px 0 7px; }
  .edit-post-visual-editor .viz.download.light .wrapper:hover, #root .viz.download.light .wrapper:hover {
    background-color: #0692fe; }
    .edit-post-visual-editor .viz.download.light .wrapper:hover .ui.button.download, #root .viz.download.light .wrapper:hover .ui.button.download {
      background-color: transparent;
      color: #FFFFFF; }
    .edit-post-visual-editor .viz.download.light .wrapper:hover .download-icon:before, #root .viz.download.light .wrapper:hover .download-icon:before {
      color: #FFFFFF;
      background-color: transparent !important;
      border-left: 2px solid #FFFFFF; }
  .edit-post-visual-editor .viz.download.light .map.wrapper:hover, #root .viz.download.light .map.wrapper:hover {
    background-color: #FFFFFF; }
  .edit-post-visual-editor .viz.download.light .dropdown.download .visible.menu.transition, #root .viz.download.light .dropdown.download .visible.menu.transition {
    border: 2px solid #0692fe;
    top: 30px;
    right: -7px;
    font-weight: 400;
    color: #0692fe;
    border-radius: 5px !important; }
  .edit-post-visual-editor .ui.top.left.popup.title-popup, #root .ui.top.left.popup.title-popup {
    background-color: #f7f7f7;
    padding: 20px; }
    .edit-post-visual-editor .ui.top.left.popup.title-popup:before, #root .ui.top.left.popup.title-popup:before {
      background: #f7f7f7; }
  .edit-post-visual-editor .ui.search, #root .ui.search {
    width: 200px; }
    .edit-post-visual-editor .ui.search .results.header, #root .ui.search .results.header {
      font-family: Roboto;
      color: #66676d !important;
      font-size: 10px;
      padding: 7px 7px;
      color: #616161 !important;
      margin: 0px 16px;
      border-left: none;
      border-right: none;
      font-weight: 300; }
    .edit-post-visual-editor .ui.search .results h4, #root .ui.search .results h4 {
      color: #454545;
      margin-bottom: 10px !important; }
    .edit-post-visual-editor .ui.search .ui.search input.prompt, #root .ui.search .ui.search input.prompt {
      border-radius: 3px; }
    .edit-post-visual-editor .ui.search .ui.icon.input > input, #root .ui.search .ui.icon.input > input {
      border-radius: 3px; }
    .edit-post-visual-editor .ui.search .ui.blue.segment:not(.inverted), #root .ui.search .ui.blue.segment:not(.inverted) {
      border-top: none !important; }
    .edit-post-visual-editor .ui.search .ui.search > .results, #root .ui.search .ui.search > .results {
      width: 315px !important;
      margin-top: 0px !important;
      border-radius: 0px !important;
      border: 1px solid #d4d4d5;
      border-top: none; }
    .edit-post-visual-editor .ui.search .ui.segment, #root .ui.search .ui.segment {
      box-shadow: none; }
    .edit-post-visual-editor .ui.search .has-standard-12-font-size, .edit-post-visual-editor .ui.search .has-standard-12-font-size, #root .ui.search .has-standard-12-font-size, #root .ui.search .has-standard-12-font-size {
      color: #9e9e9e; }
    .edit-post-visual-editor .ui.search .has-standard-12-font-size:hover, .edit-post-visual-editor .ui.search .has-standard-12-font-size:hover, #root .ui.search .has-standard-12-font-size:hover, #root .ui.search .has-standard-12-font-size:hover {
      color: #3b5b74; }
    .edit-post-visual-editor .ui.search .has-standard-12-font-size h5, #root .ui.search .has-standard-12-font-size h5 {
      color: #003a5b;
      font-size: 14px !important;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 3px !important;
      line-height: 16px;
      font-weight: 300;
      margin-bottom: 10px !important;
      margin-top: 10px !important; }
    .edit-post-visual-editor .ui.search .has-standard-12-font-size:hover h5, #root .ui.search .has-standard-12-font-size:hover h5 {
      color: #3b5b74; }
    .edit-post-visual-editor .ui.search .see-more-results, #root .ui.search .see-more-results {
      font-size: 12px;
      margin: 0px 23px;
      font-weight: 700;
      color: #4a5da8; }
    .edit-post-visual-editor .ui.search .ui.search > .results .result, #root .ui.search .ui.search > .results .result {
      padding: 4px 8px 0px 19px; }
    .edit-post-visual-editor .ui.search b, #root .ui.search b {
      color: #003a5b;
      font-weight: 700; }
    .edit-post-visual-editor .ui.search .search-content, #root .ui.search .search-content {
      color: #666666; }
    .edit-post-visual-editor .ui.search .results.transition.visible, #root .ui.search .results.transition.visible {
      transform: translateX(-189px);
      margin-top: 0; }
  .edit-post-visual-editor .downloadPdf, #root .downloadPdf {
    margin-bottom: 40px;
    text-align: center; }
    .edit-post-visual-editor .downloadPdf .download, #root .downloadPdf .download {
      border: 1px solid blue;
      font-weight: 700 !important;
      height: 50px; }
  @media print {
    .edit-post-visual-editor .downloadPdf, #root .downloadPdf {
      display: none; } }
  .edit-post-visual-editor .ui.container.filter, #root .ui.container.filter {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 2px; }
    .edit-post-visual-editor .ui.container.filter input, #root .ui.container.filter input {
      display: none; }
    .edit-post-visual-editor .ui.container.filter .ui.button, #root .ui.container.filter .ui.button {
      text-wrap: none;
      cursor: pointer !important;
      color: #66676d;
      border: 1px solid #ced0da;
      font-size: 16px;
      height: 38px;
      padding: 2px;
      font-weight: 400;
      background-color: #f5f6f9; }
    .edit-post-visual-editor .ui.container.filter .ui.button.applied, #root .ui.container.filter .ui.button.applied {
      color: #5089a6; }
    .edit-post-visual-editor .ui.container.filter .angle.down.icon, #root .ui.container.filter .angle.down.icon {
      float: right;
      margin-right: 4px;
      margin-left: auto;
      margin-top: 9px; }
    .edit-post-visual-editor .ui.container.filter .ui.dropdown, #root .ui.container.filter .ui.dropdown {
      width: 100%; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .divider.text, #root .ui.container.filter .ui.dropdown .divider.text {
        cursor: pointer;
        width: 100%;
        font-size: 14px;
        line-height: 25px; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .ui.label, #root .ui.container.filter .ui.dropdown .ui.label {
        cursor: pointer;
        border: 0px;
        font-size: 13px;
        font-weight: 400;
        margin: 0px;
        padding: 5px 0px; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown i, #root .ui.container.filter .ui.dropdown i {
        margin-right: -1em;
        margin-top: .5em; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .item, #root .ui.container.filter .ui.dropdown .item {
        height: 25px; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .ui.segment, #root .ui.container.filter .ui.dropdown .ui.segment {
        color: #66676d;
        border-radius: 0px;
        border-left: 0px;
        border-right: 0px;
        padding: 7px 19px;
        margin: 0px 0px 10px 0px;
        font-size: 14px;
        font-weight: 300; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .menu, #root .ui.container.filter .ui.dropdown .menu {
        min-height: 100px; }
      .edit-post-visual-editor .ui.container.filter .ui.dropdown .ui.container, #root .ui.container.filter .ui.dropdown .ui.container {
        margin: 0px;
        width: auto;
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-weight: 200;
        padding: 0px 15px 10px 15px;
        max-height: 200px; }
        .edit-post-visual-editor .ui.container.filter .ui.dropdown .ui.container .item, #root .ui.container.filter .ui.dropdown .ui.container .item {
          height: 25px;
          box-sizing: border-box;
          border: 1px;
          max-width: 50%; }
        .edit-post-visual-editor .ui.container.filter .ui.dropdown .ui.container .ui.checkbox label, #root .ui.container.filter .ui.dropdown .ui.container .ui.checkbox label {
          padding-left: 1.6em;
          font-size: 15px; }
  .edit-post-visual-editor .dropdown-single-column, #root .dropdown-single-column {
    max-height: none !important; }
  .edit-post-visual-editor .dropdown-item-single-column, #root .dropdown-item-single-column {
    max-width: 100% !important;
    width: 100% !important; }
  .edit-post-visual-editor .filter-search, #root .filter-search {
    display: inline-flex !important; }
  .edit-post-visual-editor .clear-icon, #root .clear-icon {
    margin-right: auto !important;
    margin-top: auto !important; }
  .edit-post-visual-editor [data-group='directcost'] .ui.container.filter .ui.dropdown .ui.container, #root [data-group='directcost'] .ui.container.filter .ui.dropdown .ui.container {
    max-height: none; }
  .edit-post-visual-editor [data-group='directcost'] .ui.container.filter .ui.dropdown .ui.container .item, #root [data-group='directcost'] .ui.container.filter .ui.dropdown .ui.container .item {
    max-width: 100%;
    width: 100%; }
  .edit-post-visual-editor .editing .ui.container.filter, #root .editing .ui.container.filter {
    margin: 3px !important; }
    .edit-post-visual-editor .editing .ui.container.filter .menu, #root .editing .ui.container.filter .menu {
      display: none !important; }
  .edit-post-visual-editor .ui.container.filter, #root .ui.container.filter {
    width: 100% !important;
    padding: 0 !important; }
    .edit-post-visual-editor .ui.container.filter .item, #root .ui.container.filter .item {
      display: block !important; }
    .edit-post-visual-editor .ui.container.filter .item.dropdown-item-single-column, #root .ui.container.filter .item.dropdown-item-single-column {
      display: block !important; }
  .edit-post-visual-editor body, #root body {
    background-color: transparent !important; }
  .edit-post-visual-editor .map.component, #root .map.component {
    position: relative;
    background-color: transparent;
    overflow: hidden; }
    .edit-post-visual-editor .map.component .legend, #root .map.component .legend {
      background-color: #FFF;
      margin-top: 4px;
      overflow: hidden; }
      @media only screen and (max-width: 414px) {
        .edit-post-visual-editor .map.component .legend, #root .map.component .legend {
          margin-top: 10px; } }
      .edit-post-visual-editor .map.component .legend ul, #root .map.component .legend ul {
        list-style: none;
        padding: 0;
        margin: 0 0 0 10px;
        width: 100%; }
        .edit-post-visual-editor .map.component .legend ul li, #root .map.component .legend ul li {
          display: inline-flex;
          float: left;
          margin-bottom: 10px;
          margin-right: 10px; }
          .edit-post-visual-editor .map.component .legend ul li .legend-label, #root .map.component .legend ul li .legend-label {
            color: #66676d;
            display: block;
            float: left;
            font-weight: 400;
            font-size: 15px;
            line-height: 12px;
            margin-top: 5px;
            margin-left: 5px;
            white-space: nowrap; }
          .edit-post-visual-editor .map.component .legend ul li .symbol, #root .map.component .legend ul li .symbol {
            float: left;
            height: 15px;
            margin-top: 3px;
            width: 15px; }
    .edit-post-visual-editor .map.component .edit-mode-message, #root .map.component .edit-mode-message {
      border: 1px solid #1e1e1e;
      padding: 10px;
      border-radius: 2px;
      margin: 10px; }
    .edit-post-visual-editor .map.component .control.panel, #root .map.component .control.panel {
      position: absolute;
      bottom: 8vh;
      margin-left: 0.5vw; }
      .edit-post-visual-editor .map.component .control.panel .reset,
      .edit-post-visual-editor .map.component .control.panel .zoom, #root .map.component .control.panel .reset,
      #root .map.component .control.panel .zoom {
        padding: 0.2vw;
        margin-top: 0.2vw;
        height: auto !important;
        width: auto !important; }
      .edit-post-visual-editor .map.component .control.panel .icon, #root .map.component .control.panel .icon {
        background-color: #3e53a6;
        padding: 5px;
        width: 30px;
        height: 25px;
        font-size: 14px;
        color: #FFF; }
    .edit-post-visual-editor .map.component .ocean-color, #root .map.component .ocean-color {
      fill: #c1e1ec; }
    .edit-post-visual-editor .map.component rect.country-map-label, #root .map.component rect.country-map-label {
      fill: #5baf95; }
    .edit-post-visual-editor .map.component circle.active, #root .map.component circle.active {
      fill: #FFF;
      stroke: #5baf95;
      stroke-width: 2; }
    .edit-post-visual-editor .map.component text tspan.label, #root .map.component text tspan.label {
      font-weight: bold;
      fill: #5baf95;
      pointer-events: all; }
    .edit-post-visual-editor .map.component text.focus tspan.label, #root .map.component text.focus tspan.label {
      color: #FFF;
      fill: #FFF !important; }
    .edit-post-visual-editor .map.component path.zoom-enabled, #root .map.component path.zoom-enabled {
      cursor: pointer; }
    .edit-post-visual-editor .map.component path.focus, #root .map.component path.focus {
      opacity: 0.8; }
    .edit-post-visual-editor .map.component circle.focus, #root .map.component circle.focus {
      stroke: #67a0bd;
      fill: #84acc0; }
    .edit-post-visual-editor .map.component .big.label, #root .map.component .big.label {
      font-size: 40px;
      text-transform: uppercase;
      fill: #FFF; }
    .edit-post-visual-editor .map.component .map-label, #root .map.component .map-label {
      fill: #595959; }
      @media print {
        .edit-post-visual-editor .map.component .map-label, #root .map.component .map-label {
          font-size: 14px;
          font-weight: 600 !important; } }
    .edit-post-visual-editor .map.component .map-label-bg, #root .map.component .map-label-bg {
      fill: #fff6e1; }
    .edit-post-visual-editor .map.component .map-label-no-bg, #root .map.component .map-label-no-bg {
      opacity: 0; }
    .edit-post-visual-editor .map.component .map-label-value, #root .map.component .map-label-value {
      font-weight: 700; }
    .edit-post-visual-editor .map.component .big.numeric.label, #root .map.component .big.numeric.label {
      padding: 0;
      font-size: 140px;
      fill: #5baf95;
      font-weight: bold; }
    .edit-post-visual-editor .map.component .hidden, #root .map.component .hidden {
      display: none; }
    .edit-post-visual-editor .map.component .national-avg-value, #root .map.component .national-avg-value {
      color: #ffb400;
      margin-left: 10px;
      font-size: 14px;
      font-weight: bold; }
    .edit-post-visual-editor .map.component .national-avg-label, #root .map.component .national-avg-label {
      margin-left: 5px;
      font-size: 14px;
      color: #66676d;
      font-weight: bold; }
      @media print {
        .edit-post-visual-editor .map.component .national-avg-label, #root .map.component .national-avg-label {
          white-space: nowrap; } }
    .edit-post-visual-editor .map.component .footnote, #root .map.component .footnote {
      margin: 0;
      min-height: none;
      padding: 0;
      text-align: left;
      width: auto; }
      @media print {
        .edit-post-visual-editor .map.component .footnote, #root .map.component .footnote {
          padding-left: 10px; } }
      .edit-post-visual-editor .map.component .footnote p, #root .map.component .footnote p {
        line-height: 40px;
        font-size: 13px;
        padding: 0px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: right; }
    .edit-post-visual-editor .map.component .legend-title, #root .map.component .legend-title {
      float: left;
      text-align: right;
      font-size: 14px;
      color: #66676d;
      font-weight: bold;
      margin-right: 10px;
      white-space: nowrap; }
    .edit-post-visual-editor .map.component .vertical-spacer, #root .map.component .vertical-spacer {
      color: #dadada;
      font-weight: bold;
      margin-top: -3px; }
    .edit-post-visual-editor .map.component .highlighted-loc-info, #root .map.component .highlighted-loc-info {
      width: 20%;
      border-radius: 5px;
      padding: 5px;
      top: 45px;
      right: 15px;
      position: absolute; }
      .edit-post-visual-editor .map.component .highlighted-loc-info span, #root .map.component .highlighted-loc-info span {
        font-weight: bold; }
    .edit-post-visual-editor .map.component .measure-selector, #root .map.component .measure-selector {
      margin-top: 5px; }
      .edit-post-visual-editor .map.component .measure-selector .label, #root .map.component .measure-selector .label {
        font-weight: bold; }
      .edit-post-visual-editor .map.component .measure-selector ul, #root .map.component .measure-selector ul {
        margin: 5px auto 0 10px;
        overflow: hidden; }
      .edit-post-visual-editor .map.component .measure-selector li, #root .map.component .measure-selector li {
        display: inline-flex;
        float: left;
        margin-bottom: 5px;
        margin-right: 10px;
        font-size: 14px;
        padding: 5px 5px 5px 0px;
        color: #66676d;
        cursor: pointer; }
        @media print {
          .edit-post-visual-editor .map.component .measure-selector li, #root .map.component .measure-selector li {
            white-space: nowrap; } }
        .edit-post-visual-editor .map.component .measure-selector li input[type=radio], #root .map.component .measure-selector li input[type=radio] {
          margin-top: 0.1rem;
          margin-bottom: 0.25rem; }
  .edit-post-visual-editor .back-to-top-container, #root .back-to-top-container {
    border-radius: 5px;
    padding: 10px;
    position: static;
    background-color: #ecb040; }
    .edit-post-visual-editor .back-to-top-container .icon-wrapper, #root .back-to-top-container .icon-wrapper {
      height: 45%;
      font-size: 10px;
      text-align: center;
      position: static;
      background-color: #ecb040; }
      .edit-post-visual-editor .back-to-top-container .icon-wrapper .custom-icon, #root .back-to-top-container .icon-wrapper .custom-icon {
        font-size: 25px;
        bottom: 5px;
        position: static;
        text-align: center;
        color: #fff;
        background-color: #ecb040; }
    .edit-post-visual-editor .back-to-top-container .btn-text, #root .back-to-top-container .btn-text {
      height: 45%;
      font-size: 14px;
      color: #fff;
      text-align: center;
      font-weight: bold; }
  .edit-post-visual-editor .top-navigator, #root .top-navigator {
    background-color: #5baf95;
    right: 0px !important; }
    .edit-post-visual-editor .top-navigator div.ui.menu a.item, #root .top-navigator div.ui.menu a.item {
      color: white; }
    .edit-post-visual-editor .top-navigator .ui.menu .item:before, #root .top-navigator .ui.menu .item:before {
      width: 0px; }
  .edit-post-visual-editor .ui.container.data-filters-reset, #root .ui.container.data-filters-reset {
    margin-left: 0em !important;
    margin-right: 0em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px 2px;
    background-color: #fff;
    cursor: pointer;
    color: #52a963;
    font-weight: bold;
    text-transform: lowercase; }
    .edit-post-visual-editor .ui.container.data-filters-reset :first-letter, #root .ui.container.data-filters-reset :first-letter {
      text-transform: capitalize !important; }
    .edit-post-visual-editor .ui.container.data-filters-reset span, #root .ui.container.data-filters-reset span {
      float: right;
      font-size: 14px; }
    .edit-post-visual-editor .ui.container.data-filters-reset .custom-undo-icon, #root .ui.container.data-filters-reset .custom-undo-icon {
      color: #52a963;
      background-color: #fff; }
  .edit-post-visual-editor .ui.container.disabled, #root .ui.container.disabled {
    margin-left: 0em !important;
    margin-right: 0em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px 2px;
    background-color: #fff;
    cursor: pointer;
    color: lightgrey;
    font-weight: bold;
    text-transform: lowercase; }
    .edit-post-visual-editor .ui.container.disabled :first-letter, #root .ui.container.disabled :first-letter {
      text-transform: capitalize !important; }
    .edit-post-visual-editor .ui.container.disabled span, #root .ui.container.disabled span {
      float: right;
      font-size: 14px; }
    .edit-post-visual-editor .ui.container.disabled .custom-undo-icon, #root .ui.container.disabled .custom-undo-icon {
      color: lightgrey;
      background-color: #fff; }
  .edit-post-visual-editor .wp-info-tooltip.wp-info, #root .wp-info-tooltip.wp-info {
    display: inline; }
  .edit-post-visual-editor .popup, #root .popup {
    border: 1px solid red; }
  @media print {
    .edit-post-visual-editor .wp-info-tooltip, #root .wp-info-tooltip {
      display: none !important; } }
  .edit-post-visual-editor .wp-reference.viz-component, #root .wp-reference.viz-component {
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    line-height: 100%;
    vertical-align: baseline;
    position: relative;
    top: -0.5em;
    z-index: 1; }
  .edit-post-visual-editor .ui.popup.reference-popup, #root .ui.popup.reference-popup {
    max-width: 300px;
    width: 300px; }
    .edit-post-visual-editor .ui.popup.reference-popup p, #root .ui.popup.reference-popup p {
      font-size: 1em; }
    .edit-post-visual-editor .ui.popup.reference-popup a, #root .ui.popup.reference-popup a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word; }
  .edit-post-visual-editor a.wp-reference, #root a.wp-reference {
    display: inline;
    cursor: pointer;
    display: inline !important;
    position: relative;
    font-size: 75%;
    color: #5089a6;
    content: attr(data-index) !important;
    margin-top: 0; }
  .edit-post-visual-editor .white-reference a.wp-reference, #root .white-reference a.wp-reference {
    color: white; }
  .edit-post-visual-editor .references.list, #root .references.list {
    color: #66676d;
    margin: 20px !important;
    padding: 10px 10px 10px 0; }
    .edit-post-visual-editor .references.list .edit-mode-message, #root .references.list .edit-mode-message {
      border: 1px solid #1e1e1e;
      padding: 10px;
      border-radius: 2px;
      margin-left: -10px; }
    .edit-post-visual-editor .references.list .column, #root .references.list .column {
      padding-top: 0 !important;
      padding-bottom: 1rem !important;
      padding-left: 0 !important; }
    .edit-post-visual-editor .references.list .reference, #root .references.list .reference {
      font-size: 14px;
      display: block; }
      .edit-post-visual-editor .references.list .reference .index, #root .references.list .reference .index {
        font-size: 14px;
        display: inline; }
      .edit-post-visual-editor .references.list .reference .index:after, #root .references.list .reference .index:after {
        content: ". ";
        margin-right: 2px;
        font-size: 11px;
        display: inline; }
      .edit-post-visual-editor .references.list .reference .content, #root .references.list .reference .content {
        display: inline; }
      .edit-post-visual-editor .references.list .reference .link, #root .references.list .reference .link {
        overflow-wrap: break-word; }
  @media (max-width: 480px) {
    .edit-post-visual-editor .references.list, #root .references.list {
      overflow: auto; } }
  .edit-post-visual-editor .time.line .tooltip, #root .time.line .tooltip {
    border: 1px solid transparent;
    height: auto;
    width: 625px;
    z-index: 1000; }
    .edit-post-visual-editor .time.line .tooltip a, #root .time.line .tooltip a {
      pointer-events: all; }
    .edit-post-visual-editor .time.line .tooltip .tooltip, #root .time.line .tooltip .tooltip {
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      padding: 15px;
      width: 100%; }
    .edit-post-visual-editor .time.line .tooltip .wp-block-columns, #root .time.line .tooltip .wp-block-columns {
      margin-bottom: 0px; }
    .edit-post-visual-editor .time.line .tooltip p, #root .time.line .tooltip p {
      color: #fff;
      pointer-events: none; }
    .edit-post-visual-editor .time.line .tooltip img, #root .time.line .tooltip img {
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px; }
  .edit-post-visual-editor .time.line .pointer, #root .time.line .pointer {
    position: absolute;
    top: 50%;
    width: 0;
    height: 0; }
  .edit-post-visual-editor .time.line text, #root .time.line text {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 22px; }
  .edit-post-visual-editor .time.line text, .edit-post-visual-editor .time.line circle, #root .time.line text, #root .time.line circle {
    cursor: pointer;
    pointer-events: all; }
  .edit-post-visual-editor .chart.container .measures, #root .chart.container .measures {
    display: flex;
    justify-content: left; }
    .edit-post-visual-editor .chart.container .measures .measure.item, #root .chart.container .measures .measure.item {
      cursor: pointer;
      border-radius: 6px;
      padding: 5px;
      border: 1px solid #66676d;
      font-size: 11px;
      margin-left: 10px; }
    .edit-post-visual-editor .chart.container .measures .label-item, #root .chart.container .measures .label-item {
      padding: 5px; }
      .edit-post-visual-editor .chart.container .measures .label-item label, #root .chart.container .measures .label-item label {
        font-size: 14px;
        font-weight: bold;
        color: #66676d; }
    .edit-post-visual-editor .chart.container .measures .measure.item.active, #root .chart.container .measures .measure.item.active {
      color: #FFF;
      background-color: #5baf95; }
    .edit-post-visual-editor .chart.container .measures .single-select, #root .chart.container .measures .single-select {
      padding: 5px;
      font-size: 13px; }
  .edit-post-visual-editor .ui.placeholder.segment.chartNoDataMessage, #root .ui.placeholder.segment.chartNoDataMessage {
    background-color: white; }
  .edit-post-visual-editor .chartNoDataMessage, #root .chartNoDataMessage {
    height: 100%; }
    .edit-post-visual-editor .chartNoDataMessage .ui.icon.header, #root .chartNoDataMessage .ui.icon.header {
      align-self: center;
      width: 260px;
      margin: 30px; }
    .edit-post-visual-editor .chartNoDataMessage .ui.header, #root .chartNoDataMessage .ui.header {
      padding-top: 20px;
      padding-bottom: 20px; }
    .edit-post-visual-editor .chartNoDataMessage .noDataSVG, #root .chartNoDataMessage .noDataSVG {
      fill: dimgrey;
      width: 120px; }
    .edit-post-visual-editor .chartNoDataMessage .WPnoDataMsg, #root .chartNoDataMessage .WPnoDataMsg {
      color: dimgrey;
      padding-top: 30px;
      font-family: "Roboto Light";
      font-size: 24px; }
    .edit-post-visual-editor .chartNoDataMessage .ui.button, #root .chartNoDataMessage .ui.button {
      color: white;
      background-color: dimgrey;
      margin-bottom: 40px; }
  .edit-post-visual-editor .container.measures.group span, #root .container.measures.group span {
    background: none;
    font-size: 16px;
    font-weight: 300;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer; }
  .edit-post-visual-editor .container.measures.group .inputs.lists, #root .container.measures.group .inputs.lists {
    display: inline;
    margin-left: 1em; }
    .edit-post-visual-editor .container.measures.group .inputs.lists input[type="radio"], #root .container.measures.group .inputs.lists input[type="radio"] {
      appearance: none;
      background-color: #fff;
      margin: 0.5em;
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border-radius: 50%;
      transform: translateY(-0.035em); }
      .edit-post-visual-editor .container.measures.group .inputs.lists input[type="radio"] :focus, #root .container.measures.group .inputs.lists input[type="radio"] :focus {
        outline: 2em solid currentColor;
        outline-offset: 2em; }
    .edit-post-visual-editor .container.measures.group .inputs.lists input[type="radio"]::before, #root .container.measures.group .inputs.lists input[type="radio"]::before {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      transition: 120ms transform ease-in-out;
      margin: 3px; }
  @media (max-width: 768px) {
    .edit-post-visual-editor .wp-block-columns, #root .wp-block-columns {
      margin: 0 !important; }
    .edit-post-visual-editor .container.measures.group span:first-child, #root .container.measures.group span:first-child {
      display: block !important;
      margin-bottom: 5px !important; }
    .edit-post-visual-editor .container.measures.group .inputs.lists, #root .container.measures.group .inputs.lists {
      margin: 0 15px 0 0 !important; }
      .edit-post-visual-editor .container.measures.group .inputs.lists input, #root .container.measures.group .inputs.lists input {
        margin: 0 5px 0 0 !important; } }
  .edit-post-visual-editor .back-to-top-container, #root .back-to-top-container {
    border-radius: 5px;
    padding: 10px;
    position: static;
    background-color: #ecb040; }
    .edit-post-visual-editor .back-to-top-container .icon-wrapper, #root .back-to-top-container .icon-wrapper {
      height: 45%;
      font-size: 10px;
      text-align: center;
      position: static;
      background-color: #ecb040; }
      .edit-post-visual-editor .back-to-top-container .icon-wrapper .custom-icon, #root .back-to-top-container .icon-wrapper .custom-icon {
        font-size: 25px;
        bottom: 5px;
        position: static;
        text-align: center;
        color: #fff;
        background-color: #ecb040; }
    .edit-post-visual-editor .back-to-top-container .btn-text, #root .back-to-top-container .btn-text {
      height: 45%;
      font-size: 14px;
      color: #fff;
      text-align: center;
      font-weight: bold; }
  .edit-post-visual-editor .top-navigator, #root .top-navigator {
    background-color: #008751;
    right: 0px !important; }
    .edit-post-visual-editor .top-navigator div.ui.menu a.item, #root .top-navigator div.ui.menu a.item {
      color: white; }
    .edit-post-visual-editor .top-navigator .ui.menu .item:before, #root .top-navigator .ui.menu .item:before {
      width: 0px; }
  .edit-post-visual-editor .ui.float-search, #root .ui.float-search {
    text-align: center;
    display: block;
    font-family: Roboto;
    font-weight: 700;
    height: 71px;
    width: 50px;
    color: #54657e;
    margin-top: 26px;
    padding: 8px 0px 0px 13px;
    z-index: 10;
    border: 1px solid transparent;
    border-bottom: 1px solid transparent; }
  .edit-post-visual-editor .ui-float-search-active, #root .ui-float-search-active {
    color: #5089a6;
    background-color: #FFFFFF;
    border: 1px solid #dadada !important;
    border-bottom: 1px solid #FFFFFF !important;
    cursor: pointer !important; }
  .edit-post-visual-editor #float-input-container, #root #float-input-container {
    text-align: center;
    margin: 0px;
    padding-left: 108px;
    left: 0px;
    top: 196px;
    width: 100%;
    height: 51.43px;
    position: absolute;
    border: 1px solid #dadada !important;
    background-color: #FFF;
    transform: translateY(2px);
    z-index: 9999999; }
    .edit-post-visual-editor #float-input-container .input.search, #root #float-input-container .input.search {
      border-radius: 0px;
      border: 0px;
      width: 100%;
      height: 50px; }
    .edit-post-visual-editor #float-input-container .input.search:focus, #root #float-input-container .input.search:focus {
      outline: none;
      box-shadow: none; }
  .edit-post-visual-editor #float-results-container, #root #float-results-container {
    background-color: #FFF;
    border: 1px solid #dadada;
    border-right: 0 !important;
    border-left: 0 !important;
    padding: 12px;
    text-align: left;
    left: 50%;
    transform: translate(-50%, 0.5px);
    top: 50px;
    z-index: 1500;
    cursor: pointer;
    position: absolute;
    width: 100vw !important; }
    .edit-post-visual-editor #float-results-container .searching-results, #root #float-results-container .searching-results {
      padding: 15px 0px 15px 108px;
      border-bottom: 1px solid #dadada; }
    .edit-post-visual-editor #float-results-container .searching-results:first-child, #root #float-results-container .searching-results:first-child {
      border-top: 0px solid #dadada; }
    .edit-post-visual-editor #float-results-container .searching-results:last-child, #root #float-results-container .searching-results:last-child {
      border-bottom: 0px solid #dadada; }
    .edit-post-visual-editor #float-results-container .searching-results:hover, #root #float-results-container .searching-results:hover {
      background-color: #F9FAFB; }
    .edit-post-visual-editor #float-results-container .search-title, #root #float-results-container .search-title {
      font-family: Roboto;
      font-size: 18px !important;
      color: #4C4D50; }
    .edit-post-visual-editor #float-results-container .search-content, #root #float-results-container .search-content {
      font-family: Roboto;
      font-size: 16px !important;
      color: #4C4D50;
      padding-top: 5px;
      max-width: 700px; }
    .edit-post-visual-editor #float-results-container .breadcrumbs-search, #root #float-results-container .breadcrumbs-search {
      margin: 0;
      font-family: Roboto;
      font-size: 14px !important;
      padding-bottom: 10px;
      color: #003a5b;
      font-weight: 300; }
    .edit-post-visual-editor #float-results-container .float-results-header, #root #float-results-container .float-results-header {
      margin: 0 30px 0 30px;
      display: block;
      padding-top: 5px;
      padding-left: 78px;
      font-family: Roboto;
      font-size: 12px;
      color: #616161;
      font-weight: 300; }
    .edit-post-visual-editor #float-results-container b, #root #float-results-container b {
      color: #003a5b;
      font-weight: 700; }

.agree-and-download.link {
  font-size: 16px;
  cursor: pointer; }

.agree-and-download.link:hover {
  color: #184c73 !important; }

.modal .agreement-header {
  display: flex !important;
  align-items: center;
  font-family: "Roboto", sans-serif !important;
  font-size: 23px !important;
  background-color: #52a963 !important;
  color: #FFFFFF !important;
  font-weight: 300 !important;
  position: relative;
  height: 75px; }

.modal .agreement-header span {
  display: inline-block;
  transform: translateY(1px); }

.modal .agreement-header svg {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 12px 0 10px;
  stroke-width: 4px; }

.modal .agreement-body {
  background-color: #F6F6F6 !important;
  padding: 30px 80px 5px 80px !important; }
  .modal .agreement-body .wp-block-separator {
    color: #e5e5e5;
    width: 100%; }

.modal .agreement-footer {
  background-color: #F6F6F6 !important;
  border: none !important;
  padding-top: 30px !important;
  padding-bottom: 45px !important; }

.modal .modal-cancel-button {
  height: 35px;
  width: 140px;
  transform: translateX(96px);
  padding-top: 11px;
  font-size: 14px !important;
  font-weight: 400;
  color: #FFFFFF !important;
  background-color: #C96A6A !important;
  transition: background-color 0.3s ease;
  backface-visibility: hidden; }

.modal .modal-cancel-button:hover {
  background-color: #a65454 !important; }

.modal .modal-agree-button {
  height: 35px;
  width: 140px;
  transform: translateX(-659px);
  padding-top: 11px;
  font-size: 14px !important;
  font-weight: 400;
  color: #FFFFFF !important;
  background-color: #52a963 !important;
  transition: background-color 0.3s ease; }

.modal .modal-agree-button:hover {
  background-color: #397a46 !important; }

/* Styles for editor*/
.has-standard-14-font-size {
  font-size: 14px; }

.has-standard-12-font-size {
  font-size: 12px; }

.has-standard-14-font-size {
  font-size: 14px; }

.has-standard-15-font-size {
  font-size: 15px; }

.has-standard-16-font-size {
  font-size: 16px; }

.has-standard-18-font-size {
  font-size: 18px; }

.has-standard-24-font-size {
  font-size: 24px; }

.has-standard-27-font-size {
  font-size: 27px; }

.has-standard-30-font-size {
  font-size: 30px; }

.has-standard-36-font-size {
  font-size: 36px; }

.has-standard-64-font-size {
  font-size: 64px;
  line-height: 110%; }

.has-condensed-text {
  font-stretch: condensed;
  line-height: 115% !important; }

.has-weight-300 {
  font-weight: 300; }

.has-weight-400 {
  font-weight: 400; }

.has-weight-700 {
  font-weight: 700; }

* {
  -webkit-print-color-adjust: exact; }

.has-white-color {
  color: #FFF; }

.has-dark-orange-color {
  color: #ffb400; }

.has-light-orange-color {
  color: #fff6e1; }

.has-nav-orange-color {
  color: #fff6e1; }

.has-grey-color {
  color: #828387; }

.has-regular-grey-color {
  color: #535454; }

.has-background-grey-color {
  color: #ececec; }

.has-light-green-color {
  color: #82b38b; }

.has-vibrant-green-color {
  color: #52a963; }

.has-red-color {
  color: #ba5555; }

.has-blue-color {
  color: #5089a6; }

.has-border-color {
  color: #41a1e0; }

.has-dark-orange-background-color {
  background-color: #ffb400; }

.has-light-orange-background-color {
  background-color: #fff6e1; }

.has-nav-orange-background-color {
  background-color: #fff6e1; }

.has-grey-background-color {
  background-color: #828387; }

.has-regular-grey-background-color {
  background-color: #535454; }

.has-background-grey-background-color {
  background-color: #ececec; }

.has-light-green-background-color {
  background-color: #82b38b; }

.has-vibrant-green-background-color {
  background-color: #52a963; }

.has-red-background-color {
  background-color: #ba5555; }

.has-blue-background-color {
  background-color: #5089a6; }

.has-border-background-color {
  background-color: #41a1e0; }

html {
  font-size: 18px !important; }

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: transparent !important;
  font-size: 18px !important; }

a {
  outline: 0;
  border: none;
  box-shadow: none;
  -moz-outline-style: none; }
  a:hover, a:focus {
    outline: 0;
    border: none;
    box-shadow: none;
    -moz-outline-style: none; }

.edit-post-visual-editor .tabacoo-wrap, #root .tabacoo-wrap {
  border-bottom: 1px solid #e0e0e0; }

.edit-post-visual-editor .tabacoo-wrap .wp-block-column:nth-child(1), #root .tabacoo-wrap .wp-block-column:nth-child(1) {
  padding-right: 15px;
  border-right: 1px solid #e0e0e0;
  margin-right: 20px; }

.edit-post-visual-editor .tabacoo-wrap .wp-block-column li, #root .tabacoo-wrap .wp-block-column li {
  list-style-type: none;
  color: #333;
  margin-top: 10px;
  position: relative; }

.edit-post-visual-editor .tabacoo-wrap .wp-block-column li::before, #root .tabacoo-wrap .wp-block-column li::before {
  content: '';
  position: absolute;
  left: -27px;
  top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: #5baf95; }

.edit-post-visual-editor .tabacoo-wrap .wp-block-column li strong, #root .tabacoo-wrap .wp-block-column li strong {
  font-size: 22px;
  margin-bottom: 5px;
  display: block;
  color: #5baf95; }

.edit-post-visual-editor iframe, #root iframe {
  margin: 0px;
  padding: 0px; }

.edit-post-visual-editor code, #root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.edit-post-visual-editor .ui, #root .ui {
  font-family: 'Roboto', sans-serif !important; }

.edit-post-visual-editor .ui.container.narrow, #root .ui.container.narrow {
  max-width: 1080px; }

.edit-post-visual-editor .ui.container.fluid.desktop, #root .ui.container.fluid.desktop {
  max-width: 1366px !important; }

.edit-post-visual-editor .ui.primary.button, #root .ui.primary.button {
  background-color: #5089a6; }

.edit-post-visual-editor .ui.secondary.button, #root .ui.secondary.button {
  background-color: #5baf95; }

h1.type {
  font-size: 1rem;
  padding: 0;
  margin: 0px;
  color: #5089a6;
  font-family: Roboto, sans-serif; }

.ui.container.editing {
  margin: 0px !important;
  padding: 5px !important; }
  .ui.container.editing .ui.container {
    width: 100% !important;
    margin-left: 0em !important;
    margin-right: 0em !important; }

.map-tooltip-light {
  font-size: 1em;
  color: #1e1e1e;
  border-radius: 5px;
  background-color: #f7f7f7 !important;
  min-width: 220px; }
  .map-tooltip-light .tooltip-content {
    padding: 5px; }
    .map-tooltip-light .tooltip-content hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none; }
    .map-tooltip-light .tooltip-content b {
      color: unset; }
  .map-tooltip-light .header {
    padding: 5px;
    border-bottom: 1px solid #DDD; }
  .map-tooltip-light .value {
    color: #1e1e1e;
    font-weight: bold;
    margin-left: 5px; }
  .map-tooltip-light .footer {
    padding: 5px; }
    .map-tooltip-light .footer .confidence {
      font-weight: bold;
      color: #1e1e1e; }
    .map-tooltip-light .footer hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px; }
    .map-tooltip-light .footer b {
      color: #1e1e1e; }

.map-tooltip-dark {
  font-size: 1em;
  color: #FFF;
  border-radius: 5px;
  background-color: #5c5c5c !important;
  min-width: 220px; }
  .map-tooltip-dark .tooltip-content {
    padding: 5px; }
    .map-tooltip-dark .tooltip-content hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none; }
  .map-tooltip-dark .header {
    padding: 5px;
    border-bottom: 1px solid #DDD; }
  .map-tooltip-dark .value {
    color: #FFF;
    font-weight: bold;
    margin-left: 5px; }
  .map-tooltip-dark .footer {
    padding: 5px; }
    .map-tooltip-dark .footer .confidence {
      font-weight: bold;
      color: #FFF; }
    .map-tooltip-dark .footer hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none; }
    .map-tooltip-dark .footer b {
      color: #FFF; }

.ui.container.filter .menu {
  z-index: 1600; }

.title-popup > div,
.reference-popup > div {
  max-width: 300px !important;
  word-wrap: break-word; }
